






import { Component, Prop, Vue } from "vue-property-decorator";

import FilterListItem from "./FilterListItem.vue";
import { FilterId, FILTER_BACKGROUND } from "@/shared/models";

@Component({
  components: {
    FilterListItem,
  },
})
export default class DetachedReportFilterWrapper extends Vue {
  @Prop() filterId!: FilterId;

  get color() {
    return FILTER_BACKGROUND.get(this.filterId);
  }
}
