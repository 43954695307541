





























import { Component, Vue, Prop } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import DatesPicker from "@/shared/components/pickers/DatesPicker.vue";
import DaysLimit from "@/reports/components/DaysLimit.vue";
import CustomSelect from "@/shared/components/pickers/CustomSelect.vue";
import { DatesFilterModel, FilterId, FilterPreviewId } from "@/shared/models";
import { DayLimitFilterPreviewModel } from "@/shared/models/FilterPreviewModel";
import { CohortFilter, ReportFilter } from "@/reports/models";

@Component({
  components: {
    DatesPicker,
    DaysLimit,
    CustomSelect,
  },
})
export default class DatesFilterVisualization extends Vue {
  @Prop() presetStartingDate?: string;
  @Prop() max?: string;
  @Prop() filterPreviewId!: FilterPreviewId;
  @Prop() daysLimit?: DayLimitFilterPreviewModel;

  readonly FilterPreviewId = FilterPreviewId;
  readonly eventsDayLimitItems = [30, 60, 90, 120];

  get localReport(): ReportFilter {
    return this.$store.state.reportStore.localReport;
  }

  get valid(): boolean {
    return (
      (this.localReport?.date?.required &&
        this.localDatesValue.length === 2 &&
        this.localDatesValue.every((value) => !!value)) ||
      this.localDatesValue.every((value) => value !== "Invalid date")
    );
  }

  get required(): boolean {
    return this.localReport.date?.required ?? false;
  }

  get clearable(): boolean {
    return (
      (this.localReport.date?.clearable || !this.localReport.date?.required) ??
      false
    );
  }

  get localDatesValue(): Array<string> {
    return this.localReport?.date
      ? [this.localReport.date.from, this.localReport.date.to]
      : [];
  }

  set localDatesValue(values: Array<string>) {
    const clonedLocalReport = cloneDeep(this.localReport);

    (clonedLocalReport as any).date = values.length
      ? new DatesFilterModel(
          this.localReport?.date
            ? this.localReport.date.id
            : FilterId.EVENTS_DATE,
          this.localReport?.date
            ? this.localReport.date.required
            : this.required,
          this.localReport?.date ? this.localReport.date.filled : false,
          this.valid,
          values[0],
          values[1],
          this.localReport?.date
            ? this.localReport.date.clearable
            : this.clearable
        )
      : undefined;

    this.$store.commit("updateLocalReport", clonedLocalReport);
  }

  get localDayLimit(): number {
    return (this.localReport as CohortFilter).dayLimit;
  }

  set localDayLimit(value: number) {
    const clonedLocalReport = cloneDeep(this.localReport as CohortFilter);

    clonedLocalReport.dayLimit = value;
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }

  get localEventsDayLimit(): number {
    return (this.localReport as any).eventsDayLimit;
  }

  set localEventsDayLimit(value: number) {
    const clonedLocalReport = cloneDeep(this.localReport as any);

    clonedLocalReport.eventsDayLimit = value;
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }
}
