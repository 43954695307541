














import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";

import { DefaultValues } from "@/shared/models";
import ValidUtil from "@/shared/utils/ValidUtil";
import { FilterPreviewId } from "@/shared/models/FilterModel";
import { delay } from "lodash";
import { VueAutocomplete } from "@/shared/types/ExtendedVueType";

@Component
export default class DaysLimit extends Vue {
  @Prop({ default: DefaultValues.DAYS_LIMIT }) value!: number;
  @Prop() filterPreviewId?: FilterPreviewId;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: "" }) label!: string;

  @Ref() input!: VueAutocomplete;

  localValue: number = DefaultValues.DAYS_LIMIT;

  get rules() {
    return [
      ValidUtil.required(this.$lang("validation.required")),
      ValidUtil.min(1, this.$lang("validation.min", 1)),
      ValidUtil.max(
        DefaultValues.MAX_DAYS_LIMIT,
        this.$lang("validation.max", DefaultValues.MAX_DAYS_LIMIT)
      ),
      ValidUtil.integer(this.$lang("validation.integer")),
    ];
  }

  get clickedFilterPreviewId(): FilterPreviewId {
    return this.$store.state.reportStore.clickedFilterPreviewId;
  }

  @Watch("localValue")
  watchLocalValue(value: number) {
    this.$emit("input", value);
  }

  @Watch("clickedFilterPreviewId")
  watchPreviewClick(previewId: FilterPreviewId) {
    if (previewId !== this.filterPreviewId) {
      return;
    }

    delay(() => {
      this.input.focus();
      this.$store.commit("setClickedFilterPreviewId");
    }, 100);
  }

  created() {
    this.localValue = this.value;
  }
}
