




























































































































































































































































































































import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import { cloneDeep, delay, intersection } from "lodash";

import NewTemplate from "@/reports/components/NewTemplate.vue";
import ReportTemplates from "@/reports/components/ReportTemplates.vue";
import ReportFilterCurrentState from "@/reports/components/ReportFilterCurrentState.vue";
import CohortAnalysisInfoTooltip from "@/reports/components/CohortAnalysisInfoTooltip.vue";
import SystemAlert from "@/alerts-system/components/SystemAlert.vue";
import ReportResults from "@/reports/components/ReportResults.vue";
import ReportFilters from "@/reports/components/ReportFilters.vue";
import ReportMetricsFilter from "@/reports/components/ReportMetricsFilter.vue";
import ExpansionPanelHeader from "@/reports/components/ExpansionPanelHeader.vue";
import ReportFiltersControls from "@/reports/components/ReportFiltersControls.vue";
import ReportInfo from "@/reports/components/ReportInfo.vue";
import ReportSources from "@/reports/components/ReportSources.vue";
import { ReportFilter, ReportType } from "@/reports/models";
import { NewReportTemplateModel } from "@/templates/models/TemplateModel";
import {
  FilterPreviewId,
  MULTI_APP,
  Application,
  MenuItems,
  DICTIONARIES_BY_REPORT_TYPE,
  REPORT_FILTERS,
  FilterId,
  NetworksFilterType,
  TrackerDictionary,
  FilterModel,
  TrackerFilterModel,
  TrackerFilterPartModel,
  ExcludableMultipleValueFilterModel,
  Dictionary,
  FilterPreview,
  FILTER_PREVIEW_SORT_ORDER,
  CurrentUserModel,
  AppSection,
  FILTER_ID_TO_INIT_FUNCTION,
  DictionaryType,
  TrackerOrigin,
} from "@/shared/models";
import ReportUtil from "@/reports/utils/ReportUtil";
import {
  AlertFeature,
  AlertFilterModel,
} from "@/alerts-system/models/AlertResponseModel";
import EncodeUtil from "@/shared/utils/EncodeUtil";

@Component({
  components: {
    NewTemplate,
    ReportTemplates,
    ReportFilterCurrentState,
    CohortAnalysisInfoTooltip,
    SystemAlert,
    ReportResults,
    ReportFilters,
    ReportMetricsFilter,
    ExpansionPanelHeader,
    ReportFiltersControls,
    ReportInfo,
    ReportSources,
  },
})
export default class ReportView extends Vue {
  @Ref("previewPanel") readonly previewPanel!: HTMLElement;

  isFilterPinned = false;
  isFilterVisible = false;
  isReportTemplatesDialogVisible = false;
  isNewTemplateDialogVisible = false;
  template: NewReportTemplateModel | null = null;
  valid = true;
  horizontalFilters: Array<number> = [0];
  selectedTab = 0;
  isReportDictionariesLoading = false;
  isFullDictionariesReload = false;

  get applicationId(): string {
    return this.$route.params.id;
  }

  get app(): Application {
    return this.$store.getters.applicationsById.get(this.applicationId);
  }

  get reportType(): ReportType {
    return this.$route.name as ReportType;
  }

  get localReport(): ReportFilter {
    return this.$store.state.reportStore.localReport;
  }

  get report(): ReportFilter {
    return this.$store.state.reportStore.currentReport;
  }

  get isMultiAppReport(): boolean {
    return ReportUtil.isMultiAppReport(this.reportType);
  }

  get isMultiAppSwitchVisible(): boolean {
    return this.applicationId !== MULTI_APP && this.isMultiAppReport;
  }

  get isCommonReportFilterEnabled(): boolean {
    return this.$store.state.reportStore.isCommonReportFilterEnabled;
  }

  get isCohortAnalysisReport(): boolean {
    return this.reportType === ReportType.COHORT_ANALYSIS;
  }

  get isMonetizationMonitoring(): boolean {
    return this.reportType === ReportType.MONETIZATION_MONITORING;
  }

  get hasTemplatesViewAccess(): boolean {
    return (
      !this.isCohortAnalysisReport &&
      !this.isMonetizationMonitoring &&
      this.currentUser.viewAccessEntities[this.applicationId]?.includes(
        AppSection.REPORT_TEMPLATES
      )
    );
  }

  get hasTemplatesCreateAccess(): boolean {
    return (
      !this.isCohortAnalysisReport &&
      !this.isMonetizationMonitoring &&
      this.currentUser.createAccessEntities[this.applicationId]?.includes(
        AppSection.REPORT_TEMPLATES
      )
    );
  }

  get apps(): Array<Application> {
    return this.isMultiAppReport && this.applicationId === MULTI_APP
      ? this.$store.state.application.apps.filter(({ id }: Application) =>
          this.currentUser.availableReportApps
            .get(this.reportType)
            ?.includes(id)
        )
      : [this.app];
  }

  get reportName(): string {
    return ReportUtil.getReportNameByReportType(this.reportType);
  }

  get isReportGenerated(): boolean {
    const { query } = this.$route;

    return query && !!Object.keys(query).length;
  }

  get isPreviewsVisible(): boolean {
    return (
      this.isReportGenerated &&
      this.report &&
      !this.report.invalid &&
      !!this.report.previews.length
    );
  }

  get isReportSourcesVisible(): boolean {
    return this.isReportGenerated && this.report && !this.report.invalid;
  }

  get filterPreviews(): Array<FilterPreview> {
    const excludes = this.$store.getters.applicationsById.get(
      this.applicationId
    )?.hasFirebaseBannersFeature
      ? []
      : [FilterPreviewId.PROVIDED_BANNER];

    return this.report.previews
      .filter(({ id }) => !excludes.includes(id))
      .sort((previewA: FilterPreview, previewB: FilterPreview) => {
        return (
          FILTER_PREVIEW_SORT_ORDER.indexOf(previewA.id) -
          FILTER_PREVIEW_SORT_ORDER.indexOf(previewB.id)
        );
      });
  }

  get clickedFilterPreviewId(): FilterPreviewId {
    return this.$store.state.reportStore.clickedFilterPreviewId;
  }

  get isFilterPanelTemporary(): boolean {
    return !this.isFilterPinned && this.isReportGenerated;
  }

  get isReportFilterHorizontal(): boolean {
    return this.$store.state.accountSettingsStore.accountSettings
      .isReportFilterHorizontal;
  }

  get isReportLoading(): boolean {
    return this.$store.getters.isReportLoading;
  }

  get isReportCanceled(): boolean {
    return this.$store.state.reportStore.isReportCanceled;
  }

  get isBtnDisabled(): boolean {
    if (this.localReport?.date) {
      return (
        this.localReport.invalid ||
        this.isReportLoading ||
        !this.valid ||
        !this.localReport.date.valid
      );
    }

    return this.localReport.invalid || this.isReportLoading || !this.valid;
  }

  get trackerItemsFilter():
    | ((dictionary: TrackerDictionary) => boolean)
    | null {
    return ReportUtil.trackerItemsFilter(this.reportType);
  }

  get currentUser(): CurrentUserModel {
    return this.$store.state.userStore.currentUser;
  }

  get reportCohortMetricsFilterVisible(): boolean {
    return [ReportType.METRICS_CONSTRUCTOR].includes(this.reportType);
  }

  get notAllNetworksApps(): Array<string> | null {
    return this.$store.state.userStore.notAllNetworksApps;
  }

  get networkFilterIds(): Array<NetworksFilterType> {
    return intersection(REPORT_FILTERS[this.reportType], [
      ...(DICTIONARIES_BY_REPORT_TYPE[this.reportType].some((item) =>
        [
          DictionaryType.ROOT_AD_NETWORK_NAMES,
          DictionaryType.ALL_AD_NETWORK_NAMES,
        ].includes(item)
      )
        ? [FilterId.AD_NETWORK_NAME]
        : []),
      FilterId.TRACKER,
      FilterId.SOURCE,
    ]) as Array<NetworksFilterType>;
  }

  @Watch("report", { immediate: true, deep: true })
  watchReport() {
    this.$store.commit(
      "updateLocalReport",
      ReportUtil.getCopyOfReportFilter(this.report, this.reportType, this.apps)
    );
  }

  @Watch("localReport.getApp")
  async watchReportApps(value: string) {
    if (this.isFullDictionariesReload) {
      this.isFullDictionariesReload = false;

      return;
    }

    const dictionaryTypes: Array<DictionaryType> =
      DICTIONARIES_BY_REPORT_TYPE[this.reportType];
    const origins: Array<TrackerOrigin> | null = dictionaryTypes.includes(
      DictionaryType.SOURCES
    )
      ? ReportUtil.getTrackerOrigins(this.reportType)
      : null;

    this.$store.dispatch("loadDictionaries", {
      app: value,
      dictionaryTypes,
      origins,
    });
  }

  @Watch("clickedFilterPreviewId")
  watchPreviewClick() {
    this.isFilterVisible = true;
  }

  @Watch("$route.name", { immediate: true })
  async watchRouteName() {
    document.title = this.$lang("documentTitle", this.reportName);

    this.generateReportFromQuery();
  }

  @Watch("applicationId")
  async clearReportData(newAppId: string) {
    this.$store.commit("resetReportData");

    if (
      newAppId === MULTI_APP &&
      !ReportUtil.isMultiAppReport(this.report.reportId)
    ) {
      this.$router.push({
        name: MenuItems.HOME,
        params: { id: newAppId },
      });

      return;
    }

    this.isFullDictionariesReload = true;
    this.isReportDictionariesLoading = true;
    await this.loadReportDictionaries();
    await this.loadUsedDictionaries();
    this.isReportDictionariesLoading = false;
  }

  @Watch("reportType", { immediate: true })
  clearSystemAlerts() {
    this.$store.commit("setAlertFilter", null);
    this.$store.commit("setNotAllNetworksApps", null);
    this.fetchNotAllNetworksApps();
  }

  @Watch("isReportFilterHorizontal")
  unpinFilter() {
    if (!this.isFilterPinned) {
      return;
    }

    this.isFilterPinned = false;
  }

  created() {
    if (this.$route.query?.resetCache) {
      this.$store.commit("setResetCache", true);
    }

    if (this.$route.query?.currentDateAs) {
      this.$store.commit("setCurrentDateAs", this.$route.query.currentDateAs);
    }

    window.addEventListener("popstate", this.generateReportFromQuery);
  }

  beforeDestroy() {
    window.removeEventListener("popstate", this.generateReportFromQuery);
    this.$store.commit("resetReportData");
  }

  async loadReportDictionaries() {
    let response = null;

    if (this.$route.query?.data) {
      response = await EncodeUtil.decompressRouteQuery(
        this.$route.query.data as string
      );
    }

    return this.$store.dispatch("loadReportDictionaries", {
      reportType: this.reportType,
      apps: this.apps,
      query: response ? JSON.parse(response) : {},
    });
  }

  async generateReportFromQuery() {
    const { query } = this.$route;

    this.isReportDictionariesLoading = true;
    await this.loadReportDictionaries();

    if (query && Object.keys(query).length) {
      const response = await EncodeUtil.decompressRouteQuery(
        query.data as string
      );

      const reportFromQuery = ReportUtil.getCopyOfReportFilter(
        JSON.parse(response),
        this.reportType,
        this.apps
      );

      await this.loadUsedDictionaries(reportFromQuery);

      if (!reportFromQuery.invalid) {
        this.$store.commit("updateCurrentReport", reportFromQuery);
        this.getReportData();
        this.isReportDictionariesLoading = false;

        return;
      }
    } else {
      await this.loadUsedDictionaries();
    }

    this.isReportDictionariesLoading = false;
    this.isFilterVisible = true;
  }

  async generateReport() {
    if (!this.isFilterPinned) {
      this.isFilterVisible = false;
    }

    this.updateNetworkFilters();
    this.$store.commit("updateCurrentReport", this.localReport);
    await this.getReportData();

    const compressedQueryParams = await EncodeUtil.compressRouteQuery(
      JSON.stringify(this.report.toRequestQuery())
    );
    this.$router.push({
      query: {
        data: compressedQueryParams,
      },
    });
    this.setCommonReportFilter();

    if (this.isReportFilterHorizontal) {
      delay(() => {
        this.$vuetify.goTo(this.previewPanel, { duration: 300 });
      }, 100);
    }
  }

  getReportData() {
    this.$store.commit(
      "setAlertFilter",
      new AlertFilterModel(
        this.report.getApp,
        AlertFeature.REPORTS,
        this.report.reportId,
        this.report?.date?.from,
        this.report?.date?.to
      )
    );
    this.$store.dispatch("getReportData", this.report);
  }

  async updateByTemplate(reportFilter: ReportFilter) {
    this.$store.commit(
      "updateCurrentReport",
      ReportUtil.getCopyOfReportFilter(
        reportFilter,
        this.reportType,
        ReportUtil.isMultiAppReport(reportFilter.reportId)
          ? this.$store.state.application.apps
          : this.$store.state.application.apps.filter(
              ({ id }: Application) => id === this.applicationId
            )
      )
    );

    const compressedQueryParams = await EncodeUtil.compressRouteQuery(
      JSON.stringify(this.report.toRequestQuery())
    );

    this.$router.replace({
      ...(this.report.getApp.split(",").length > 1
        ? { params: { id: MULTI_APP } }
        : {}),
      query: {
        data: compressedQueryParams,
      },
    });
    this.setCommonReportFilter();

    await this.loadUsedDictionaries(this.report);
    this.getReportData();
  }

  toggleCommonReportFilter() {
    this.$store.commit("toggleCommonReportFilter");
    this.setCommonReportFilter();
  }

  async setCommonReportFilter() {
    const hasQuery = this.$route.query && Object.keys(this.$route.query).length;

    const response = await EncodeUtil.decompressRouteQuery(
      this.$route.query.data as string
    );

    this.$store.commit(
      "setCommonReportFilter",
      this.isCommonReportFilterEnabled && hasQuery
        ? ReportUtil.getCopyOfReportFilter(
            JSON.parse(response),
            this.reportType,
            this.apps
          )
        : null
    );
  }

  saveTemplate() {
    this.template = new NewReportTemplateModel(
      this.report.getApp.split(","),
      this.report
    );
    this.isNewTemplateDialogVisible = true;
  }

  switchToMultiApp() {
    this.$router.push({ params: { id: MULTI_APP } });
  }

  toggleHorizontalFilters() {
    this.horizontalFilters = this.horizontalFilters.length ? [] : [0];
  }

  updateNetworkFilters() {
    if (
      !this.networkFilterIds.length ||
      !this.notAllNetworksApps?.length ||
      !intersection(this.notAllNetworksApps, this.localReport.getApp.split(","))
        .length
    ) {
      return;
    }

    const clonedLocalReport = cloneDeep(this.localReport);
    const setNetworkValues = (filterId: NetworksFilterType) => {
      const dictionaries: Array<Dictionary | TrackerDictionary> = {
        [FilterId.TRACKER]: () =>
          this.$store.state.trackerStore.dataTrackers.sources.values,
        [FilterId.SOURCE]: () =>
          this.$store.state.dictionaryStore[DictionaryType.SOURCES].values,
        [FilterId.AD_NETWORK_NAME]: () =>
          this.$store.state.dictionaryStore[
            DictionaryType.ROOT_AD_NETWORK_NAMES
          ].values,
      }[filterId]();
      const networks = dictionaries.reduce(
        (result: Array<string>, dictionary) =>
          result.concat(
            filterId === FilterId.AD_NETWORK_NAME ||
              !this.trackerItemsFilter ||
              this.trackerItemsFilter(dictionary as TrackerDictionary)
              ? [dictionary.value]
              : []
          ),
        []
      );
      const filter = clonedLocalReport.filter.find(
        ({ id }: FilterModel) => id === filterId
      );

      if (!filter) {
        const newFilter = FILTER_ID_TO_INIT_FUNCTION[filterId]();

        if (newFilter instanceof TrackerFilterModel) {
          newFilter.source = new TrackerFilterPartModel(true, networks);
        } else if (newFilter instanceof ExcludableMultipleValueFilterModel) {
          newFilter.values = networks;
        }

        clonedLocalReport.filter.push(newFilter);

        return;
      }

      if (
        filter instanceof TrackerFilterModel &&
        filter.source &&
        !filter.source.included
      ) {
        filter.source.included = true;
        filter.source.values = networks.filter(
          (network: string) => !filter.source?.values.includes(network)
        );
      } else if (
        filter instanceof ExcludableMultipleValueFilterModel &&
        !filter.included
      ) {
        filter.included = true;
        filter.values = networks.filter(
          (network: string) => !filter.values.includes(network)
        );
      }
    };

    this.networkFilterIds.forEach((filterId) => setNetworkValues(filterId));
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }

  fetchNotAllNetworksApps() {
    if (this.notAllNetworksApps || !this.networkFilterIds.length) {
      return;
    }

    const apps =
      this.applicationId === MULTI_APP
        ? this.currentUser.availableReportApps.get(this.reportType)?.join(",")
        : this.applicationId;

    this.$store.dispatch("fetchNotAllNetworksApps", apps);
  }

  async loadUsedDictionaries(report?: ReportFilter) {
    await Promise.all([
      this.$store.dispatch("loadUsedDictionaries", {
        app: this.report.getApp,
        values: report?.getUsedDictionaryValues() || {},
      }),
      this.$store.dispatch("loadUsedTrackerDictionaries", {
        app: this.report.getApp,
        values: report?.getUsedTrackerDictionaryValues() || {},
      }),
    ]);
  }

  cancelCalculationReport() {
    this.$store.dispatch("cancelCalculationReport", this.report);
  }
}
