













import { Component, Vue, Prop } from "vue-property-decorator";

import CustomSelect from "@/shared/components/pickers/CustomSelect.vue";
import { Dictionary, FilterPreviewId } from "@/shared/models";
import {
  TimeSpentReportType,
  TimeSpentRportTypeReportFilterExtension,
} from "@/reports/models";
import { cloneDeep } from "lodash";

@Component({ components: { CustomSelect } })
export default class TimeSpentReportTypeFilterVisualization extends Vue {
  @Prop() filterPreviewId!: FilterPreviewId;
  @Prop() isAttached!: boolean;

  readonly items = [
    new Dictionary(
      TimeSpentReportType.CUMULATIVE_PLAY_TIME,
      this.$lang(
        `views.timeSpent.${TimeSpentReportType.CUMULATIVE_PLAY_TIME.toLowerCase()}`
      )
    ),
    new Dictionary(
      TimeSpentReportType.PLAY_TIME,
      this.$lang(
        `views.timeSpent.${TimeSpentReportType.PLAY_TIME.toLowerCase()}`
      )
    ),
    new Dictionary(
      TimeSpentReportType.CUMULATIVE_TIME_SPENT,
      this.$lang(
        `views.timeSpent.${TimeSpentReportType.CUMULATIVE_TIME_SPENT.toLowerCase()}`
      )
    ),
    new Dictionary(
      TimeSpentReportType.TIME_SPENT,
      this.$lang(
        `views.timeSpent.${TimeSpentReportType.TIME_SPENT.toLowerCase()}`
      )
    ),
  ];

  get localReport(): TimeSpentRportTypeReportFilterExtension {
    return this.$store.state.reportStore.localReport;
  }

  get localValue(): TimeSpentReportType {
    return this.localReport.reportType;
  }

  set localValue(value: TimeSpentReportType) {
    const clonedLocalReport = cloneDeep(this.localReport);

    clonedLocalReport.reportType = value;
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }
}
