









import { Component, Vue, Prop } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import CustomSwitch from "@/shared/components/filters/CustomSwitch.vue";
import { ExcludeNoInternetReportFilterExtension } from "@/reports/models";
import { FilterPreviewId } from "@/shared/models";

@Component({
  components: {
    CustomSwitch,
  },
})
export default class ExcludeNoInternetFilterVisualization extends Vue {
  @Prop() filterPreviewId!: FilterPreviewId;

  get localReport(): ExcludeNoInternetReportFilterExtension {
    return this.$store.state.reportStore.localReport;
  }

  get localValue(): boolean {
    return this.localReport.excludeNoInternet;
  }

  set localValue(value: boolean) {
    const clonedLocalReport = cloneDeep(this.localReport);

    clonedLocalReport.excludeNoInternet = value;
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }

  get label(): string {
    return this.$lang(`components.${this.filterPreviewId.toLowerCase()}`);
  }
}
