













import { Component, Vue, Prop } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import CustomSelect from "@/shared/components/pickers/CustomSelect.vue";
import { Dictionary, FilterPreviewId } from "@/shared/models";
import {
  TimeSpentReportType,
  TimeSpentTypeReportFilterExtension,
} from "@/reports/models";

@Component({ components: { CustomSelect } })
export default class TimeSpentTypeFilterVisualization extends Vue {
  @Prop() filterPreviewId!: FilterPreviewId;
  @Prop() isAttached!: boolean;

  readonly items = [
    new Dictionary(
      TimeSpentReportType.PLAY_TIME,
      this.$lang(
        `components.metrics.${TimeSpentReportType.PLAY_TIME.toLowerCase()}`
      )
    ),
    new Dictionary(
      TimeSpentReportType.TIME_SPENT,
      this.$lang(
        `components.metrics.${TimeSpentReportType.TIME_SPENT.toLowerCase()}`
      )
    ),
  ];

  get localReport(): TimeSpentTypeReportFilterExtension {
    return this.$store.state.reportStore.localReport;
  }

  get localValue(): TimeSpentReportType {
    return this.localReport.timeSpentType;
  }

  set localValue(value: TimeSpentReportType) {
    const clonedLocalReport = cloneDeep(this.localReport);

    clonedLocalReport.timeSpentType = value;
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }
}
