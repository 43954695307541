








































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { cloneDeep } from "lodash";
import draggable from "vuedraggable";

import ReportMetricCard from "./metrics-filter/ReportMetricCard.vue";
import UserMetricsForm from "./metrics-filter/user-metrics/UserMetricsForm.vue";
import PredefinedMetricsForm from "./metrics-filter/PredefinedMetricsForm.vue";
import {
  ReportFilter,
  MetricsConstructorReportFilterExtension,
  ReportMetricType,
  UserMetricModel,
  PredefinedMetricModel,
  MetricType,
} from "@/reports/models";

@Component({
  components: {
    draggable,
    ReportMetricCard,
    UserMetricsForm,
    PredefinedMetricsForm,
  },
})
export default class ReportMetricsFilter extends Vue {
  @Prop() value!: boolean;
  @Prop({ default: false }) horizontal!: boolean;

  // USER METRICS
  userMetricSidebarVisible = false;
  isUserMetricEdit = false;
  isUserMetricCopy = false;
  userMetricBeingEdited: UserMetricModel | null = null;
  // PREDEFINED METRICS
  predefinedMetricSidebarVisible = false;
  isPredefinedMetricEdit = false;
  predefinedMetricBeingEdited: PredefinedMetricModel | null = null;
  // GENERAL
  metricIndexBeingEdited: number | null = null;

  get localReport(): ReportFilter & MetricsConstructorReportFilterExtension {
    return this.$store.state.reportStore.localReport;
  }

  get metrics(): Array<UserMetricModel | PredefinedMetricModel> {
    return this.localReport.metrics ?? [];
  }

  set metrics(value: Array<UserMetricModel | PredefinedMetricModel>) {
    const clonedLocalReport = cloneDeep(this.localReport);

    clonedLocalReport.metrics = value;
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }

  get addMetricButtonHidden(): boolean {
    return (
      this.selectedPredefinedMetricsIds.length ===
      Object.keys(ReportMetricType).length
    );
  }

  get selectedPredefinedMetricsIds(): Array<ReportMetricType> {
    return (
      this.metrics.filter(
        ({ type }) => type === MetricType.PREDEFINED
      ) as Array<PredefinedMetricModel>
    ).map(({ id }: PredefinedMetricModel) => id as ReportMetricType);
  }

  get metricsNames(): Array<string> {
    return this.metrics.reduce(
      (
        result: Array<string>,
        metric: PredefinedMetricModel | UserMetricModel
      ) => {
        if (metric.type === MetricType.USER) {
          result.push((metric as UserMetricModel).definition.name);
        } else {
          result.push(
            this.$lang(
              `views.metrics.tableHeader.${(
                metric as PredefinedMetricModel
              )?.id?.toLowerCase()}`
            )
          );
        }

        return result;
      },
      []
    );
  }

  get lastAddedCohortDays(): Array<number> {
    const lastMetric: UserMetricModel | PredefinedMetricModel | undefined =
      this.metrics[this.metrics.length - 1];

    if (!lastMetric) {
      return [];
    }

    if (
      lastMetric.type === MetricType.PREDEFINED &&
      PredefinedMetricModel.getDaysItemsByMetricType(
        lastMetric.id as ReportMetricType
      )
    ) {
      return lastMetric.days;
    }

    if (
      lastMetric.type === MetricType.USER &&
      (lastMetric as UserMetricModel).definition.basicMetrics.some(
        (item) => item.cohort
      )
    ) {
      return (lastMetric as UserMetricModel).days;
    }

    return [];
  }

  // USER METRICS
  @Watch("userMetricSidebarVisible")
  watchUserMetricSidebarVisible(value: boolean) {
    if (!value) {
      this.resetLocalUserMetricData();
    }
  }

  handleAddingMetric() {
    this.userMetricSidebarVisible = true;
  }

  handleEditingUserMetric(item: UserMetricModel) {
    this.userMetricSidebarVisible = true;
    this.isUserMetricEdit = true;
    this.userMetricBeingEdited = cloneDeep(item);
  }

  handleCopyUserMetric(item: UserMetricModel, index: number) {
    const clonedItem = cloneDeep(item);

    clonedItem.definition.name = `${clonedItem.definition.name} copy`;
    clonedItem.definition.id = Math.floor(Math.random() * 100000);
    this.isUserMetricCopy = true;
    this.userMetricSidebarVisible = true;
    this.userMetricBeingEdited = cloneDeep(clonedItem);
    this.metricIndexBeingEdited = index;
  }

  handleSavingUserMetric({
    value,
    isCreateOneMoreMetric,
  }: {
    value: UserMetricModel;
    isCreateOneMoreMetric: boolean;
  }) {
    const clonedMetrics = cloneDeep(this.metrics);

    if (this.isUserMetricEdit && this.metricIndexBeingEdited !== null) {
      clonedMetrics.splice(this.metricIndexBeingEdited, 1, value);
      this.resetLocalUserMetricData();
    } else {
      clonedMetrics.push(value);
    }

    this.metrics = clonedMetrics;

    if (!isCreateOneMoreMetric) {
      this.userMetricSidebarVisible = false;
    }
  }

  resetLocalUserMetricData() {
    this.isUserMetricEdit = false;
    this.isUserMetricCopy = false;
    this.userMetricBeingEdited = null;
    this.metricIndexBeingEdited = null;
  }

  // PREDEFINED METRICS
  @Watch("predefinedMetricSidebarVisible")
  watchPredefinedMetricSidebarVisible(value: boolean) {
    if (!value) {
      this.resetLocalPredefinedMetricData();
    }
  }

  handleAddingPredefinedMetric() {
    this.predefinedMetricSidebarVisible = true;
  }

  handleEditingPredefinedMetric(item: PredefinedMetricModel) {
    this.predefinedMetricSidebarVisible = true;
    this.isPredefinedMetricEdit = true;
    this.predefinedMetricBeingEdited = PredefinedMetricModel.of(item);
  }

  handleSavingPredefinedMetric({
    value,
    isCreateOneMoreMetric,
  }: {
    value: PredefinedMetricModel;
    isCreateOneMoreMetric: boolean;
  }) {
    const clonedMetrics = cloneDeep(this.metrics);

    if (this.isPredefinedMetricEdit && this.metricIndexBeingEdited !== null) {
      clonedMetrics.splice(this.metricIndexBeingEdited, 1, value);
      this.resetLocalPredefinedMetricData();
    } else {
      clonedMetrics.push(value);
    }

    this.metrics = clonedMetrics;

    if (!isCreateOneMoreMetric) {
      this.predefinedMetricSidebarVisible = false;
    }
  }

  resetLocalPredefinedMetricData() {
    this.isPredefinedMetricEdit = false;
    this.predefinedMetricBeingEdited = null;
    this.metricIndexBeingEdited = null;
  }

  // GENERAL
  handleEditingMetricItem(
    item: UserMetricModel | PredefinedMetricModel,
    index: number
  ) {
    this.metricIndexBeingEdited = index;

    if (item.type === MetricType.PREDEFINED) {
      this.handleEditingPredefinedMetric(item as PredefinedMetricModel);
    } else {
      this.handleEditingUserMetric(item as UserMetricModel);
    }
  }

  handleDeleteMetricItemByIndex(index: number) {
    const clonedMetrics = cloneDeep(this.metrics);

    clonedMetrics.splice(index, 1);
    this.metrics = clonedMetrics;
  }
}
