
































































import { Component, Prop, Vue } from "vue-property-decorator";

import ChartAlert from "@/alerts-system/components/ChartAlert.vue";
import Chart from "@/chart/components/Chart.vue";
import CellTooltip from "./CellTooltip.vue";
import { ChartName } from "@/chart/models/ChartModel";
import { getReportChartData } from "@/chart/utils/ChartUtil";
import ConditionsTooltip from "@/reports/components/ConditionsTooltip.vue";
import ReportTable from "@/reports/components/ReportTable.vue";
import {
  CohortFilter,
  ReportDataType,
  ReportFilter,
  ReportResultItem,
  ReportVisualizationModel,
} from "@/reports/models";
import { FilterId } from "@/shared/models";

@Component({
  components: {
    ReportTable,
    ConditionsTooltip,
    Chart,
    ChartAlert,
    CellTooltip,
  },
})
export default class ReportResultsTabs extends Vue {
  @Prop() visualization!: ReportVisualizationModel;

  readonly ReportDataType = ReportDataType;
  activeTab = 0;
  cellTooltipData = {
    xPos: 0,
    yPos: 0,
    visible: false,
    activator: "",
    reportDataType: ReportDataType.DATA,
    currentSortByKey: "",
    hasGroupedHeaders: false,
  };
  groupedItems: Map<any, Array<number>> = new Map();
  items: Array<ReportResultItem> = [];

  get report(): ReportFilter {
    // This is to reset activeTab after report generation
    this.activeTab = 0;

    return this.$store.state.reportStore.currentReport;
  }

  get isChartLoading(): boolean {
    return this.$store.state.reportStore.report[
      this.visualization.reportDataType
    ].loading;
  }

  get isDisabledGraphsTab(): boolean {
    return (
      this.visualization.reportDataType !== ReportDataType.TOTAL &&
      this.report.groupByFilter.groupBy.length > 1
    );
  }

  handleUpdateGroupedItems(value: Map<any, Array<number>>) {
    this.groupedItems = value;
  }

  handleUpdateItems(value: Array<ReportResultItem>) {
    this.items = value;
  }

  handleHoverCell({
    event,
    activatorId,
    value,
    reportDataType,
    currentSortByKey,
    hasGroupedHeaders,
  }: {
    event: any;
    activatorId: string;
    value: boolean;
    reportDataType?: ReportDataType;
    currentSortByKey?: string;
    hasGroupedHeaders?: boolean;
  }) {
    this.cellTooltipData.xPos = event.clientX;
    this.cellTooltipData.yPos = event.clientY;
    this.cellTooltipData.activator = `#${activatorId}`;
    this.cellTooltipData.visible = value;

    if (value) {
      this.cellTooltipData.reportDataType = reportDataType as ReportDataType;
      this.cellTooltipData.currentSortByKey = currentSortByKey as string;
      this.cellTooltipData.hasGroupedHeaders = hasGroupedHeaders as boolean;
    }
  }

  getChartData(
    reportDataType: ReportDataType,
    chartName: ChartName
  ): Array<Record<string, any>> {
    const filterId: FilterId | null =
      this.report.groupByFilter.groupBy.length === 1
        ? this.report.groupByFilter.groupBy[0]
        : null;

    return getReportChartData(
      this.$store.state.reportStore.report[reportDataType].items,
      undefined,
      chartName,
      filterId,
      (this.report as CohortFilter).dayLimit
    );
  }

  showChartDialog(item: {
    filter: Map<any, any>;
    reportDataType: ReportDataType;
  }) {
    this.$emit("showChartDialog", item);
  }
}
