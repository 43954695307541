









































import { Component, Vue, Ref, Watch } from "vue-property-decorator";
import { cloneDeep, delay } from "lodash";

import { GoalReportFilterExtension } from "@/reports/models";
import ValidUtil from "@/shared/utils/ValidUtil";
import FilterListItem from "@/reports/components/FilterListItem.vue";
import { FilterPreviewId } from "@/shared/models";

@Component({
  components: {
    FilterListItem,
  },
})
export default class DayFilter extends Vue {
  @Ref() startDayInput!: HTMLElement;
  @Ref() endDayInput!: HTMLElement;
  @Ref() goalInput!: HTMLElement;

  get localReport(): GoalReportFilterExtension {
    return this.$store.state.reportStore.localReport;
  }

  get localStartDay(): number {
    return this.localReport.startDay;
  }

  set localStartDay(value: number) {
    const clonedLocalReport = cloneDeep(this.localReport);

    clonedLocalReport.startDay = value;
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }

  get localEndDay(): number {
    return this.localReport.endDay;
  }

  set localEndDay(value: number) {
    const clonedLocalReport = cloneDeep(this.localReport);

    clonedLocalReport.endDay = value;
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }

  get localGoal(): number {
    return this.localReport.goal;
  }

  set localGoal(value: number) {
    const clonedLocalReport = cloneDeep(this.localReport);

    clonedLocalReport.goal = value;
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }

  get startDayRules(): Array<() => string | true> {
    return [
      ValidUtil.required(this.$lang("validation.required")),
      ValidUtil.min(1, this.$lang("validation.min", 1)),
      ValidUtil.max(
        this.localEndDay,
        this.$lang("validation.max", this.localEndDay)
      ),
      ValidUtil.integer(this.$lang("validation.integer")),
    ];
  }

  get endDayRules(): Array<() => string | true> {
    return [
      ValidUtil.required(this.$lang("validation.required")),
      ValidUtil.min(
        this.localStartDay,
        this.$lang("validation.min", this.localStartDay)
      ),
      ValidUtil.max(
        this.localGoal,
        this.$lang("validation.max", this.localGoal)
      ),
      ValidUtil.integer(this.$lang("validation.integer")),
    ];
  }

  get goalRules(): Array<() => string | true> {
    return [
      ValidUtil.required(this.$lang("validation.required")),
      ValidUtil.min(
        this.localEndDay,
        this.$lang("validation.min", this.localEndDay)
      ),
      ValidUtil.integer(this.$lang("validation.integer")),
    ];
  }

  get clickedFilterPreviewId(): FilterPreviewId {
    return this.$store.state.reportStore.clickedFilterPreviewId;
  }

  @Watch("clickedFilterPreviewId")
  watchPreviewClick(previewId: FilterPreviewId) {
    const input = new Map([
      [FilterPreviewId.START_DAY, this.startDayInput],
      [FilterPreviewId.END_DAY, this.endDayInput],
      [FilterPreviewId.GOAL, this.goalInput],
    ]).get(previewId);

    if (!input) {
      return;
    }

    delay(() => {
      input.focus();
      this.$store.commit("setClickedFilterPreviewId");
    }, 100);
  }
}
