

















import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import DetachedReportFilterWrapper from "./DetachedReportFilterWrapper.vue";
import CommonReportFilterWrapper from "./CommonReportFilterWrapper.vue";
import MultipleValueFilter from "@/shared/components/filters/MultipleValueFilter.vue";
import AttributionDateFilter from "@/shared/components/filters/AttributionDateFilter.vue";
import ExcludableMultipleDictionaryFilter from "@/shared/components/filters/ExcludableMultipleDictionaryFilter.vue";
import AdRevenueFilter from "@/shared/components/filters/AdRevenueFilter.vue";
import SessionNumberFilter from "@/shared/components/filters/SessionNumberFilter.vue";
import TrackerFilter from "@/shared/components/filters/TrackerFilter.vue";
import RangeBasedMultipleEventParamFilter from "@/shared/components/filters/RangeBasedMultipleEventParamFilter.vue";
import EventParamFilter from "@/shared/components/filters/EventParamFilter.vue";
import BoolFilter from "@/shared/components/filters/BoolFilter.vue";
import DatesFilter from "@/shared/components/filters/DatesFilter.vue";
import GroupedFilter from "@/shared/components/filters/GroupedFilter.vue";
import { ReportFilter } from "@/reports/models";
import ReportUtil from "@/reports/utils/ReportUtil";
import {
  FilterId,
  FILTER_ID_TO_COMPONENT,
  FILTER_ID_TO_FILTER_PREVIEW_ID,
  FilterModel,
  FILTER_ID_TO_DATA_TYPES,
  TrackerOrigin,
  DictionaryType,
  DICTIONARIES_BY_REPORT_TYPE,
} from "@/shared/models";

@Component({
  components: {
    GroupedFilter,
    MultipleValueFilter,
    ExcludableMultipleDictionaryFilter,
    AdRevenueFilter,
    SessionNumberFilter,
    TrackerFilter,
    RangeBasedMultipleEventParamFilter,
    EventParamFilter,
    AttributionDateFilter,
    DatesFilter,
    BoolFilter,
    DetachedReportFilterWrapper,
  },
})
export default class ReportSingleFilter extends Vue {
  @Prop() reportFilter!: ReportFilter;
  @Prop() filterId!: FilterId;
  @Prop() label?: string;
  @Prop() itemsFilter?: (item: any, currentValue: any) => boolean;
  @Prop({ default: true }) isAttached!: boolean;
  // TODO: REPORTS temp prop
  @Prop({ default: false }) newLayout!: boolean;
  @Prop() defaultValues!: Array<any>;
  @Prop() origins?: TrackerOrigin;

  readonly filterComponents = FILTER_ID_TO_COMPONENT;
  readonly filterIdToPreviewId = FILTER_ID_TO_FILTER_PREVIEW_ID;
  localValue: FilterModel = new FilterModel(this.filterId);

  get dataTypes(): Array<DictionaryType> {
    let dictionaryTypes = FILTER_ID_TO_DATA_TYPES[this.filterId];

    if (
      DICTIONARIES_BY_REPORT_TYPE[this.reportFilter.reportId].includes(
        DictionaryType.ALL_AD_NETWORK_NAMES
      )
    ) {
      dictionaryTypes = dictionaryTypes.map((type) =>
        type === DictionaryType.ROOT_AD_NETWORK_NAMES
          ? DictionaryType.ALL_AD_NETWORK_NAMES
          : type
      );
    }

    if (
      DICTIONARIES_BY_REPORT_TYPE[this.reportFilter.reportId].includes(
        DictionaryType.PRICED_NETWORK_NAMES
      )
    ) {
      dictionaryTypes = dictionaryTypes.map((type) =>
        type === DictionaryType.ROOT_AD_NETWORK_NAMES
          ? DictionaryType.PRICED_NETWORK_NAMES
          : type
      );
    }

    return dictionaryTypes;
  }

  // TODO: REPORTS temp computed prop
  get wrapperComponent() {
    if (this.newLayout) {
      return "div";
    }

    return [
      FilterId.COUNTRY,
      FilterId.SEGMENT,
      FilterId.TRACKER,
      FilterId.SOURCE,
    ].includes(this.filterId)
      ? DetachedReportFilterWrapper
      : CommonReportFilterWrapper;
  }

  @Watch("reportFilter", { immediate: true, deep: true })
  private watchReportFilter() {
    this.localValue = ReportUtil.getFilterModel(
      this.reportFilter,
      this.filterId,
      this.defaultValues
    );
  }

  @Watch("localValue", { deep: true })
  private watchLocalValue(localValue: FilterModel) {
    ReportUtil.setFilterModel(this.reportFilter, localValue);
  }
}
