































































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import { uniq } from "lodash";

import SidebarPanel from "./SidebarPanel.vue";
import { PredefinedMetricModel, ReportMetricType } from "@/reports/models";
import ValidUtil from "@/shared/utils/ValidUtil";
import { VueAutocomplete, VueForm } from "@/shared/types/ExtendedVueType";

@Component({
  components: {
    SidebarPanel,
  },
})
export default class PredefinedMetricsForm extends Vue {
  @Prop() value!: boolean;
  @Prop() predefinedMetricBeingEdited!: PredefinedMetricModel | null;
  @Prop() selectedPredefinedMetricsIds!: Array<ReportMetricType>;
  @Prop() lastAddedCohortDays!: Array<number>;

  @Ref("form") form!: VueForm;
  @Ref("autocomplete") readonly autocomplete!: VueAutocomplete;

  readonly PredefinedMetricModel = PredefinedMetricModel;
  readonly requiredArray = [
    ValidUtil.requiredArray(this.$lang("validation.required")),
  ];
  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];

  valid = false;
  search = "";
  localValue = new PredefinedMetricModel();

  get visible(): boolean {
    return this.value;
  }

  set visible(value: boolean) {
    if (!value) {
      this.resetLocalValue();
    } else if (value && !this.predefinedMetricBeingEdited) {
      // Нужно делей небольшой, потому что есть анимация выезда для сайдбара
      setTimeout(() => {
        this.autocomplete.focus();
        this.autocomplete.activateMenu();
      }, 200);
    }

    this.$emit("input", value);
  }

  get sidebarTitle(): string {
    if (this.predefinedMetricBeingEdited) {
      return this.$lang("views.metrics.editPredefinedMetric");
    }

    return this.$lang("views.metrics.newPredefinedMetric");
  }

  get noDataText(): string {
    // mb it needs
    // || Number(value) % 1 !== 0
    return isNaN(Number(this.search)) || Number(this.search) < 0
      ? this.$lang("comboboxNoDataText")
      : this.$lang("comboboxNoDataTextOption", this.search || "");
  }

  get appId(): string {
    return this.$store.state.application.applicationId;
  }

  get hasReportsMetricsPredicted(): boolean {
    return this.$store.getters.applicationsById.get(this.appId)
      ?.hasReportsMetricsPredicted;
  }

  @Watch("predefinedMetricBeingEdited")
  private watchPredefinedMetricBeingEdited(
    value: PredefinedMetricModel | null
  ) {
    if (value) {
      this.localValue = PredefinedMetricModel.of(value);
    }
  }

  getMetricItems(
    selectedType?: ReportMetricType
  ): Array<Record<string, string>> {
    return Object.entries(ReportMetricType)
      .reduce((result: Array<Record<string, string>>, [key, value]) => {
        if (
          this.selectedPredefinedMetricsIds.some(
            (id) => id !== selectedType && id === value
          ) ||
          ([
            ReportMetricType.LIFE_TIME_VALUE,
            ReportMetricType.LIFE_TIME_VALUE_PER_USER,
          ].includes(value) &&
            !this.hasReportsMetricsPredicted)
        ) {
          return result;
        }

        result.push({
          text: this.$lang(`views.metrics.tableHeader.${key.toLowerCase()}`),
          value,
        });

        return result;
      }, [] as Array<Record<string, string>>)
      .sort((itemA, itemB) => {
        const textA = itemA.text.toLowerCase();
        const textB = itemB.text.toLowerCase();

        if (textA < textB) {
          return -1;
        } else if (textA > textB) {
          return 1;
        }

        return 0;
      });
  }

  getDaysItemsByMetricType(id?: ReportMetricType): Array<number> {
    return id ? PredefinedMetricModel.getDaysItemsByMetricType(id) : [];
  }

  handleChangeMetricType() {
    if (this.getDaysItemsByMetricType(this.localValue.id).length) {
      this.localValue.days = this.lastAddedCohortDays.length
        ? this.lastAddedCohortDays
        : [3, 7, 14, 30];
    } else {
      this.localValue.days = [];
    }
  }

  handleInputDays(values: Array<string | number>) {
    this.localValue.days = uniq(
      values.reduce((res: Array<number>, value) => {
        if (
          isNaN(Number(value)) ||
          Number(value) < 0 ||
          Number(value) % 1 !== 0
        ) {
          return res;
        }

        return res.concat([Number(value)]);
      }, [])
    );
  }

  emitSave(isCreateOneMoreMetric: boolean) {
    if (!this.form.validate()) {
      return;
    }

    this.$emit("save", {
      value: this.localValue,
      isCreateOneMoreMetric,
    });

    this.resetLocalValue();
  }

  resetLocalValue() {
    this.localValue = new PredefinedMetricModel();
    this.form.resetValidation();
  }
}
