














































































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import { ReportResultItem } from "../models/Report";
import { ReportHeader } from "../models/ReportHeader";
import {
  SearchCustomValue,
  SearchModel,
  SearchType,
} from "../models/SearchModel";

@Component
export default class TableSearch extends Vue {
  @Prop() headers!: Array<ReportHeader>;
  @Prop() items!: Array<ReportResultItem>;

  @Ref() combobox!: any;

  // TODO: need to remake this component in vue 3
  searchItem: SearchModel | null = null;
  searchInput = "";
  selectedItems: Array<SearchModel> = [];
  selectedItem: SearchModel | null = null;
  searchItems: Array<SearchModel> = this.getInitialSearchItems();
  initSearchItems: Array<SearchModel> = this.getInitialSearchItems();

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  @Watch("headers")
  watchHeaders() {
    this.selectedItems = [];
    this.searchItems = this.getInitialSearchItems();
    this.initSearchItems = this.getInitialSearchItems();
  }

  @Watch("items", { immediate: true })
  watchItems() {
    this.filterLocalDataTableItems();
  }

  getInitialSearchItems(): Array<SearchModel> {
    return SearchModel.ofArray([
      // Mb this needs in the future
      // { header: this.$lang("search.properties") },
      { header: this.$lang("search.includedColumnsInSearch") },
      ...this.headers.flatMap(({ fractionDigits, text, value }: ReportHeader) =>
        this.getHeaderItemType(fractionDigits, value) === SearchType.NUMBER
          ? [
              new SearchModel(
                text,
                value,
                "",
                this.getHeaderItemType(fractionDigits, value),
                undefined,
                "="
              ),
              // -greater and -less -- I chose this symbol ("-") because we already have _ for cohort metrics in header.value (ex. data.arpdauCohort_3).
              // To prevent checks for 1 or 2 symbols ("_")
              // записывать -greate и -less в value лучше пересмотреть на vue 3, потому что потенциально бэк может присылать эти символы и тогда опять сломается
              new SearchModel(
                text,
                value + "-greater",
                "",
                this.getHeaderItemType(fractionDigits, value),
                "greater",
                ">"
              ),
              new SearchModel(
                text,
                value + "-less",
                "",
                this.getHeaderItemType(fractionDigits, value),
                "less",
                "<"
              ),
            ]
          : [
              new SearchModel(
                text,
                value,
                "",
                this.getHeaderItemType(fractionDigits, value)
              ),
            ]
      ),
    ]);
  }

  getHeaderItemType(
    fractionDigits: number | undefined,
    value: string
  ): SearchType {
    if (fractionDigits !== undefined) {
      return SearchType.NUMBER;
    } else if (value === "date") {
      return SearchType.DATE;
    }

    return SearchType.STRING;
  }

  async onChangeSearch(item: SearchModel | string) {
    if (typeof item === "object") {
      await this.$nextTick();
      this.searchInput =
        (item as SearchModel).name + (item.operation ? ` ${item.desc} ` : ": ");
      this.selectedItem = item;
    } else {
      this.handleTextSearch();
    }
  }

  async handleTextSearch() {
    const [firstItem, secondItem] = this.searchInput.split(
      `${
        this.searchInput.includes(" > ")
          ? " > "
          : this.searchInput.includes(" < ")
          ? " < "
          : ": "
      }`
    );

    if (firstItem && secondItem) {
      const found = this.searchItems.find(
        (item: SearchModel) =>
          item.name === firstItem &&
          item.operation === this.selectedItem?.operation
      );

      if (found && !secondItem) {
        return;
      }

      if (found) {
        found.searchValue = secondItem;
        this.selectedItems.push(found);
        this.searchItems = this.searchItems.filter(
          (item: SearchModel) => item.value !== found.value
        );
      }
    } else {
      const found = this.selectedItems.find(
        (item: SearchModel) => item.value === SearchCustomValue.SEARCH_ALL
      );

      if (found) {
        found.searchValue = this.searchInput;
      } else {
        this.selectedItems.push(
          new SearchModel(
            this.$lang("search.searchAllOver"),
            SearchCustomValue.SEARCH_ALL,
            this.searchInput,
            SearchType.STRING
          )
        );
      }
    }

    this.filterLocalDataTableItems();
    await this.$nextTick();
    this.resetSearchData();
  }

  resetSearchData() {
    this.combobox.blur();
    this.searchItem = null;
    this.searchInput = "";
    this.selectedItem = null;
  }

  removeSearchItem(removedItem: SearchModel) {
    this.selectedItems = this.selectedItems.filter(
      ({ value }: SearchModel) => value !== removedItem.value
    );
    const foundIndex = this.initSearchItems.findIndex(
      ({ value }: SearchModel) => value === removedItem.value
    );

    if (foundIndex >= 0) {
      this.searchItems = this.initSearchItems.filter(({ value }: SearchModel) =>
        this.selectedItems.every((item: SearchModel) => item.value !== value)
      );
    }

    this.filterLocalDataTableItems();
  }

  filterLocalDataTableItems() {
    const emitedItems = this.selectedItems.length
      ? this.items.filter((it: ReportResultItem) =>
          this.selectedItems.every((selectedItem: SearchModel) => {
            if (
              selectedItem.type === SearchType.STRING &&
              selectedItem.value !== SearchCustomValue.SEARCH_ALL
            ) {
              return String(
                it.data[(selectedItem.value as string).split(".")[1]]
              )
                .toLowerCase()
                .includes((selectedItem.searchValue as string).toLowerCase());
            } else if (
              selectedItem.type === SearchType.STRING &&
              selectedItem.value === SearchCustomValue.SEARCH_ALL
            ) {
              return (
                Object.values(it.data).some((item) => {
                  return String(item)
                    .toLowerCase()
                    .includes(
                      (selectedItem.searchValue as string).toLowerCase()
                    );
                }) ||
                it.formattedDate
                  .toLowerCase()
                  .includes((selectedItem.searchValue as string).toLowerCase())
              );
            } else if (selectedItem.type === SearchType.DATE) {
              return it.formattedDate
                .toLowerCase()
                .includes((selectedItem.searchValue as string).toLowerCase());
            }

            return selectedItem.value?.includes("-greater")
              ? it.data[
                  (selectedItem.value as string).split("-")[0].split(".")[1]
                ] > Number(selectedItem.searchValue)
              : selectedItem.value?.includes("-less")
              ? it.data[
                  (selectedItem.value as string).split("-")[0].split(".")[1]
                ] < Number(selectedItem.searchValue)
              : it.data[(selectedItem.value as string).split(".")[1]] ===
                Number(selectedItem.searchValue);
          })
        )
      : this.items;

    this.$emit("changeLocalDataTableItems", emitedItems);
    this.$emit("update:search", !!this.selectedItems.length);
  }
}
