































import { Component, Vue, Prop, Emit } from "vue-property-decorator";

import Chart from "@/chart/components/Chart.vue";
import ChartAlert from "@/alerts-system/components/ChartAlert.vue";
import { ReportFilter, ReportResultItem, ReportType } from "@/reports/models";
import { ChartName } from "@/chart/models/ChartModel";
import { getReportChartData } from "@/chart/utils/ChartUtil";
import ReportUtil from "../utils/ReportUtil";

@Component({
  components: {
    Chart,
    ChartAlert,
  },
})
export default class ReportChartDialog extends Vue {
  @Prop() value!: boolean;
  @Prop() report!: ReportFilter;
  @Prop({ default: () => [] }) items!: Array<ReportResultItem>;
  @Prop({ default: () => new Map() }) filter!: Map<string, string>;

  get isLoading() {
    return this.$store.state.reportStore.isReportLoading;
  }

  get dialogHeading() {
    const reportName = ReportUtil.getReportNameByReportType(
      this.$route.name as ReportType
    );
    const groupedBy = Array.from(this.filter)
      .map(([, value]) => value)
      .join(", ");

    return this.$lang("chartDialogHeading", reportName, groupedBy);
  }

  get chartNames(): Array<ChartName> {
    return this.report?.groupedCharts ?? [];
  }

  get chartData(): Array<Record<string, any>> {
    return getReportChartData(this.items, this.filter);
  }

  @Emit("input")
  hideDialog() {
    return false;
  }
}
