









import { Component, Vue, Prop } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import { FilterPreviewId } from "@/shared/models";
import { NoMonitoringTableExtension } from "@/reports/models";

@Component
export default class NoMonitoringFilterVisualization extends Vue {
  @Prop() filterPreviewId!: FilterPreviewId;

  get localReport(): NoMonitoringTableExtension {
    return this.$store.state.reportStore.localReport;
  }

  get localValue(): boolean {
    return this.localReport.noMonitoringTable;
  }

  set localValue(value: boolean) {
    const clonedLocalReport = cloneDeep(this.localReport);

    clonedLocalReport.noMonitoringTable = value;
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }
}
