










































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { cloneDeep, isEqual, sortBy } from "lodash";

import CustomAutocomplete from "@/shared/components/pickers/CustomAutocomplete.vue";
import IncludedIcon from "@/shared/components/IncludedIcon.vue";
import {
  Application,
  ApplicationStatus,
  CurrentUserModel,
  FilterPreviewId,
} from "@/shared/models";
import { MultiAppsReportFilter } from "@/reports/models";

@Component({ components: { CustomAutocomplete, IncludedIcon } })
export default class ApplicationsFilterVisualization extends Vue {
  @Prop() filterPreviewId!: FilterPreviewId;
  @Prop() isAttached!: boolean;

  archivedVisible = false;

  get currentUser(): CurrentUserModel {
    return this.$store.state.userStore.currentUser;
  }

  get apps(): Array<Application> {
    return this.$store.state.application.apps.filter(({ id }: Application) =>
      this.currentUser.availableReportApps
        .get(this.localReport.reportId)
        ?.includes(id)
    );
  }

  get items(): Array<Application> {
    return this.apps.filter(
      ({ platformType, status, id }) =>
        (!this.localReport.platforms.length ||
          this.localReport.platforms.includes(platformType)) &&
        (this.archivedVisible ||
          this.localAppsValue.includes(id) ||
          status === ApplicationStatus.ACTIVE)
    );
  }

  get localReport(): MultiAppsReportFilter {
    return this.$store.state.reportStore.localReport;
  }

  get loading(): boolean {
    return this.$store.state.application.loadingApps;
  }

  get applicationId(): string {
    return this.$route.params.id;
  }

  get localAppsValue(): Array<string> {
    return this.localReport.apps;
  }

  set localAppsValue(value: Array<string>) {
    const clonedLocalReport = cloneDeep(this.localReport);

    clonedLocalReport.apps = value;
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }

  get localIncludedAppsValue(): boolean {
    return this.localReport.includedApps;
  }

  set localIncludedAppsValue(value: boolean) {
    const clonedLocalReport = cloneDeep(this.localReport);

    clonedLocalReport.includedApps = value;
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }

  get slotName(): string {
    return this.isAttached ? "append-outer" : "prepend";
  }

  @Watch("localReport.platforms")
  watchPlatforms() {
    if (!this.localReport.platforms.length) {
      return;
    }

    const updatedApps = this.localReport.availableApps.reduce(
      (result: Array<string>, { id, platformType }) => [
        ...result,
        ...(this.localReport.platforms.includes(platformType) &&
        this.localAppsValue.includes(id)
          ? [id]
          : []),
      ],
      []
    );

    if (isEqual(sortBy(this.localAppsValue), sortBy(updatedApps))) {
      return;
    }

    this.localAppsValue = updatedApps;
  }

  @Watch("applicationId", { immediate: true })
  watchApplicationId() {
    if (Object.keys(this.$route.query).length) {
      return;
    }

    if (!this.$store.getters.isMultiAppMode) {
      this.localAppsValue = [this.applicationId];
    }
  }
}
