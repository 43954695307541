













import { Component, Vue, Prop } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import CustomSelect from "@/shared/components/pickers/CustomSelect.vue";
import { Dictionary, FilterPreviewId } from "@/shared/models";
import { PerminReportTypeReportFilterExtension } from "@/reports/models";
import { PerMinReportType } from "@/reports/models/PerMinReportType";

@Component({ components: { CustomSelect } })
export default class PerminReportTypeFilterVisualization extends Vue {
  @Prop() filterPreviewId!: FilterPreviewId;
  @Prop() isAttached!: boolean;

  readonly items = [
    new Dictionary(
      PerMinReportType.PLAY_TIME,
      this.$lang(`views.perMin.${PerMinReportType.PLAY_TIME.toLowerCase()}`)
    ),
    new Dictionary(
      PerMinReportType.TIME_SPENT,
      this.$lang(`views.perMin.${PerMinReportType.TIME_SPENT.toLowerCase()}`)
    ),
  ];

  get localReport(): PerminReportTypeReportFilterExtension {
    return this.$store.state.reportStore.localReport;
  }

  get localValue(): PerMinReportType {
    return this.localReport.reportType;
  }

  set localValue(value: PerMinReportType) {
    const clonedLocalReport = cloneDeep(this.localReport);

    clonedLocalReport.reportType = value;
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }
}
