















import { Component, Vue, Prop } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import CustomSelect from "@/shared/components/pickers/CustomSelect.vue";
import { FilterPreviewId } from "@/shared/models";
import {
  ConversionValue,
  ConversionValueReportFilterExtension,
} from "@/reports/models";

@Component({ components: { CustomSelect } })
export default class ConversionValueFilterVisualization extends Vue {
  @Prop() filterPreviewId!: FilterPreviewId;
  @Prop() isAttached!: boolean;

  readonly items = Object.values(ConversionValue).map((value) => ({
    value,
    text: this.$lang(`views.conversionValue.${value.toLowerCase()}`),
  }));

  get localReport(): ConversionValueReportFilterExtension {
    return this.$store.state.reportStore.localReport;
  }

  get localValue(): Array<ConversionValue> {
    return this.localReport.conversionValue;
  }

  set localValue(value: Array<ConversionValue>) {
    const clonedLocalReport = cloneDeep(this.localReport);

    clonedLocalReport.conversionValue = value;
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }
}
