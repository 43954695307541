var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-combobox',{ref:"combobox",staticClass:"px-3 pt-0 my-search",attrs:{"items":_vm.searchItems,"search-input":_vm.searchInput,"label":_vm.$lang('search.label'),"menu-props":{
    zIndex: 2,
  },"item-text":"name","item-value":"value","append-icon":"search","hide-details":"","hide-selected":"","outlined":"","dense":"","single-line":""},on:{"update:searchInput":function($event){_vm.searchInput=$event},"update:search-input":function($event){_vm.searchInput=$event},"change":_vm.onChangeSearch},scopedSlots:_vm._u([{key:"item",fn:function(ref){
  var item = ref.item;
return [_c('v-list-item-content',[(item.desc)?_c('v-list-item-title',[_c('span',[_vm._v(_vm._s(item.name))]),_c('v-chip',{staticClass:"ml-2",attrs:{"outlined":"","x-small":""}},[_vm._v(_vm._s(item.desc))])],1):_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.name)}})],1)]}},{key:"prepend-inner",fn:function(){return _vm._l((_vm.selectedItems),function(item){return _c('v-chip',{key:item.value,attrs:{"close":"","color":"primary","outlined":"","small":""},on:{"click:close":function($event){return _vm.removeSearchItem(item)}}},[(item.value === 'impreciseComparison')?_c('b',[_vm._v(_vm._s(item.name))]):[_c('b',[_vm._v(" "+_vm._s(item.name)),(item.operation)?[_vm._v(" "+_vm._s(item.desc))]:[_vm._v(":")]],2),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(item.searchValue))])]],2)})},proxy:true},{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$lang("search.press"))+" "),_c('kbd',{class:_vm.dark ? 'white black--text' : ''},[_vm._v(_vm._s(_vm.$lang("search.enter")))]),_c('span',{domProps:{"innerHTML":_vm._s(
              !_vm.searchItem
                ? _vm.$lang('search.toSearchAllOver')
                : _vm.$lang('search.toSearchByColumn', _vm.searchItem.name)
            )}})])],1)],1)]},proxy:true}]),model:{value:(_vm.searchItem),callback:function ($$v) {_vm.searchItem=$$v},expression:"searchItem"}})}
var staticRenderFns = []

export { render, staticRenderFns }