export enum SearchCustomValue {
  SEARCH_ALL = "searchAll",
}

export enum SearchType {
  NUMBER = "number",
  STRING = "string",
  DATE = "date",
}

export class SearchModel {
  constructor(
    public name?: string,
    public value?: string | SearchCustomValue,
    public searchValue?: string,
    public type?: SearchType,
    public operation?: string,
    public desc?: string,
    public header?: string
  ) {}

  static ofSource(model: SearchModel): SearchModel {
    return new SearchModel(
      model.name,
      model.value,
      model.searchValue,
      model.type,
      model.operation,
      model.desc,
      model.header
    );
  }

  static ofArray(items: Array<SearchModel>) {
    return items.map((item) => SearchModel.ofSource(item));
  }
}
