















import { Component, Vue, Prop } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import CustomSelect from "@/shared/components/pickers/CustomSelect.vue";
import { AdRevenueMethod, FilterPreviewId } from "@/shared/models";
import ValidUtil from "@/shared/utils/ValidUtil";
import { AdRevenueMethodReportFilterExtension } from "@/reports/models";

@Component({ components: { CustomSelect } })
export default class AdRevenueMethodFilterVisualization extends Vue {
  @Prop() filterPreviewId!: FilterPreviewId;
  @Prop() disabled!: boolean;
  @Prop() isAttached!: boolean;

  readonly items = [
    {
      value: AdRevenueMethod.PRICED,
      text: this.$lang("components.adRevenueMethodPicker.priced"),
    },
    {
      value: AdRevenueMethod.AVERAGE,
      text: this.$lang("components.adRevenueMethodPicker.average"),
    },
  ];
  readonly rules = [ValidUtil.required(this.$lang("validation.required"))];

  get localReport(): AdRevenueMethodReportFilterExtension {
    return this.$store.state.reportStore.localReport;
  }

  get localValue(): AdRevenueMethod {
    return this.localReport.adRevenueMethod;
  }

  set localValue(value: AdRevenueMethod) {
    const clonedLocalReport = cloneDeep(this.localReport);

    clonedLocalReport.adRevenueMethod = value;
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }
}
