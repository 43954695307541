
















import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import CustomAutocomplete from "@/shared/components/pickers/CustomAutocomplete.vue";
import {
  Application,
  CurrentUserModel,
  Dictionary,
  FilterPreviewId,
} from "@/shared/models";
import { MultiAppsReportFilter } from "@/reports/models";

@Component({ components: { CustomAutocomplete } })
export default class PlatformsFilterVisualization extends Vue {
  @Prop() filterPreviewId!: FilterPreviewId;
  @Prop() isAttached!: boolean;

  get currentUser(): CurrentUserModel {
    return this.$store.state.userStore.currentUser;
  }

  get apps(): Array<Application> {
    return this.$store.state.application.apps.filter(({ id }: Application) =>
      this.currentUser.availableReportApps
        .get(this.localReport.reportId)
        ?.includes(id)
    );
  }

  get items(): Array<Dictionary> {
    const availablePlatforms = this.apps.reduce(
      (result: Array<string>, { platformType }) =>
        result.includes(platformType) ? result : result.concat([platformType]),
      []
    );

    return this.$store.state.application.platforms.filter(
      ({ value }: Dictionary) => availablePlatforms.includes(value)
    );
  }

  get localReport(): MultiAppsReportFilter {
    return this.$store.state.reportStore.localReport;
  }

  get localValue(): Array<string> {
    return this.localReport.platforms;
  }

  set localValue(value: Array<string>) {
    const clonedLocalReport = cloneDeep(this.localReport);

    clonedLocalReport.platforms = value;
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }

  get loading(): boolean {
    return this.$store.state.application.loadingApps;
  }

  get applicationId(): string {
    return this.$route.params.id;
  }

  @Watch("applicationId", { immediate: true })
  watchApplicationId() {
    if (
      Object.keys(this.$route.query).length ||
      this.$store.getters.isMultiAppMode
    ) {
      return;
    }

    const found = this.apps.find(
      ({ id }: Application) => id === this.applicationId
    );

    if (found) {
      this.localValue = [found.platformType];
    }
  }
}
