




























import { Component, Vue, Prop } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import CustomAutocomplete from "@/shared/components/pickers/CustomAutocomplete.vue";
import IncludedIcon from "@/shared/components/IncludedIcon.vue";
import { FilterPreviewId } from "@/shared/models";
import { UaMainMetricsOverviewFilter } from "@/reports/models";

@Component({ components: { CustomAutocomplete, IncludedIcon } })
export default class CohortMetricsDaysFilterVisualization extends Vue {
  @Prop() filterPreviewId!: FilterPreviewId;
  @Prop() isAttached!: boolean;

  get items() {
    return [
      {
        value: 1,
        text: "D1",
      },
      {
        value: 3,
        text: "D3",
      },
      {
        value: 7,
        text: "D7",
      },
      {
        value: 14,
        text: "D14",
      },
      {
        value: 30,
        text: "D30",
      },
      {
        value: 60,
        text: "D60",
      },
      {
        value: 90,
        text: "D90",
      },
      {
        value: 120,
        text: "D120",
      },
      {
        value: 180,
        text: "D180",
      },
      {
        value: 365,
        text: "D365",
      },
    ];
  }

  get slotName(): string {
    return this.isAttached ? "append-outer" : "prepend";
  }

  get localReport(): UaMainMetricsOverviewFilter {
    return this.$store.state.reportStore.localReport;
  }

  get localValue(): Array<number> {
    return this.localReport.cohortMetricsDays;
  }

  set localValue(value: Array<number>) {
    const clonedLocalReport = cloneDeep(this.localReport);

    clonedLocalReport.cohortMetricsDays = value.sort(
      (itemA, itemB) => itemA - itemB
    );
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }

  handleRemoveItem(item: { value: number; text: string }) {
    this.localValue = this.localValue.filter(
      (value: number) => value !== item.value
    );
  }
}
