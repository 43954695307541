













import { Component, Vue, Prop } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import CustomSelect from "@/shared/components/pickers/CustomSelect.vue";
import { Dictionary } from "@/shared/models";
import { TimeSpentReportType } from "@/reports/models";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component({ components: { CustomSelect } })
export default class TimeSpentTypeFilter extends Vue {
  @Prop() value!: any;
  @Prop({ default: false }) isAttached!: boolean;

  readonly items = [
    new Dictionary(
      TimeSpentReportType.PLAY_TIME,
      this.$lang(
        `components.metrics.${TimeSpentReportType.PLAY_TIME.toLowerCase()}`
      )
    ),
    new Dictionary(
      TimeSpentReportType.TIME_SPENT,
      this.$lang(
        `components.metrics.${TimeSpentReportType.TIME_SPENT.toLowerCase()}`
      )
    ),
  ];
  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];

  get localValue(): TimeSpentReportType {
    return this.value.timeSpentType;
  }

  set localValue(value: TimeSpentReportType) {
    const clonedLocalValue = cloneDeep(this.value);

    clonedLocalValue.timeSpentType = value;
    this.$emit("input", clonedLocalValue);
  }
}
