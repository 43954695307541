







































































































































































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import ConfirmedItem from "./ConfirmedItem.vue";
import CohortFilter from "./filters/CohortFilter.vue";
import AdRevenueMethodFilter from "./filters/AdRevenueMethodFilter.vue";
import RevenueFilter from "./filters/RevenueFilter.vue";
import EventsFilter from "./filters/EventsFilter.vue";
import BannerProvidedFilter from "./filters/BannerProvidedFilter.vue";
import CumulativeFilter from "./filters/CumulativeFilter.vue";
import TimeSpentTypeFilter from "./filters/TimeSpentTypeFilter.vue";
import {
  BasicMetricType,
  BasicMetricModel,
  BasicMetricFilterProvider,
  BasicMetricFilterModel,
  MetricStepId,
  UserMetricModel,
  OperationType,
  AggregatorStrategyType,
} from "@/reports/models";
import { FilterPreviewId } from "@/shared/models";
import ValidUtil from "@/shared/utils/ValidUtil";
import { VueForm } from "@/shared/types/ExtendedVueType";

@Component({
  components: {
    ConfirmedItem,
    CohortFilter,
    AdRevenueMethodFilter,
    RevenueFilter,
    EventsFilter,
    BannerProvidedFilter,
    CumulativeFilter,
    TimeSpentTypeFilter,
  },
})
export default class BasicMetrics extends Vue {
  @Prop() value!: UserMetricModel;
  @Prop({ default: false }) isDone!: boolean;

  @Ref("form") form!: VueForm;
  @Ref("autocomplete") readonly autocomplete!: any;

  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  valid = false;
  localBasicMetricsValue: Array<BasicMetricModel> = [new BasicMetricModel()];

  get isLocalDone(): boolean {
    return this.isDone;
  }

  set isLocalDone(value: boolean) {
    this.$emit("changeIsDone", {
      value,
      stepValue: MetricStepId.BASIC_METRICS,
    });
  }

  get basicMetrics(): Array<{ value: string; text: string }> {
    return Object.values(BasicMetricType).reduce(
      (
        result: Array<{ value: string; text: string }>,
        value: BasicMetricType
      ) => {
        if (
          this.localBasicMetricsValue.some(
            ({ filter: { type }, isDone }) => type === value && !!isDone
          )
        ) {
          return result;
        }

        result.push({
          value,
          text: this.$lang(`views.metrics.basicMetrics.${value.toLowerCase()}`),
        });

        return result;
      },
      []
    );
  }

  get addMetricBtnVisible(): boolean {
    return (
      this.localBasicMetricsValue.every(({ isDone }) => isDone) &&
      this.localBasicMetricsValue.length < 2
    );
  }

  get confirmStepBtnVisible(): boolean {
    return (
      !!this.localBasicMetricsValue.length &&
      this.localBasicMetricsValue.every(({ isDone }) => isDone)
    );
  }

  get confirmedItemText(): string {
    return this.value.definition.basicMetrics
      .map(({ filter: { type } }) =>
        this.$lang(`views.metrics.basicMetrics.${type.toLowerCase()}`)
      )
      .join(", ");
  }

  get hasFirebaseBannersFeature(): boolean {
    return this.$store.getters.applicationsById.get(this.$route.params.id)
      ?.hasFirebaseBannersFeature;
  }

  @Watch("value.definition.basicMetrics", { deep: true, immediate: true })
  private watchDefinitionBasicMetrics(value: Array<BasicMetricModel>) {
    this.localBasicMetricsValue = cloneDeep(value);
  }

  mounted() {
    this.handleAutofocusForAutocomplete();
  }

  handleAutofocusForAutocomplete() {
    if (this.autocomplete) {
      this.autocomplete[0].focus();
      this.autocomplete[0].activateMenu();
    }
  }

  handleChangeMetricName(value: BasicMetricType, index: number) {
    const tempBasicMetrics = cloneDeep(this.localBasicMetricsValue);

    tempBasicMetrics[index].filter = !value
      ? new BasicMetricFilterModel()
      : BasicMetricFilterProvider.createFilter(value);
    tempBasicMetrics[index].cohort =
      value === BasicMetricType.PREDICTED_REVENUE;

    this.localBasicMetricsValue = tempBasicMetrics;
  }

  getSupportedFilters(item: any): Array<FilterPreviewId> {
    return this.hasFirebaseBannersFeature
      ? item.filter.supportedFilters
      : item.filter.supportedFilters.filter(
          (item: FilterPreviewId) => item !== FilterPreviewId.PROVIDED_BANNER
        );
  }

  getComponentByFilterId(filterPreviewId: FilterPreviewId): string | undefined {
    return new Map([
      [FilterPreviewId.COHORT, "CohortFilter"],
      [FilterPreviewId.AD_REVENUE_METHOD, "AdRevenueMethodFilter"],
      [FilterPreviewId.REVENUE, "RevenueFilter"],
      [FilterPreviewId.EVENT, "EventsFilter"],
      [FilterPreviewId.PROVIDED_BANNER, "BannerProvidedFilter"],
      [FilterPreviewId.CUMULATIVE, "CumulativeFilter"],
      [FilterPreviewId.TIME_SPENT_TYPE, "TimeSpentTypeFilter"],
    ]).get(filterPreviewId);
  }

  handleConfirmBasicMetric(index: number) {
    if (!this.form.validate()) {
      return;
    }

    const tempBasicMetrics = cloneDeep(this.localBasicMetricsValue);
    tempBasicMetrics[index].isDone = true;

    if (
      [
        BasicMetricType.REVENUE,
        BasicMetricType.REVENUE_ACTIVE_USERS_COUNT,
        BasicMetricType.REVENUE_EVENTS_COUNT,
      ].includes(tempBasicMetrics[index].filter.type as BasicMetricType)
    ) {
      if ((tempBasicMetrics[index].filter as any).isIapsOnly) {
        (tempBasicMetrics[index].filter as any).eventTypes = ["purchase"];
      } else if ((tempBasicMetrics[index].filter as any).isAdEventsOnly) {
        (tempBasicMetrics[index].filter as any).eventTypes = (
          tempBasicMetrics[index].filter as any
        ).eventTypes.filter((item: string) => item !== "purchase");
      } else if (
        (tempBasicMetrics[index].filter as any).eventTypes.every(
          (item: string) => item !== "purchase"
        )
      ) {
        (tempBasicMetrics[index].filter as any).eventTypes.push("purchase");
      }
    }
    this.localBasicMetricsValue = tempBasicMetrics;
  }

  getConfirmedMetricItemText(item: any): Array<{ key: string; value: string }> {
    return [
      ...Object.entries(item.filter).reduce(
        (result: Array<{ key: string; value: string }>, [key, value]: any) => {
          if (key === "type") {
            return result;
          }

          result.push({
            key: this.$lang(`views.metrics.${key}`),
            value: Array.isArray(value)
              ? value.join(", ")
              : this.getValueText(key, value),
          });

          return result;
        },
        []
      ),
      ...(item.cohort !== undefined
        ? [
            {
              key: this.$lang(`views.metrics.cohort`),
              value: String(item.cohort),
            },
          ]
        : []),
      ...(item.cumulative !== undefined
        ? [
            {
              key: this.$lang(`views.metrics.cumulative`),
              value: String(item.cumulative),
            },
          ]
        : []),
    ];
  }

  getValueText(key: string, value: string | Array<any>): string {
    const filterMap = new Map<any, any>([
      ["timeSpentType", "components.metrics"],
      ["bannerProvider", "components.providedBannerPicker"],
      ["revenueMethod", "components.adRevenueMethodPicker"],
    ]);

    const filterMapValue = filterMap.get(key);

    if (filterMapValue) {
      return this.$lang(`${filterMapValue}.${(value as string).toLowerCase()}`);
    }

    return "";
  }

  async handleAddingNewMetric() {
    this.localBasicMetricsValue.push(new BasicMetricModel());
    await this.$nextTick();
    this.handleAutofocusForAutocomplete();
  }

  handleEditingItem(index: number) {
    const tempBasicMetrics = cloneDeep(this.localBasicMetricsValue);
    tempBasicMetrics[index].isDone = false;
    this.localBasicMetricsValue = tempBasicMetrics;
  }

  handleDeleteItemByIndex(index: number) {
    this.localBasicMetricsValue.splice(index, 1);
  }

  handleDeleteItem(item: BasicMetricModel) {
    this.localBasicMetricsValue = this.localBasicMetricsValue.filter(
      ({ filter: { type } }: BasicMetricModel) => type !== item.filter.type
    );
  }

  handleEditingStep() {
    this.$emit("editStep", MetricStepId.BASIC_METRICS);
  }

  handleDeleteStep() {
    this.localBasicMetricsValue = [new BasicMetricModel()];
    this.$emit("deleteStep", MetricStepId.BASIC_METRICS);
  }

  handleConfirmStep() {
    if (!this.form.validate()) {
      return;
    }

    const clonedValue = cloneDeep(this.value);
    clonedValue.definition.basicMetrics = this.localBasicMetricsValue;

    if (
      this.localBasicMetricsValue.length > 1 &&
      clonedValue.definition.resultSettings.operation ===
        OperationType.WITHOUT_PROCESSING
    ) {
      clonedValue.definition.resultSettings.operation = OperationType.ADD;
    } else if (
      this.localBasicMetricsValue.length > 1 &&
      JSON.stringify(
        this.localBasicMetricsValue.map((item) => item.filter.type).sort()
      ) !==
        JSON.stringify(clonedValue.definition.resultSettings.operands.sort())
    ) {
      clonedValue.definition.resultSettings.operands = [];
    } else if (this.localBasicMetricsValue.length === 1) {
      clonedValue.definition.resultSettings.operation =
        OperationType.WITHOUT_PROCESSING;
      clonedValue.definition.resultSettings.aggregatorStrategy =
        AggregatorStrategyType.SUM;
      clonedValue.definition.resultSettings.operands = [
        this.localBasicMetricsValue[0].filter.type as BasicMetricType,
      ];
    }

    this.$emit("confirmStep", {
      stepValue: MetricStepId.BASIC_METRICS,
      value: clonedValue,
    });
  }
}
