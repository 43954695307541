














import { Component, Prop, Vue } from "vue-property-decorator";

import ValidUtil from "@/shared/utils/ValidUtil";
import { AggregatorStrategyType, OperationType } from "@/reports/models";

@Component
export default class AggregationStrategy extends Vue {
  @Prop() value!: AggregatorStrategyType;
  @Prop() singleMetric!: boolean;
  @Prop() operation!: OperationType;

  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];

  get aggregatorStrategyItems(): Array<Record<string, any>> {
    return Object.values(AggregatorStrategyType).map(
      (value: AggregatorStrategyType) => ({
        value,
        text: this.$lang(`views.metrics.${value.toLowerCase()}`),
        disabled:
          value === AggregatorStrategyType.WEIGHTED_AVERAGE &&
          (this.singleMetric ||
            [
              OperationType.ADD,
              OperationType.SUBTRACT,
              OperationType.MULTIPLY,
            ].includes(this.operation)),
      })
    );
  }

  get localValue(): AggregatorStrategyType {
    return this.value;
  }

  set localValue(value: AggregatorStrategyType) {
    this.$emit("input", value);
  }
}
