


















import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import Chart from "@/chart/components/Chart.vue";
import { GroupByFilter } from "@/reports/models";
import { ChartName } from "@/chart/models/ChartModel";
import { DatesFilterModel, FilterId } from "@/shared/models";
import { getReportChartData } from "@/chart/utils/ChartUtil";

@Component({
  components: {
    Chart,
  },
})
export default class MonetizationMonitoringReportChart extends Vue {
  @Prop() chartName!: ChartName;
  @Prop() reportDataType!: any;
  @Prop() reportDate?: DatesFilterModel;
  @Prop() groupBy?: GroupByFilter;
  @Prop({ default: true }) hasTitle!: boolean;

  readonly MM_MAX_SERIES_COUNT = 15;
  chartData: Array<Record<string, any>> = [];
  series: Array<string> = [];
  seriesWithData: Array<string> = [];

  get loading(): boolean {
    return this.$store.state.reportStore.report[this.reportDataType].loading;
  }

  get emptyDataSeriesCount(): number {
    return this.series.length - this.seriesWithData.length;
  }

  get noData(): boolean {
    return (
      !!this.series.length && this.emptyDataSeriesCount === this.series.length
    );
  }

  get alertMessage(): string {
    const objectsToCount = [
      ChartName.REVENUE_DIFF_PERCENT_DATE_COUNTRY_BASED_DIFF_REVENUE_DAY,
      ChartName.REVENUE_DIFF_PERCENT_DATE_COUNTRY_BASED_DIFF_IMPRESSIONS_DAY,
      ChartName.ARPDAU_DIFF_DATE_COUNTRY_BASED_DIFF_REVENUE_PREV_DAY,
      ChartName.ARPDAU_DIFF_DATE_COUNTRY_BASED_DIFF_IMPRESSIONS_PREV_DAY,
      ChartName.ARPDAU_DIFF_DATE_COUNTRY_BASED_DIFF_REVENUE_PREV_YEAR,
      ChartName.ARPDAU_DIFF_DATE_COUNTRY_BASED_DIFF_IMPRESSIONS_PREV_YEAR,
    ].includes(this.chartName)
      ? "countries"
      : "networks";

    return this.noData
      ? this.$lang("chartCondition.noDataFoundForThePeriod")
      : this.$lang(
          "chartCondition.noDataFound",
          this.emptyDataSeriesCount,
          objectsToCount
        );
  }

  get itemsByReportDataType(): any {
    return this.$store.state.reportStore.report[this.reportDataType].items;
  }

  get computedData(): Array<Record<string, any>> {
    const filterId: FilterId | null =
      this.$store.state.reportStore.currentReport.groupByFilter.groupBy
        .length === 1
        ? this.$store.state.reportStore.currentReport.groupByFilter.groupBy[0]
        : null;

    return getReportChartData(
      this.itemsByReportDataType,
      undefined,
      this.chartName,
      filterId,
      undefined,
      this.MM_MAX_SERIES_COUNT
    );
  }

  @Watch("computedData", { immediate: true })
  getChartData() {
    if (!this.computedData.length) {
      this.chartData = [];
      this.series = [];
      this.seriesWithData = [];

      return;
    }

    this.series = Object.keys(this.computedData[0]).filter(
      (key: string) => key !== "date"
    );
    this.seriesWithData = this.series.filter((series) =>
      this.computedData.every((item) => item[series] !== null)
    );
    this.chartData = this.computedData.reduce(
      (result: Array<Record<string, any>>, item: Record<string, any>) => [
        ...result,
        {
          date: item.date,
          ...Object.entries(item).reduce(
            (resultItem: Record<string, any>, [key, value]) => ({
              ...resultItem,
              ...(this.seriesWithData.includes(key) ? { [key]: value } : {}),
            }),
            {}
          ),
        },
      ],
      []
    );
  }
}
