

























import { Component, Vue, Ref, Prop, Watch } from "vue-property-decorator";

import { ChartRenderer } from "@/chart/models/ChartRenderer";
import { ChartName } from "@/chart/models/ChartModel";
import AlertResponseModel from "@/alerts-system/models/AlertResponseModel";
import { CohortFilter, GroupByFilter } from "@/reports/models";
import { getReportChartData, getChartRenderer } from "@/chart/utils/ChartUtil";
import { getSystemAlertsForCharts } from "@/alerts-system/utils/AlertsSystemUtil";
import { DatesFilterModel, FilterId, MenuItemsTools } from "@/shared/models";

@Component
export default class ReportChart extends Vue {
  @Prop() reportDataType!: any;
  @Prop() chartName!: ChartName;
  @Prop({ default: true }) hasTitle!: boolean;
  @Prop() reportDate?: DatesFilterModel;
  @Prop() groupBy?: GroupByFilter;

  @Ref("element") readonly element!: HTMLElement;

  readonly aspectRatio = 16 / 9;
  readonly height = 600;

  root?: ChartRenderer;
  data: any = [];

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  get applicationId(): string {
    return this.$route.params.id;
  }

  get alerts(): Array<AlertResponseModel> {
    return getSystemAlertsForCharts(
      this.$store.state.alertsSystemStore.systemAlerts
    );
  }

  get loading(): boolean {
    return this.$store.state.reportStore.report[this.reportDataType].loading;
  }

  get itemsByReportDataType(): any {
    return this.$store.state.reportStore.report[this.reportDataType].items;
  }

  get computedData(): Array<Record<string, any>> {
    const filterId: FilterId | null =
      this.$store.state.reportStore.currentReport.groupByFilter.groupBy
        .length === 1
        ? this.$store.state.reportStore.currentReport.groupByFilter.groupBy[0]
        : null;

    return getReportChartData(
      this.itemsByReportDataType,
      undefined,
      this.chartName,
      filterId,
      (this.$store.state.reportStore.currentReport as CohortFilter).dayLimit
    );
  }

  @Watch("dark")
  watchDarkMode(value: boolean) {
    this.root?.setThemes(value);
  }

  @Watch("loading")
  @Watch("data")
  async watchLoading() {
    if (this.loading) {
      return;
    }

    this.data = this.computedData;

    await this.$nextTick();
    this.updateChart();
  }

  mounted() {
    if (!this.element || this.loading) {
      return;
    }

    this.updateChart();
  }

  updateChart() {
    if (this.root) {
      this.root.dispose();
    }

    if (!this.data.length) {
      return;
    }

    this.root = getChartRenderer(
      this.chartName,
      this.element,
      this.data,
      this.dark,
      this.$lang,
      this.$route.fullPath.includes("report") ? this.alerts : [],
      this.aspectRatio > 1,
      this.groupBy?.aggregationPeriod,
      this.reportDate?.to,
      this.$route.name === MenuItemsTools.MONETIZATION_MONITORING
    );
  }
}
