
















import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import CustomSelect from "@/shared/components/pickers/CustomSelect.vue";
import { DefaultValues, Dictionary } from "@/shared/models";
import { AdRevenueMethod } from "@/shared/models/FilterModel";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component({ components: { CustomSelect } })
export default class EventsFilter extends Vue {
  @Prop() value!: any;
  @Prop({ default: false }) isAttached!: boolean;

  get rules(): Array<any> {
    if (
      !this.isPricedMethod &&
      !this.localValue.filter((item) => item !== "purchase").length
    ) {
      return [ValidUtil.required(this.$lang("validation.required"))];
    }

    return [];
  }

  get localValue(): Array<string> {
    return this.value.eventTypes;
  }

  set localValue(value: Array<string>) {
    const clonedLocalValue = cloneDeep(this.value);

    clonedLocalValue.eventTypes = value;
    this.$emit("input", clonedLocalValue);
  }

  get isPricedMethod(): boolean {
    return this.value.revenueMethod === AdRevenueMethod.PRICED;
  }

  get isAdEventsOnly(): boolean {
    return this.value.isAdEventsOnly;
  }

  get items(): Array<Dictionary> {
    return this.$store.state.dictionaryStore.events.values;
  }

  @Watch("isPricedMethod")
  watchAdRevenueMethod(value: boolean) {
    if (value) {
      this.localValue = [DefaultValues.EVENT];
    }
  }

  @Watch("isAdEventsOnly", { immediate: true })
  watchIsAdEventsOnly() {
    this.localValue =
      this.localValue[0] === "purchase"
        ? [DefaultValues.EVENT]
        : [this.localValue[0]];
  }
}
