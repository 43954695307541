














import { Component, Vue, Prop } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import CustomSelect from "@/shared/components/pickers/CustomSelect.vue";
import {
  UserActivityReportFilterExtension,
  UsersActivityType,
} from "@/reports/models";
import { FilterPreviewId } from "@/shared/models";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component({ components: { CustomSelect } })
export default class UserActivityFilterVisualization extends Vue {
  @Prop() filterPreviewId!: FilterPreviewId;
  @Prop() isAttached!: boolean;

  readonly rules = [ValidUtil.required(this.$lang("validation.required"))];
  readonly items = [
    {
      value: UsersActivityType.ALL,
      text: this.$lang("components.usersActivityTypePicker.all"),
    },
    {
      value: UsersActivityType.AD_ACTIVE,
      text: this.$lang("components.usersActivityTypePicker.ad_active"),
    },
  ];

  get localReport(): UserActivityReportFilterExtension {
    return this.$store.state.reportStore.localReport;
  }

  get localValue(): UsersActivityType {
    return this.localReport.usersActivityType;
  }

  set localValue(value: UsersActivityType) {
    const clonedLocalReport = cloneDeep(this.localReport);

    clonedLocalReport.usersActivityType = value;
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }
}
