


















import { Component, Vue, Prop } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import EventParamFilter from "@/shared/components/filters/EventParamFilter.vue";
import { ReportFilter, ReportType } from "@/reports/models";
import {
  EventParamFilterRangeModel,
  FilterId,
  FilterPreviewId,
} from "@/shared/models";
import ReportUtil from "@/reports/utils/ReportUtil";

@Component({
  components: {
    EventParamFilter,
  },
})
export default class EventStatsFilterVisualization extends Vue {
  @Prop() isAttached!: boolean;
  @Prop() filterPreviewId!: FilterPreviewId;

  get hasValidation(): boolean {
    return [ReportType.MEASURES_LITE].includes(this.localReport.reportId);
  }

  get localReport(): ReportFilter {
    return this.$store.state.reportStore.localReport;
  }

  get localValue(): EventParamFilterRangeModel {
    return ReportUtil.getFilterModel(
      this.localReport,
      FilterId.EVENT
    ) as EventParamFilterRangeModel;
  }

  set localValue(value: EventParamFilterRangeModel) {
    const clonedLocalReport = cloneDeep(this.localReport);
    let foundIndex = clonedLocalReport.filter.findIndex(
      (item) => item.id === FilterId.EVENT
    );

    if (foundIndex >= 0) {
      clonedLocalReport.filter[foundIndex] =
        value as EventParamFilterRangeModel;
    } else {
      clonedLocalReport.filter.push(value);
    }

    if (!value.value.name) {
      clonedLocalReport.filter = clonedLocalReport.filter.filter(
        (item) => item.id !== FilterId.EVENT
      );
    }

    this.$store.commit("updateLocalReport", clonedLocalReport);
  }
}
