









import { Component, Vue, Prop } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import { BasicMetricModel } from "@/reports/models";

@Component
export default class CumulativeFilter extends Vue {
  @Prop() value!: BasicMetricModel;

  get localValue(): boolean {
    return this.value.cumulative;
  }

  set localValue(value: boolean) {
    const clonedLocalValue = cloneDeep(this.value);

    clonedLocalValue.cumulative = value;
    this.$emit("input", clonedLocalValue);
  }
}
