















import { Component, Vue, Prop } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import CustomSelect from "@/shared/components/pickers/CustomSelect.vue";
import { Dictionary } from "@/shared/models";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component({ components: { CustomSelect } })
export default class RevenueFilter extends Vue {
  @Prop() value!: any;
  @Prop({ default: false }) isAttached!: boolean;

  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];

  get localValue(): Array<string> {
    return this.value.revenueTypes;
  }

  set localValue(value: Array<string>) {
    const clonedLocalValue = cloneDeep(this.value);

    clonedLocalValue.revenueTypes = value;
    this.$emit("input", clonedLocalValue);
  }

  get items(): Array<Dictionary> {
    return this.$store.state.dictionaryStore.revenues.values.map(
      ({ name, value }: Dictionary) => ({
        text: name,
        value: value.toUpperCase(),
      })
    );
  }
}
