













import { Component, Vue, Prop } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import CustomSelect from "@/shared/components/pickers/CustomSelect.vue";
import { FilterPreviewId } from "@/shared/models";
import {
  SkadReportType,
  SkadReportTypeReportFilterExtension,
} from "@/reports/models";

@Component({ components: { CustomSelect } })
export default class SkadReportTypeFilterVisualization extends Vue {
  @Prop() filterPreviewId!: FilterPreviewId;
  @Prop() isAttached!: boolean;

  readonly items = Object.values(SkadReportType).map((value) => ({
    value,
    text: this.$lang(`views.skadReportType.${value.toLowerCase()}`),
  }));

  get localReport(): SkadReportTypeReportFilterExtension {
    return this.$store.state.reportStore.localReport;
  }

  get localValue(): SkadReportType {
    return this.localReport.reportType;
  }

  set localValue(value: SkadReportType) {
    const clonedLocalReport = cloneDeep(this.localReport);

    clonedLocalReport.reportType = value;
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }
}
