






















































import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import ValidUtil from "@/shared/utils/ValidUtil";
import {
  BasicMetricModel,
  BasicMetricType,
  OPERATION_BY_OPERATION_TYPE,
  OperationType,
  UserMetricResultModel,
} from "@/reports/models";
import { VueAutocomplete } from "@/shared/types/ExtendedVueType";

@Component
export default class Operation extends Vue {
  @Prop() value!: UserMetricResultModel;
  @Prop() basicMetrics!: Array<BasicMetricModel>;

  @Ref("autocomplete") readonly autocomplete!: VueAutocomplete;

  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  readonly operations = Object.entries(OperationType).reduce(
    (result: Array<{ value: OperationType; text: string }>, [, value]) => {
      if (value !== OperationType.WITHOUT_PROCESSING) {
        result.push({
          value,
          text: this.getOperationSignByOperationType(value),
        });
      }

      return result;
    },
    []
  );

  get operation(): OperationType {
    return this.value.operation;
  }

  set operation(value: OperationType) {
    const clonedLocalValue = cloneDeep(this.value);

    clonedLocalValue.operation = value;
    this.$emit("input", clonedLocalValue);
  }

  get operands(): Array<BasicMetricType> {
    return this.value.operands;
  }

  set operands(value: Array<BasicMetricType>) {
    const clonedLocalValue = cloneDeep(this.value);

    clonedLocalValue.operands = value;
    this.$emit("input", clonedLocalValue);
  }

  get metricsTypes(): Array<{ text: string; value: BasicMetricType }> {
    return this.basicMetrics.map(({ filter: { type } }) => ({
      value: type as BasicMetricType,
      text: this.$lang(`views.metrics.basicMetrics.${type.toLowerCase()}`),
    }));
  }

  get firstSelectMetricsTypes(): Array<{
    text: string;
    value: BasicMetricType;
  }> {
    return this.metricsTypes.filter(({ value }) => value !== this.operands[1]);
  }

  get secondSelectMetricsTypes(): Array<{
    text: string;
    value: BasicMetricType;
  }> {
    return this.metricsTypes.filter(({ value }) => value !== this.operands[0]);
  }

  mounted() {
    if (!this.operands[0]) {
      this.autocomplete.focus();
      this.autocomplete.activateMenu();
    }
  }

  getOperationSignByOperationType(operationType: OperationType): string {
    return OPERATION_BY_OPERATION_TYPE.get(operationType) as string;
  }
}
