
























































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import BaseTable from "@/reports/components/BaseTable.vue";
import {
  BaseGradientFlags,
  GroupByType,
  ReportDataType,
  ReportFilter,
  ReportHeader,
  ReportResultItem,
  ReportType,
} from "@/reports/models";
import ReportUtil from "@/reports/utils/ReportUtil";
import { FilterId, MultipleValueFilterModel } from "@/shared/models";

@Component({
  components: {
    BaseTable,
  },
})
export default class ReportTable extends Vue {
  @Prop() reportDataType!: ReportDataType;
  @Prop() hasTotal!: boolean;

  readonly groupByItems = Object.values(GroupByType).reduce(
    (result: Array<Record<string, string>>, value) => {
      if (
        (this.isFirebaseReport && value === GroupByType.DAY) ||
        (!this.isFirebaseReport && value === GroupByType.AD_TYPES)
      ) {
        return result;
      }

      return [
        ...result,
        {
          value,
          name: this.$lang(`components.groupBy.${value}`),
        },
      ];
    },
    []
  );
  gradientFlags: BaseGradientFlags | null = null;
  min: number | null = null;
  max: number | null = null;
  groupBy: GroupByType = this.isFirebaseReport
    ? GroupByType.AD_TYPES
    : GroupByType.DAY;
  currentSortBy: string | null = null;
  currentSortDesc = true;

  get dark() {
    return this.$vuetify.theme.dark;
  }

  get report(): ReportFilter {
    return this.$store.state.reportStore.currentReport;
  }

  get loading(): boolean {
    return (
      this.$store.state.reportStore.report[this.reportDataType].loading ||
      this.$store.state.reportStore.reportPredictLoading ||
      (this.hasTotal &&
        this.$store.state.reportStore.report[ReportDataType.TOTAL].loading)
    );
  }

  get items(): Array<ReportResultItem> {
    // DANGEROUS ZONE :D
    // be careful with sort, if u will do some changes here then it can broke of functionality getBorderBottomStyle function in BaseTable component
    const items = cloneDeep(
      this.getFilteredItems(
        this.$store.state.reportStore.report[this.reportDataType].items
      )
    );

    return this.currentSortBy
      ? items.sort((a: ReportResultItem, b: ReportResultItem) => {
          const key =
            this.currentSortBy === "date"
              ? "date"
              : (this.currentSortBy as keyof ReportResultItem);

          if (!key.includes("data")) {
            if (a[key] > b[key]) {
              return this.currentSortDesc ? -1 : 1;
            } else if (a[key] < b[key]) {
              return this.currentSortDesc ? 1 : -1;
            }

            return 0;
          } else if (key.includes("data")) {
            const currentA =
              typeof a.data[key.split(".")[1]] === "string"
                ? a.data[key.split(".")[1]].toLowerCase()
                : a.data[key.split(".")[1]];
            const currentB =
              typeof b.data[key.split(".")[1]] === "string"
                ? b.data[key.split(".")[1]].toLowerCase()
                : b.data[key.split(".")[1]];

            if (currentA === undefined || currentA === null) {
              return this.currentSortDesc ? 1 : -1;
            }

            if (currentB === undefined || currentB === null) {
              return this.currentSortDesc ? -1 : 1;
            }

            if (currentA > currentB) {
              return this.currentSortDesc ? -1 : 1;
            } else if (currentA < currentB) {
              return this.currentSortDesc ? 1 : -1;
            }

            return 0;
          }

          return 0;
        })
      : items;
  }

  get headers(): Array<ReportHeader> {
    const events = (
      this.report.filter.find(
        ({ id }) => id === FilterId.SIMPLE_MULTIPLE_EVENT
      ) as MultipleValueFilterModel
    )?.values.map((value) => ({
      value,
      name: this.$store.getters.eventNameByValue[value],
    }));

    return ReportUtil.initHeaders(
      this.$lang,
      this.report,
      this.reportDataType,
      events,
      this.groupBy
    );
  }

  get total(): ReportResultItem | undefined {
    if (!this.hasTotal) {
      return undefined;
    }

    const reportTotal =
      this.$store.state.reportStore.report[ReportDataType.TOTAL].items;

    return reportTotal.length ? reportTotal[0] : undefined;
  }

  get installs(): string {
    let installs = "";

    this.headers.some(({ value }) => {
      if (value === `${ReportResultItem.PREFIX}installs`) {
        installs = "installs";

        return true;
      }

      if (value === `${ReportResultItem.PREFIX}adjustInstalls`) {
        installs = "adjustInstalls";

        return true;
      }

      return false;
    });

    return installs;
  }

  get pageSize(): number | undefined {
    if (this.report.reportId === ReportType.COHORT_ANALYSIS) {
      return this.reportDataType === ReportDataType.SUB_TOTAL ? 5 : 10;
    }

    return undefined;
  }

  get hasHorizontalGradient(): boolean {
    return ReportUtil.hasHorizontalGradient(this.report.reportId);
  }

  get isGroupByFilterVisible(): boolean {
    return [
      ReportType.METRICS,
      ReportType.METRICS_SPEND,
      ReportType.SKAD,
      ReportType.PAYING_USERS_CONVERSION,
      ReportType.FIREBASE_VS_NETWORKS,
      ReportType.FIREBASE_SHOW_TO_IMPRESSION,
      ReportType.FIREBASE_FILL_RATE,
      ReportType.TRAFFIC_QUALITY,
      ReportType.UA_MAIN_METRICS_OVERVIEW,
      ReportType.METRICS_CONSTRUCTOR,
    ].includes(this.report.reportId);
  }

  get isFirebaseReport(): boolean {
    return [
      ReportType.FIREBASE_VS_NETWORKS,
      ReportType.FIREBASE_SHOW_TO_IMPRESSION,
      ReportType.FIREBASE_FILL_RATE,
    ].includes(this.report.reportId);
  }

  @Watch("report", { immediate: true })
  updateGradientFlags() {
    this.gradientFlags = ReportUtil.initGradientFlags(
      this.report,
      this.reportDataType
    );
  }

  getFilteredItems(items: Array<ReportResultItem>): Array<ReportResultItem> {
    return (!!this.min || !!this.max) && this.installs
      ? items.filter(
          (item) =>
            (!this.min || item.data[this.installs] >= this.min) &&
            (!this.max || item.data[this.installs] <= this.max)
        )
      : items;
  }

  showChartDialog(item: ReportResultItem) {
    this.$emit("showChartDialog", {
      filter: item.getChartFilter(this.headers),
      reportDataType:
        this.report.reportId === ReportType.WATERFALL
          ? this.reportDataType
          : ReportDataType.DATA,
    });
  }

  updateSortBy(value: string) {
    this.currentSortBy = value;
  }

  updateSortDesc(value: boolean) {
    this.currentSortDesc = value;
  }

  applyExcludedItems() {
    this.$emit("applyExcludedItems");
  }
}
