


























import { Component, Prop, Vue } from "vue-property-decorator";

import CellTooltip from "./CellTooltip.vue";
import ReportTable from "./ReportTable.vue";
import {
  ReportDataType,
  ReportFilter,
  ReportResultItem,
  ReportVisualizationModel,
} from "@/reports/models";

@Component({
  components: {
    ReportTable,
    CellTooltip,
  },
})
export default class ReportResultsWithoutTabs extends Vue {
  @Prop() visualization!: ReportVisualizationModel;

  readonly ReportDataType = ReportDataType;
  cellTooltipData = {
    xPos: 0,
    yPos: 0,
    visible: false,
    activator: "",
    reportDataType: ReportDataType.DATA,
    currentSortByKey: "",
    hasGroupedHeaders: false,
  };
  groupedItems: Map<any, Array<number>> = new Map();
  items: Array<ReportResultItem> = [];

  get report(): ReportFilter {
    return this.$store.state.reportStore.currentReport;
  }

  handleUpdateGroupedItems(value: Map<any, Array<number>>) {
    this.groupedItems = value;
  }

  handleUpdateItems(value: Array<ReportResultItem>) {
    this.items = value;
  }

  handleHoverCell({
    event,
    activatorId,
    value,
    reportDataType,
    currentSortByKey,
    hasGroupedHeaders,
  }: {
    event: any;
    activatorId: string;
    value: boolean;
    reportDataType?: ReportDataType;
    currentSortByKey?: string;
    hasGroupedHeaders?: boolean;
  }) {
    this.cellTooltipData.xPos = event.clientX;
    this.cellTooltipData.yPos = event.clientY;
    this.cellTooltipData.activator = `#${activatorId}`;
    this.cellTooltipData.visible = value;

    if (value) {
      this.cellTooltipData.reportDataType = reportDataType as ReportDataType;
      this.cellTooltipData.currentSortByKey = currentSortByKey as string;
      this.cellTooltipData.hasGroupedHeaders = hasGroupedHeaders as boolean;
    }
  }
}
