













import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import { FilterPreviewId } from "@/shared/models/FilterModel";
import { delay } from "lodash";

@Component
export default class CustomSwitch extends Vue {
  @Prop() value!: boolean;
  @Prop() label!: string;
  @Prop() filterPreviewId?: FilterPreviewId;

  @Ref() switch!: Vue;

  get localValue(): boolean {
    return this.value;
  }

  set localValue(value: boolean) {
    this.$emit("input", value);
  }

  get clickedFilterPreviewId(): FilterPreviewId {
    return this.$store.state.reportStore.clickedFilterPreviewId;
  }

  @Watch("clickedFilterPreviewId")
  watchPreviewClick(previewId: FilterPreviewId) {
    if (previewId !== this.filterPreviewId) {
      return;
    }

    delay(() => {
      (this.switch.$refs.input as HTMLElement).focus();
      this.$store.commit("setClickedFilterPreviewId");
    }, 100);
  }
}
