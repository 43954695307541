















import { Component, Prop, Vue } from "vue-property-decorator";

import AlertResponseModel from "@/alerts-system/models/AlertResponseModel";
import { getSystemAlertsForCharts } from "@/alerts-system/utils/AlertsSystemUtil";
import { DatesFilterModel } from "@/shared/models";

@Component
export default class ChartAlert extends Vue {
  @Prop() reportDate!: DatesFilterModel;

  get applicationId(): string {
    return this.$route.params.id;
  }

  get alerts(): Array<AlertResponseModel> {
    return getSystemAlertsForCharts(
      this.$store.state.alertsSystemStore.systemAlerts
    );
  }
}
