







































import { Component, Prop, Vue } from "vue-property-decorator";

import ReportSingleFilter from "@/reports/components/ReportSingleFilter.vue";
import DatesFilterVisualization from "@/reports/components/filter-visualizations/DatesFilterVisualization.vue";
import RevenueFilterVisualization from "@/reports/components/filter-visualizations/RevenueFilterVisualization.vue";
import AdRevenueMethodFilterVisualization from "@/reports/components/filter-visualizations/AdRevenueMethodFilterVisualization.vue";
import ProvidedBannerFilterVisualization from "@/reports/components/filter-visualizations/ProvidedBannerFilterVisualization.vue";
import EventFilterVisualization from "@/reports/components/filter-visualizations/EventFilterVisualization.vue";
import GroupByFilterVisualization from "@/reports/components/filter-visualizations/GroupByFilterVisualization.vue";
import CumulativeFilterVisualization from "@/reports/components/filter-visualizations/CumulativeFilterVisualization.vue";
import TimeSpentTypeFilterVisualization from "@/reports/components/filter-visualizations/TimeSpentTypeFilterVisualization.vue";
import ConversionValueFilterVisualization from "@/reports/components/filter-visualizations/ConversionValueFilterVisualization.vue";
import SkadReportTypeFilterVisualization from "@/reports/components/filter-visualizations/SkadReportTypeFilterVisualization.vue";
import IncludeIapsFilterVisualization from "@/reports/components/filter-visualizations/IncludeIapsFilterVisualization.vue";
import PlatformsFilterVisualization from "@/reports/components/filter-visualizations/PlatformsFilterVisualization.vue";
import ApplicationsFilterVisualization from "@/reports/components/filter-visualizations/ApplicationsFilterVisualization.vue";
import AdTypeFilterVisualization from "@/reports/components/filter-visualizations/AdTypeFilterVisualization.vue";
import UserActivityFilterVisualization from "@/reports/components/filter-visualizations/UserActivityFilterVisualization.vue";
import GoalFilterVisualization from "@/reports/components/filter-visualizations/GoalFilterVisualization.vue";
import TargetDayFilterVisualization from "@/reports/components/filter-visualizations/TargetDayFilterVisualization.vue";
import ExcludeNoInternetFilterVisualization from "@/reports/components/filter-visualizations/ExcludeNoInternetFilterVisualization.vue";
import NetRevenueFilterVisualization from "@/reports/components/filter-visualizations/NetRevenueFilterVisualization.vue";
import GeneratedEventFilterVisualization from "@/reports/components/filter-visualizations/GeneratedEventFilterVisualization.vue";
import EventStatsFilterVisualization from "@/reports/components/filter-visualizations/EventStatsFilterVisualization.vue";
import RoundSessionsFilterVisualization from "@/reports/components/filter-visualizations/RoundSessionsFilterVisualization.vue";
import PerminReportTypeFilterVisualization from "@/reports/components/filter-visualizations/PerminReportTypeFilterVisualization.vue";
import MeasuresLiteReportTypeFilterVisualization from "@/reports/components/filter-visualizations/MeasuresLiteReportTypeFilterVisualization.vue";
import TimeSpentReportTypeFilterVisualization from "@/reports/components/filter-visualizations/TimeSpentReportTypeFilterVisualization.vue";
import CohortMetricsDaysFilterVisualization from "@/reports/components/filter-visualizations/CohortMetricsDaysFilterVisualization.vue";
import NoMonitoringFilterVisualization from "@/reports/components/filter-visualizations/NoMonitoringFilterVisualization.vue";
import ShowUniqueDevicesFilterVisualization from "@/reports/components/filter-visualizations/ShowUniqueDevicesFilterVisualization.vue";
import { FilterPreviewModel } from "@/shared/models/FilterPreviewModel";
import { ReportFilter, ReportType } from "../models";
import {
  Application,
  Dictionary,
  FilterId,
  FilterPreviewId,
  REPORT_FILTER_ID_TO_ITEM_FILTER,
  TrackerOrigin,
} from "@/shared/models";
import ReportUtil from "@/reports/utils/ReportUtil";

@Component({
  components: {
    ReportSingleFilter,
    DatesFilterVisualization,
    RevenueFilterVisualization,
    AdRevenueMethodFilterVisualization,
    ProvidedBannerFilterVisualization,
    EventFilterVisualization,
    GroupByFilterVisualization,
    CumulativeFilterVisualization,
    TimeSpentTypeFilterVisualization,
    ConversionValueFilterVisualization,
    SkadReportTypeFilterVisualization,
    IncludeIapsFilterVisualization,
    PlatformsFilterVisualization,
    ApplicationsFilterVisualization,
    AdTypeFilterVisualization,
    UserActivityFilterVisualization,
    GoalFilterVisualization,
    TargetDayFilterVisualization,
    ExcludeNoInternetFilterVisualization,
    NetRevenueFilterVisualization,
    GeneratedEventFilterVisualization,
    EventStatsFilterVisualization,
    RoundSessionsFilterVisualization,
    PerminReportTypeFilterVisualization,
    MeasuresLiteReportTypeFilterVisualization,
    TimeSpentReportTypeFilterVisualization,
    CohortMetricsDaysFilterVisualization,
    NoMonitoringFilterVisualization,
    ShowUniqueDevicesFilterVisualization,
  },
})
export default class ReportFilterItem extends Vue {
  @Prop() preview!: FilterPreviewModel;
  @Prop() horizontal!: boolean;

  readonly filterIdToItemFilter = REPORT_FILTER_ID_TO_ITEM_FILTER;

  get localReport(): ReportFilter {
    return this.$store.state.reportStore.localReport;
  }

  get excludable(): boolean {
    return (
      [
        FilterPreviewId.SEGMENT,
        FilterPreviewId.TRACKER,
        FilterPreviewId.SOURCE,
        FilterPreviewId.COUNTRY,
        FilterPreviewId.EVENTS_COUNTRY,
        FilterPreviewId.DEVICE_TYPE,
        FilterPreviewId.EVENTS_OS_VERSION,
        FilterPreviewId.EVENTS_APP_VERSION,
        FilterPreviewId.APPLICATIONS,
        FilterPreviewId.WATERFALLS_ID,
        FilterPreviewId.INSTALL_APP_VERSION,
        FilterPreviewId.GENERATED_EVENT,
        FilterPreviewId.EVENT,
      ].includes(this.preview.filterPreviewId) ||
      (this.preview.filterPreviewId === FilterPreviewId.AD_NETWORK_NAME &&
        ![
          ReportType.ARPDAU,
          ReportType.ARPDAU_SIMPLE,
          ReportType.ARPU,
          ReportType.CALENDAR_CPM,
          ReportType.COHORT_CPM,
          ReportType.CALENDAR_CTR,
          ReportType.COHORT_CTR,
        ].includes((this.$route as any).name))
    );
  }

  get app(): Application {
    return this.$store.getters.applicationsById.get(this.$route.params.id);
  }

  get visible(): boolean {
    if (
      (this.preview.filterPreviewId === FilterPreviewId.PROVIDED_BANNER &&
        !this.app?.hasFirebaseBannersFeature) ||
      (!this.$store.getters.isMultiAppMode &&
        [FilterPreviewId.PLATFORMS, FilterPreviewId.APPLICATIONS].includes(
          this.preview.filterPreviewId
        ))
    ) {
      return false;
    }

    return true;
  }

  get itemsFilter(): ((item: Dictionary, currentValue: any) => boolean) | null {
    const filterId: FilterId | undefined = this.preview.filterId;
    const reportId: ReportType | undefined = this.localReport.reportId;
    if (filterId && reportId) {
      const filter = this.filterIdToItemFilter.get(filterId);
      return filter ? filter(reportId) : null;
    }

    return null;
  }

  get origins(): Array<TrackerOrigin> {
    return ReportUtil.getTrackerOrigins(this.localReport.reportId);
  }

  getComponent(filterPreviewId: FilterPreviewId): string | undefined {
    return new Map([
      [FilterPreviewId.ATTRIBUTION_DATE_VALUE, "DatesFilterVisualization"],
      [FilterPreviewId.EVENTS_DATE, "DatesFilterVisualization"],
      [FilterPreviewId.REVENUE, "RevenueFilterVisualization"],
      [FilterPreviewId.AD_REVENUE_METHOD, "AdRevenueMethodFilterVisualization"],
      [FilterPreviewId.PROVIDED_BANNER, "ProvidedBannerFilterVisualization"],
      [FilterPreviewId.EVENT_SIMPLE, "EventFilterVisualization"],
      [FilterPreviewId.GROUP_BY, "GroupByFilterVisualization"],
      [FilterPreviewId.CUMULATIVE, "CumulativeFilterVisualization"],
      [FilterPreviewId.TIME_SPENT_TYPE, "TimeSpentTypeFilterVisualization"],
      [FilterPreviewId.CONVERSION_VALUE, "ConversionValueFilterVisualization"],
      [FilterPreviewId.SKAD_REPORT_TYPE, "SkadReportTypeFilterVisualization"],
      [FilterPreviewId.INCLUDE_IAPS, "IncludeIapsFilterVisualization"],
      [FilterPreviewId.PLATFORMS, "PlatformsFilterVisualization"],
      [FilterPreviewId.APPLICATIONS, "ApplicationsFilterVisualization"],
      [FilterPreviewId.AD_TYPE, "AdTypeFilterVisualization"],
      [FilterPreviewId.USER_ACTIVITY, "UserActivityFilterVisualization"],
      [FilterPreviewId.GOAL, "GoalFilterVisualization"],
      [FilterPreviewId.TARGET_DAY_MODE, "TargetDayFilterVisualization"],
      [FilterPreviewId.NET_REVENUE, "NetRevenueFilterVisualization"],
      [
        FilterPreviewId.EXCLUDE_NO_INTERNET,
        "ExcludeNoInternetFilterVisualization",
      ],
      [FilterPreviewId.GENERATED_EVENT, "GeneratedEventFilterVisualization"],
      [FilterPreviewId.EVENT, "EventStatsFilterVisualization"],
      [
        FilterPreviewId.ROUND_SESSIONS_TO_SECONDS,
        "RoundSessionsFilterVisualization",
      ],
      [
        FilterPreviewId.REPORT_TYPE_PERMIN,
        "PerminReportTypeFilterVisualization",
      ],
      [
        FilterPreviewId.REPORT_TYPE_MEASURES_LITE,
        "MeasuresLiteReportTypeFilterVisualization",
      ],
      [
        FilterPreviewId.REPORT_TYPE_TIME_SPENT,
        "TimeSpentReportTypeFilterVisualization",
      ],
      [
        FilterPreviewId.COHORT_METRICS_DAYS,
        "CohortMetricsDaysFilterVisualization",
      ],
      [FilterPreviewId.NO_MONITORING, "NoMonitoringFilterVisualization"],
      [
        FilterPreviewId.SHOW_UNIQUE_DEVICES,
        "ShowUniqueDevicesFilterVisualization",
      ],
    ]).get(filterPreviewId);
  }
}
