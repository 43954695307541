








































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import CustomSelect from "@/shared/components/pickers/CustomSelect.vue";
import CustomAutocomplete from "@/shared/components/pickers/CustomAutocomplete.vue";
import CustomSwitch from "@/shared/components/filters/CustomSwitch.vue";
import {
  AggregationPeriod,
  GroupByFilter,
  GroupByWithEventParamFilter,
  ReportFilter,
} from "@/reports/models";
import {
  Dictionary,
  DictionaryModel,
  DictionaryType,
  EventParamFilterRangeModel,
  FilterId,
  FilterPreviewId,
} from "@/shared/models";
import { AggregationPeriodFilterPreviewModel } from "@/shared/models/FilterPreviewModel";

@Component({
  components: {
    CustomSelect,
    CustomAutocomplete,
    CustomSwitch,
  },
})
export default class GroupByFilterVisualization extends Vue {
  @Prop({ default: true }) changeablePeriod!: boolean;
  @Prop() isAttached!: boolean;
  @Prop() items!: Array<FilterId>;
  @Prop() filterPreviewId!: FilterPreviewId;
  @Prop() multiple!: boolean;
  @Prop() aggregationPeriod!: AggregationPeriodFilterPreviewModel;

  readonly FilterPreviewId = FilterPreviewId;
  localDictionary = new DictionaryModel();
  isLoading = false;

  get isSingleValueGroupBy(): boolean {
    return this.items.length === 1;
  }

  get localReport(): ReportFilter & GroupByWithEventParamFilter {
    return this.$store.state.reportStore.localReport;
  }

  get localGroupBy(): Array<FilterId> {
    return this.localReport.groupByFilter.groupBy;
  }

  set localGroupBy(value: Array<FilterId>) {
    const clonedLocalReport = cloneDeep(this.localReport);

    clonedLocalReport.groupByFilter.groupBy = value;
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }

  get localSingleGroupBy(): FilterId {
    return this.localGroupBy[0];
  }

  set localSingleGroupBy(value: FilterId) {
    const clonedLocalReport = cloneDeep(this.localReport);

    clonedLocalReport.groupByFilter.groupBy = value ? [value] : [];
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }

  get localAggregationPeriod(): AggregationPeriod | undefined {
    return this.localReport.groupByFilter.aggregationPeriod;
  }

  set localAggregationPeriod(value: AggregationPeriod | undefined) {
    const clonedLocalReport = cloneDeep(this.localReport);

    clonedLocalReport.groupByFilter.aggregationPeriod = value;
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }

  get localEventParamKeyForGroupBy(): string | undefined {
    return this.localReport.eventParamKeyForGroupBy;
  }

  set localEventParamKeyForGroupBy(value: string | undefined) {
    const clonedLocalReport = cloneDeep(this.localReport);

    clonedLocalReport.eventParamKeyForGroupBy = value;
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }

  get disabledItems(): Array<FilterId> {
    return [
      ...(this.localReport.statsEventFilterName
        ? []
        : [FilterId.EVENT_PARAMETER]),
      ...(this.$store.getters.isMultiAppMode
        ? []
        : [FilterId.PLATFORM, FilterId.APPLICATION]),
    ];
  }

  get periodItems() {
    return this.aggregationPeriod.items.map((value) => ({
      value,
      text: this.$lang(`components.groupBy.${value.toLowerCase()}`),
    }));
  }

  get itemsGroupBy() {
    return this.items.map((value) => ({
      value,
      text: this.$lang("components.groupBy." + value.toLowerCase()),
      disabled: this.disabledItems.includes(value),
    }));
  }

  get aggregationPeriodVisible(): boolean {
    return this.aggregationPeriod.items.length > 1;
  }

  get isGroupedBySingleValue(): boolean {
    return this.localGroupBy.length === 1;
  }

  set isGroupedBySingleValue(value: boolean) {
    this.localGroupBy = value ? this.items : [];
  }

  get isGroupByVisible(): boolean {
    return !!this.items.length;
  }

  get hasEventParameter(): boolean {
    return this.localGroupBy.includes(FilterId.EVENT_PARAMETER);
  }

  get eventName(): string | undefined {
    const event = this.localReport.filter.find(
      ({ id }) => id === FilterId.EVENT
    ) as EventParamFilterRangeModel;

    return event ? event.value.name : undefined;
  }

  get eventParams(): Array<Dictionary> {
    return this.eventName ? this.localDictionary.values : [];
  }

  get applicationId(): string {
    return this.$store.state.application.applicationId;
  }

  @Watch("localReport.groupByFilter", { immediate: true })
  watchValue(value: GroupByFilter) {
    const hasInvalidValues = !value.groupBy.every((filter) =>
      this.items.includes(filter)
    );
    const hasInvalidPeriod =
      !value.aggregationPeriod && !this.aggregationPeriod.optional;
    const hasInvalidChangeablePeriod =
      value.changeableAggregationPeriod !== this.aggregationPeriodVisible;

    if (!hasInvalidValues && !hasInvalidPeriod && !hasInvalidChangeablePeriod) {
      return;
    }

    const clonedLocalReport = cloneDeep(this.localReport);

    clonedLocalReport.groupByFilter = GroupByFilter.of({
      groupBy: hasInvalidValues
        ? value.groupBy.filter((filter: FilterId) =>
            this.items.includes(filter)
          )
        : value.groupBy,
      aggregationPeriod: hasInvalidPeriod
        ? AggregationPeriod.DAY
        : value.aggregationPeriod,
      changeableAggregationPeriod: this.aggregationPeriodVisible,
    }) as GroupByFilter;
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }

  @Watch("eventName", { immediate: true })
  async loadLocalDictionary(eventName?: string, oldEventName?: string) {
    if (oldEventName) {
      this.localEventParamKeyForGroupBy = undefined;
    }

    if (!eventName) {
      this.localDictionary = new DictionaryModel();

      return;
    }

    this.isLoading = true;

    try {
      const { dictionaryValues, isFullResult } = await this.$store.dispatch(
        "loadDictionary",
        {
          app: this.applicationId,
          type: DictionaryType.EVENT_PARAM_KEYS,
          parentValues: [eventName],
          isForLocal: true,
          searchLimit: -1,
        }
      );

      this.localDictionary.update(
        Dictionary.ofArray(dictionaryValues),
        isFullResult
      );
    } finally {
      this.isLoading = false;
    }
  }
}
