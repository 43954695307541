


























import { Component, Vue, Prop } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import DatesPicker from "@/shared/components/pickers/DatesPicker.vue";
import EventParamFilter from "@/shared/components/filters/EventParamFilter.vue";
import {
  GeneratedEventReportFilterExtension,
  ReportFilter,
} from "@/reports/models";
import DateUtil from "@/shared/utils/DateUtil";
import {
  EventParamFilterRangeModel,
  FilterId,
  FilterPreviewId,
} from "@/shared/models";

@Component({
  components: {
    DatesPicker,
    EventParamFilter,
  },
})
export default class GeneratedEventFilterVisualization extends Vue {
  @Prop() isAttached!: boolean;
  @Prop({ default: 90 }) daysRange!: number;
  @Prop() filterPreviewId!: FilterPreviewId;

  readonly FilterPreviewId = FilterPreviewId;

  get localReport(): ReportFilter & GeneratedEventReportFilterExtension {
    return this.$store.state.reportStore.localReport;
  }

  get localGeneratedEventValue(): EventParamFilterRangeModel {
    return (
      this.localReport.generatedEventFilter ??
      new EventParamFilterRangeModel(FilterId.EVENT)
    );
  }

  set localGeneratedEventValue(value: EventParamFilterRangeModel) {
    const clonedLocalReport = cloneDeep(this.localReport);

    clonedLocalReport.generatedEventFilter = !value.value.name
      ? undefined
      : value;

    if (
      (!value.value.params?.length || !value.value.name) &&
      clonedLocalReport.generatedEventDateFrom &&
      clonedLocalReport.generatedEventDateTo
    ) {
      clonedLocalReport.generatedEventDateFrom = undefined;
      clonedLocalReport.generatedEventDateTo = undefined;
    }

    this.$store.commit("updateLocalReport", clonedLocalReport);
  }

  get generatedEventDates(): Array<string> {
    return [
      ...(this.localReport.generatedEventDateFrom
        ? [this.localReport.generatedEventDateFrom]
        : []),
      ...(this.localReport.generatedEventDateTo
        ? [this.localReport.generatedEventDateTo]
        : []),
    ];
  }

  set generatedEventDates([from, to]: Array<string>) {
    const clonedLocalReport = cloneDeep(this.localReport);

    clonedLocalReport.generatedEventDateFrom = from;
    clonedLocalReport.generatedEventDateTo = to;
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }

  get eventDateMin(): string | undefined {
    if (
      this.localReport.generatedEventDateFrom &&
      !this.localReport.generatedEventDateTo
    ) {
      return DateUtil.subtractDays(
        this.localReport.generatedEventDateFrom,
        this.daysRange
      );
    }

    return undefined;
  }

  get eventDateMax(): string | undefined {
    if (
      this.localReport.generatedEventDateFrom &&
      !this.localReport.generatedEventDateTo
    ) {
      return DateUtil.addDays(
        this.localReport.generatedEventDateFrom,
        this.daysRange
      );
    }

    return undefined;
  }
}
