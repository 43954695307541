














import { cloneDeep } from "lodash";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import CustomSelect from "@/shared/components/pickers/CustomSelect.vue";
import {
  AdRevenueMethod,
  Dictionary,
  ProvidedBannerType,
} from "@/shared/models";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component({ components: { CustomSelect } })
export default class BannerProvidedFilter extends Vue {
  @Prop() value!: any;
  @Prop({ default: false }) isAttached!: boolean;

  readonly items = [
    new Dictionary(
      ProvidedBannerType.ADJUST,
      this.$lang("components.providedBannerPicker.adjust")
    ),
    new Dictionary(
      ProvidedBannerType.FIREBASE,
      this.$lang("components.providedBannerPicker.firebase")
    ),
  ];

  get rules(): Array<(val: any) => boolean | string> {
    if (!this.isBannerProvidedDisabled) {
      return [ValidUtil.required(this.$lang("validation.required"))];
    }

    return [];
  }

  get localValue(): ProvidedBannerType {
    return this.value.bannerProvider;
  }

  set localValue(value: ProvidedBannerType) {
    const clonedLocalValue = cloneDeep(this.value);

    clonedLocalValue.bannerProvider = value;
    this.$emit("input", clonedLocalValue);
  }

  get isBannerProvidedDisabled(): boolean {
    return this.value.revenueMethod === AdRevenueMethod.AVERAGE;
  }

  @Watch("value.revenueMethod")
  watchAdRevenueMethod(value: AdRevenueMethod) {
    if (
      value !== AdRevenueMethod.AVERAGE ||
      this.localValue === ProvidedBannerType.ADJUST
    ) {
      return;
    }

    this.localValue = ProvidedBannerType.ADJUST;
  }

  // @Watch("localReport.adType")
  // watchAdType(value: AdType) {
  //   if (
  //     value === AdType.BANNER ||
  //     this.localValue === ProvidedBannerType.ADJUST
  //   ) {
  //     return;
  //   }

  //   this.localValue = ProvidedBannerType.ADJUST;
  // }
}
