








































import { Component, Vue, Watch } from "vue-property-decorator";

import AlertResponseModel, {
  AlertFilterModel,
} from "@/alerts-system/models/AlertResponseModel";

@Component
export default class SystemAlert extends Vue {
  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  get alertFilter(): AlertFilterModel | null {
    return this.$store.state.alertsSystemStore.alertFilter;
  }

  get alerts(): Array<AlertResponseModel> {
    const visibleAlerts: Array<AlertResponseModel> =
      this.$store.state.alertsSystemStore.systemAlerts;

    // I did not use the method SORT because you can see on UI how the list is sorted
    return [
      ...visibleAlerts.filter((item) => !item.applications?.length),
      ...visibleAlerts.filter((item) => item.applications?.length),
    ];
  }

  get visible(): boolean {
    return !!this.alerts.filter(({ visible }) => visible).length;
  }

  @Watch("alertFilter", { deep: true, immediate: true })
  loadSystemAlerts() {
    this.$store.commit("clearSystemAlerts");

    if (!this.alertFilter) {
      return;
    }

    this.$store.dispatch("loadSystemAlerts");
  }

  hideSystemAlertById(id: number) {
    this.$store.dispatch("hideSystemAlertById", id);
  }

  getBorder(border: string) {
    // cause now alerts admin on vue 3 and vuetify 3 and border left/right has been changed on border start/end
    if (border === "start") {
      return "left";
    } else if (border === "end") {
      return "right";
    }

    return border;
  }
}
