






import { Component, Prop, Vue } from "vue-property-decorator";

import { FilterId } from "@/shared/models";

@Component
export default class CommonReportFilterWrapper extends Vue {
  @Prop() filterId!: FilterId;

  readonly isExcludable = [
    FilterId.EVENTS_COUNTRY,
    FilterId.DEVICE_TYPE,
    FilterId.EVENTS_APP_VERSION,
    FilterId.WATERFALLS_ID,
    FilterId.AD_NETWORK_NAME,
    FilterId.INSTALL_APP_VERSION,
    FilterId.EVENTS_OS_VERSION,
  ].includes(this.filterId);
}
