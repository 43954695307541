
















import { Component, Vue, Prop } from "vue-property-decorator";

import { ReportFilter } from "@/reports/models";
import ReportUtil from "@/reports/utils/ReportUtil";

@Component
export default class ConditionsTooltip extends Vue {
  @Prop() filter!: ReportFilter;

  get messages() {
    return ReportUtil.getReportChartConditions(
      this.filter.reportId,
      this.$lang
    );
  }
}
