



































































import { Component, Prop, Vue, Ref, Watch } from "vue-property-decorator";
import { debounce } from "lodash";

import { NewReportTemplateModel } from "@/templates/models/TemplateModel";
import { VueForm } from "@/shared/types/ExtendedVueType";
import { AccessType } from "@/shared/models";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component
export default class NewTemplate extends Vue {
  @Prop() value!: boolean;
  @Prop() template!: NewReportTemplateModel;

  @Ref("form") readonly form!: VueForm;

  readonly nameRules = [ValidUtil.required(this.$lang("validation.required"))];
  readonly descriptionRules = [
    ValidUtil.maxLength(255, this.$lang("validation.maxLength", 255)),
  ];

  localTemplate: NewReportTemplateModel = new NewReportTemplateModel(
    this.template.applicationIds,
    this.template.reportFilter
  );
  isValid = true;

  get visible(): boolean {
    return this.value;
  }

  set visible(value: boolean) {
    this.$emit("input", value);
  }

  get isTemplateNameUnique(): boolean {
    return this.$store.state.templateStore.isTemplateNameUnique;
  }

  get nameErrorMessages(): Array<string> {
    return !this.isTemplateNameUnique
      ? [this.$lang("shared.errors.notUnique")]
      : [];
  }

  get applicationId(): string {
    return this.$route.params.id;
  }

  get accessTypes(): Array<{ name: string; value: AccessType }> {
    return Object.values(AccessType).map((it) => {
      return {
        name: this.$lang(`accessType.${it.toString().toLowerCase()}`),
        value: it,
      };
    });
  }

  get isTemplateSaving(): boolean {
    return this.$store.state.templateStore.isTemplateSaving;
  }

  @Watch("localTemplate.name")
  watchName = debounce(() => {
    this.$store.dispatch(
      "checkTemplateNameUniqueness",
      this.localTemplate.name
    );
  }, 500);

  mounted() {
    this.form.resetValidation();
    this.$store.commit("setTemplateNameUniqueness", true);
  }

  async saveTemplate() {
    if (!this.form.validate()) {
      return;
    }

    await this.$store.dispatch("createReportTemplate", this.localTemplate);
    this.visible = false;
  }
}
