























































































































































































































































































































































































































































import stringify from "csv-stringify/lib/sync";
import { debounce, uniq } from "lodash";
import { Component, Emit, Prop, Ref, Vue, Watch } from "vue-property-decorator";

import {
  BaseGradientFlags,
  CohortFilter,
  ExcludeValue,
  ExcludeValueInterface,
  GradientSupplier,
  GradientType,
  GroupReportNames,
  MetricsConstructorFilter,
  ReportDataType,
  ReportFilter,
  ReportHeader,
  ReportResultItem,
  ReportType,
} from "@/reports/models";
import {
  FilterId,
  FilterModel,
  TrackerFilterModel,
  TrackerFilterPartModel,
} from "@/shared/models";
import DateUtil from "@/shared/utils/DateUtil";
import FileUtil from "@/shared/utils/FileUtil";
import TableSearch from "./TableSearch.vue";

@Component({
  components: {
    TableSearch,
  },
})
export default class BaseTable extends Vue {
  @Prop() loading!: boolean;
  @Prop() headers!: Array<ReportHeader>;
  @Prop() gradientFlags?: BaseGradientFlags;
  @Prop() gradientKeys?: Array<string>;
  @Prop() items!: Array<ReportResultItem>;
  @Prop() total?: ReportResultItem;
  @Prop({ default: 75 }) pageSize!: number;
  @Prop() sortBy?: string;
  @Prop() sortDesc?: boolean;
  @Prop() generatedReport?: ReportFilter;
  @Prop() reportDataType!: ReportDataType;
  @Prop({ default: false }) hasHorizontalGradient!: boolean;

  @Ref() table!: Vue;

  search = "";
  searchExcludedMenuItems = "";
  excludedItems: ExcludeValue = new ExcludeValue();
  excludedHeaders: Array<Record<string, any>> = [];
  itemsForExportWithSearch: Array<ReportResultItem> = [];
  isGradientHorizontal = false;
  GroupReportNames = GroupReportNames;
  isFileDownloading = false;
  currentPage = 1;
  currentPerPage = 75;
  currentSortBy = "date";
  currentSortDesc = true;
  localDataTableItems: Array<ReportResultItem> = [];
  hasSearchItems = false;
  gradientValues: Record<string, GradientSupplier> = {};
  groupedItems = new Map();
  hoveredElementId = "";

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  get itemsPerPageOptions(): Array<number> {
    const defaultValues = [10, 30, 50, 75, 200, 500, 1000];

    if (!defaultValues.includes(this.pageSize)) {
      defaultValues.push(this.pageSize);
    }

    return defaultValues.sort((valueA, valueB) => valueA - valueB);
  }

  get hasGroupedByChart(): boolean {
    return (
      !!this.generatedReport?.hasGroupedCharts &&
      this.headers.some((header: ReportHeader) => header.isGrouped)
    );
  }

  get itemsForExport(): Array<ReportResultItem> {
    return [
      ...(this.search?.trim() ? this.itemsForExportWithSearch : this.items),
      ...(this.total ? [this.total] : []),
    ];
  }

  get groupedHeaders(): Array<string> {
    return this.headers.reduce((result, { value, isGrouped }) => {
      if (isGrouped) {
        result.push(`header.${value}`);
      }

      return result;
    }, [] as Array<string>);
  }

  get hasUnfilledDays(): boolean {
    if (!this.generatedReport || !this.hasHorizontalGradient) {
      return false;
    }

    const today = DateUtil.today();

    return (
      this.generatedReport.date.to <= today &&
      DateUtil.addDays(
        this.generatedReport.date.to,
        (this.generatedReport as CohortFilter).dayLimit
      ) >= DateUtil.subtractDays(today, 2)
    );
  }

  get localReport(): ReportFilter {
    return this.$store.state.reportStore.localReport;
  }

  get currentSources(): Array<string> {
    return uniq(this.items.map((item) => item.data.source));
  }

  get currentCampaigns(): Array<string> {
    return uniq(this.items.map((item) => item.data.campaign));
  }

  get keys(): Array<string | number> {
    return this.isGradientHorizontal ? this.rowsIndexes : this.headerValues;
  }

  get headersWithTooltip(): Array<Record<string, any>> {
    return this.headers.filter((item) => item.tooltipText);
  }

  get hasCellMenu(): boolean {
    return (
      this.reportDataType !== ReportDataType.TOTAL &&
      ["date", ...this.groupedHeaders].some((item) =>
        item.includes(this.currentSortBy)
      ) &&
      [
        ReportType.METRICS,
        ReportType.METRICS_SPEND,
        ReportType.EVENTS_COST,
        ReportType.UA_MAIN_METRICS_OVERVIEW,
        ReportType.COHORT_ANALYSIS,
        ReportType.CASH_COUNTRY,
        ReportType.CASH_GAMING,
        ReportType.ARPU,
        ReportType.RETURN_RATE,
        ReportType.MEASURES_LITE,
        ReportType.TIME_SPENT,
        ReportType.TRAFFIC_QUALITY,
        ReportType.AD_ROAS_COUNTRY,
        ReportType.AD_ROAS_NETWORK,
        ReportType.PAYING_USERS_CONVERSION,
        ReportType.COHORT_PER_MIN,
        ReportType.COHORT_CPM,
        ReportType.COHORT_CTR,
        ...(this.generatedReport &&
        this.generatedReport.reportId === ReportType.METRICS_CONSTRUCTOR &&
        (this.generatedReport as MetricsConstructorFilter).hasInstallsMetric
          ? [ReportType.METRICS_CONSTRUCTOR]
          : []),
      ].includes(this.localReport.reportId)
    );
  }

  getHighlightExcludedRowClass(rowIndex: number): string {
    return this.isExcludedRow(rowIndex) ? "highlight-excluded-row" : "";
  }

  @Watch("isGradientHorizontal")
  @Watch("items")
  setGradientValues() {
    if (!this.gradientFlags) {
      return;
    }

    const values = this.isGradientHorizontal
      ? this.getRowValues()
      : this.getColumnValues();

    this.gradientValues = this.keys.reduce(
      (result, key) =>
        Object.assign(result, {
          [key]: new GradientSupplier(
            values[key],
            this.isGradientHorizontal ? false : this.headerReversibility[key]
          ),
        }),
      {}
    );
  }

  @Watch("localDataTableItems")
  watchItems(newItems: any) {
    this.$emit("update:items", newItems);
  }

  @Watch("headers")
  @Watch("localDataTableItems")
  watchHeadersAndItems() {
    if (this.table && this.localDataTableItems.length) {
      this.renderHeaders();

      if (
        this.groupedHeaders.length &&
        this.reportDataType === ReportDataType.DATA
      ) {
        this.groupedItems.clear();
        this.setGroupedItems();
      }
    }
  }

  mounted() {
    this.renderHeaders();
    this.excludedHeaders = this.headers
      .filter((item) => item.hasExcludeFeature)
      .map((item) => ({
        ...item,
        showMenu: false,
        selectedValues: [],
      }));

    this.setEventListenersForTBody();
  }

  showCellTooltipDebounced = debounce((event: any, cellId: string) => {
    this.updateHoverdElementId(event, cellId);
  }, 300);

  updateHoverdElementId(event: any, cellId: string) {
    this.hoveredElementId = cellId;
    this.emitHoverCell(event, true);
  }

  setEventListenersForTBody() {
    if (this.reportDataType === ReportDataType.TOTAL) {
      return;
    }

    const tbody: any = document.querySelector(
      `#reportTable_${this.reportDataType} tbody`
    );

    if (tbody) {
      tbody.addEventListener(
        "mousemove",
        (event: any) => {
          const cell = event.target.closest("td");
          const header = this.headers[cell.cellIndex];

          if (this.hoveredElementId === cell.id || !this.hasCellMenu) {
            return;
          }

          if (!header.hasGradient || cell.textContent.trim() === "") {
            this.showCellTooltipDebounced.cancel();

            if (this.hoveredElementId) {
              this.emitHoverCell(event, false);
              this.hoveredElementId = "";
            }

            return;
          }

          if (this.hoveredElementId) {
            this.emitHoverCell(event, false);
            this.hoveredElementId = "";
          }

          this.showCellTooltipDebounced(event, cell.id);
        },
        { passive: true }
      );
      tbody.addEventListener(
        "mouseleave",
        (event: any) => {
          this.showCellTooltipDebounced.cancel();

          if (this.hoveredElementId) {
            this.emitHoverCell(event, false);
            this.hoveredElementId = "";
          }
        },
        { passive: true }
      );
    }
  }

  async emitHoverCell(event: any, value: boolean) {
    await this.$emit("hoverCell", {
      event,
      activatorId: this.hoveredElementId,
      value,
      reportDataType: this.reportDataType,
      currentSortByKey: this.currentSortBy,
      hasGroupedHeaders: !!this.groupedHeaders.length,
    });
  }

  getHeaderValueWithoutPrefix(value: string): string {
    return value.replace(ReportResultItem.PREFIX, "");
  }

  getColumnValues(): Record<string, Array<number>> {
    return this.items.reduce(
      (acc: Record<string, Array<number>>, item: ReportResultItem) => {
        this.headerValues.forEach((key: string) => {
          if (!acc[key]) {
            acc[key] = [item.getByKey(key)];

            return;
          }

          acc[key].splice(0, 0, item.getByKey(key));
        });

        return acc;
      },
      {}
    );
  }

  getRowValues(): Record<string, Array<number>> {
    return this.items.reduce(
      (
        acc: Record<string, Array<number>>,
        item: ReportResultItem,
        index: number
      ) =>
        Object.assign(acc, {
          [index]: this.headerValues.map((key: string) => item.getByKey(key)),
        }),
      {}
    );
  }

  async renderHeaders() {
    await this.$nextTick();
    const headerElements = [
      ...this.table.$el.querySelectorAll("thead th"),
    ] as Array<HTMLElement>;

    // TODO: temp decision while result items store is empty
    if (!headerElements.length) {
      return;
    }

    let leftShift = 0;

    this.headers.forEach((header, index) => {
      if (header.sticky) {
        headerElements[index].classList.add("sticky-header");

        headerElements[index].style.left = `${leftShift}px`;

        if (header.width) {
          leftShift += +header.width;
        }
      } else {
        headerElements[index].classList.remove("sticky-header");

        headerElements[index].style.left = "";
      }

      // TODO: temp commit mb it needs for the future
      // headerElements[index].classList.remove("group-by__background");
      // headerElements[index].classList.remove("group-by__border-left");
      // headerElements[index].classList.remove("group-by__border-right");

      // if (header?.groupBy?.hasBackground) {
      //   headerElements[index].classList.add("group-by__background");
      // }

      // if (header?.groupBy?.border?.left) {
      //   headerElements[index].classList.add("group-by__border-left");
      // }

      // if (header?.groupBy?.border?.right) {
      //   headerElements[index].classList.add("group-by__border-right");
      // }
    });
  }

  private get headerValues(): Array<string> {
    return this.headers.reduce(
      (keys: Array<string>, { hasGradient, value }: ReportHeader) => {
        if (hasGradient) {
          keys.push(value);
        }

        return keys;
      },
      []
    );
  }

  private get headerReversibility(): Record<string, boolean> {
    return this.headers.reduce(
      (result, { reverseGradient, value, hasGradient }: ReportHeader) =>
        hasGradient
          ? Object.assign(result, { [value]: !!reverseGradient })
          : result,
      {}
    );
  }

  private get rowsIndexes(): Array<number> {
    return this.items.map((_, index: number) => index);
  }

  private isWeekend(date: string) {
    return DateUtil.isWeekend(date);
  }

  private isGradient(key: string | number): boolean {
    return !!this.gradientFlags?.getByKey(key);
  }

  transformToString(val: any, fractionDigits?: number) {
    if (typeof val !== "number") {
      return val ?? "";
    }

    const numValue = val || 0;

    return (numValue as number).toLocaleString(this.$vuetify.lang.current, {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    });
  }

  toggleGradient(event: MouseEvent, key: string | number, val: any) {
    if (!(event.ctrlKey || event.metaKey || this.gradientFlags?.unlock)) {
      return;
    }

    this.gradientValues[key].toggleValue(val);
  }

  get getCsvHeaders() {
    return this.headers.map((it) => ({ key: it.value, header: it.text }));
  }

  get fractionDigits() {
    const result: Record<string, number | undefined> = {};
    this.headers
      .filter((it) => !!it.fractionDigits)
      .forEach((it) => (result[it.value] = it.fractionDigits));
    return result;
  }

  get reportKey(): string | null {
    return this.$store.state.reportStore.reportKey;
  }

  get exportable(): boolean {
    return !!this.items.length;
  }

  get gradientType(): GradientType {
    if (!this.gradientFlags || this.gradientFlags.flags.gradient === false) {
      return GradientType.NONE;
    }

    return this.isGradientHorizontal
      ? GradientType.HORIZONTAL
      : GradientType.VERTICAL;
  }

  updateItemsForExport(items: Array<ReportResultItem>) {
    this.itemsForExportWithSearch = items;
  }

  getCsvContent(delimiter?: string) {
    return stringify(this.itemsForExport, {
      header: true,
      columns: this.getCsvHeaders,
      delimiter,
      cast: {
        number: (value, context) =>
          this.transformToString(
            value,
            context.column ? this.fractionDigits[context.column] : undefined
          ),
      },
    });
  }

  exportToClipboard(delimiter?: string) {
    navigator.clipboard.writeText(this.getCsvContent(delimiter));
  }

  exportToCsvFile() {
    FileUtil.download(
      this.getCsvContent(),
      "Report.csv",
      FileUtil.CONTENT_TYPE_CSV
    );
  }

  exportToTsvFile() {
    FileUtil.download(
      this.getCsvContent("\t"),
      "Report.tsv",
      FileUtil.CONTENT_TYPE_TSV
    );
  }

  downloadFile(format: string) {
    const { currentReport } = this.$store.state.reportStore;

    this.isFileDownloading = true;
    this.$store
      .dispatch("downloadFile", {
        apps: currentReport.getApp,
        reportType: currentReport.reportId,
        key: this.reportKey,
        visualization: this.reportDataType,
        format,
        gradientType: this.gradientType,
      })
      .finally(() => {
        this.isFileDownloading = false;
      });
  }

  searchFilter(value: any, searchVal: string | null) {
    return (
      value != null &&
      searchVal != null &&
      typeof value !== "boolean" &&
      value
        .toString()
        .toLocaleLowerCase()
        .indexOf(searchVal.toLocaleLowerCase()) !== -1
    );
  }

  getRowNumber(item: ReportResultItem) {
    return this.items.indexOf(item);
  }

  getTableCellText(item: ReportResultItem, header: ReportHeader) {
    const headerValue = header.value.startsWith(ReportResultItem.PREFIX)
      ? header.value.substring(ReportResultItem.PREFIX.length)
      : header.value;
    const itemValue = item.getByKey(headerValue);
    let value = "";

    if (itemValue === null || itemValue === undefined) {
      return value;
    }

    value = this.transformToString(
      item.getByKey(header.value),
      header.fractionDigits
    );

    return value !== ""
      ? `${header.prefix ?? ""}${value}${header.postfix ?? ""}`
      : value;
  }

  getCellGradientStyle(header: ReportHeader, item: ReportResultItem) {
    let style = "";
    const key = this.isGradientHorizontal
      ? this.getRowNumber(item)
      : header.value;

    if (this.isGradient(key)) {
      const colorFactor = this.gradientValues[key].getGradientFactor(
        item.getByKey(header.value)
      );

      style +=
        colorFactor !== null
          ? `background: hsl(${
              colorFactor * 120
            }, 100%, var(--gradient-lightness));`
          : "";
    }

    if (header?.groupBy) {
      style += this.getGroupByStyles(header);
    }

    return style;
  }

  getTableCellClass(item: any, header: ReportHeader) {
    const classArray = [];

    if (header.value === "date") {
      classArray.push("text-center");

      if (this.isWeekend(item.date)) {
        classArray.push("green--text", "lighten-3");
      }
    } else {
      classArray.push(`text-${header.align}`);
    }

    if (header.sticky) {
      classArray.push("sticky-column");
    }

    return classArray.join(" ");
  }

  getGroupByStyles(header: ReportHeader): string {
    let style = "";

    if (
      header?.groupBy?.hasBackground &&
      (!header.hasGradient || this.reportDataType === ReportDataType.TOTAL)
    ) {
      style += `background: ${this.dark ? "#0F0F0F" : "#F7F7F7"};`;
    }

    if (header?.groupBy?.border?.left) {
      style += `border-left: 1px solid ${
        this.dark ? "rgba(255, 255, 255, 0.6)" : "rgba(0, 0, 0, 0.6)"
      };`;
    }

    if (header?.groupBy?.border?.right) {
      style += `border-right: 1px solid ${
        this.dark ? "rgba(255, 255, 255, 0.6)" : "rgba(0, 0, 0, 0.6)"
      };`;
    }

    this.renderHeaders();

    return style;
  }

  getTableCellStyle(index: number) {
    let style = "";

    if (this.hasGroupedByChart) {
      style += "cursor: pointer;";
    }

    if (this.headers[index].sticky) {
      const leftShift = this.headers
        .slice(0, index)
        .reduce((result, header) => {
          if (header.sticky && header.width) {
            return (result += +header.width);
          }

          return result;
        }, 0);

      style += `left: ${leftShift}px;`;
    }

    if (this.headers[index]?.groupBy) {
      style += this.getGroupByStyles(this.headers[index]);
    }

    return style;
  }

  getCellFillStyle(header: ReportHeader, item: ReportResultItem): string {
    if (!header.hasFill) {
      return "";
    }

    const value = Math.abs(
      item.data[header.value.replace(ReportResultItem.PREFIX, "")]
    );
    let color = "";

    if (value < 4) {
      return "";
    } else if (value < 6) {
      color = `${this.dark ? "#f9a825" : "#fff59d"}`;
    } else {
      color = `${this.dark ? "#ff5252" : "#ff8d8d"}`;
    }

    return `background: ${color}`;
  }

  getCurrentIndexOfItem(rowIndex: number): number {
    const numberOfItemsBehind = this.currentPerPage * (this.currentPage - 1);
    return numberOfItemsBehind + rowIndex;
  }

  hasBorderBottom(item: ReportResultItem, rowIndex: number): boolean {
    if (
      !this.generatedReport?.groupByFilter.isNotEmptyGroupBy ||
      this.reportDataType !== ReportDataType.DATA ||
      !this.headers.some(({ isGrouped }) => isGrouped)
    ) {
      return false;
    }

    const nextItem = this.itemsForExportWithSearch[rowIndex + 1];

    if (!nextItem) {
      return false;
    }

    const itemValue =
      this.currentSortBy === "date"
        ? item["date"]
        : item.getByKey(this.currentSortBy);
    const nextItemValue =
      this.currentSortBy === "date"
        ? nextItem["date"]
        : nextItem.getByKey(this.currentSortBy);

    return itemValue !== nextItemValue;
  }

  getItemValueBySortKey(item: ReportResultItem): any {
    return this.currentSortBy === "date"
      ? item?.formattedDate
      : String(item.data[this.currentSortBy]);
  }

  setGroupedItems() {
    const numberOfItemsBehind = this.currentPerPage * (this.currentPage - 1);

    this.localDataTableItems.forEach(
      (item: ReportResultItem, index: number) => {
        const lastKeyInMap = Array.from(this.groupedItems.keys()).pop();

        if (
          index + 1 > this.currentPerPage + numberOfItemsBehind &&
          lastKeyInMap !== this.getItemValueBySortKey(item)
        ) {
          return;
        }

        if (
          this.groupedItems.has(this.getItemValueBySortKey(item)) &&
          !this.groupedItems
            .get(this.getItemValueBySortKey(item))
            ?.includes(index)
        ) {
          this.groupedItems.set(this.getItemValueBySortKey(item), [
            ...this.groupedItems.get(this.getItemValueBySortKey(item)),
            index,
          ]);
        } else {
          this.groupedItems.set(this.getItemValueBySortKey(item), [index]);
        }
      }
    );

    this.$emit("update:groupedItems", this.groupedItems);
  }

  updatePage(updatedPage: number) {
    const oldNumberOfPage = this.currentPage;
    this.currentPage = updatedPage;

    if (
      oldNumberOfPage < updatedPage &&
      this.groupedHeaders.length &&
      this.reportDataType === ReportDataType.DATA
    ) {
      this.setGroupedItems();
    }
  }

  updatePerPage(updatedPerPage: number) {
    this.currentPerPage = updatedPerPage;
    this.setGroupedItems();
  }

  updateSortBy(items: Array<string>) {
    this.currentSortBy =
      items[0] === "date" || !items[0]
        ? "date"
        : items[0].replace(ReportResultItem.PREFIX, "");
    this.$emit("updateSortBy", items[0] || null);
  }

  updateSortDesc(items: Array<boolean>) {
    this.currentSortDesc = items[0] ?? true;
    this.$emit("updateSortDesc", items[0] ?? true);
  }

  getFlagName(flagName: string) {
    return this.$lang(
      `components.baseTable.${BaseGradientFlags.getFlagName(flagName)}`
    );
  }

  changeLocalDataTableItems(items: Array<ReportResultItem>) {
    this.localDataTableItems = items;
  }

  updateSearch(value: boolean) {
    this.hasSearchItems = value;
  }

  @Emit("showChartDialog")
  showChartDialog(item: ReportResultItem) {
    return item;
  }

  isExcludedRow(rowIndex: number): boolean {
    return this.excludedItems.rowContained(rowIndex);
  }

  isExcludedItem(
    headerValue: string,
    value: string,
    rowIndex: number
  ): boolean {
    return this.excludedItems.itemContained({ headerValue, value, rowIndex });
  }

  isExcludedParentItem(item: ReportResultItem): boolean {
    // V1 it is for Cohort Analysis only so data.source
    return this.excludedItems.parentItemContained(
      "data.source",
      item.getByKey("data.source")
    );
  }

  isDisabledExcludedMenu(headerValue: string, itemValue: string) {
    const foundItem = this.items.find(
      (item) =>
        item.data[headerValue.replace(ReportResultItem.PREFIX, "")] ===
        itemValue
    );

    return (
      headerValue !== "data.source" &&
      this.isExcludedParentItem(foundItem as ReportResultItem)
    );
  }

  handleAddingToExclude(
    item: ReportResultItem,
    headerValue: string,
    rowIndex?: number
  ) {
    if (rowIndex === undefined) {
      this.handleAddingToExcludeFromMenu(item, headerValue);
      return;
    }

    if (headerValue === "data.source") {
      const foundIndexes = this.items.reduce(
        (res: Array<number>, it: ReportResultItem, index) => {
          if (
            it.data[headerValue.replace(ReportResultItem.PREFIX, "")] ===
            item.getByKey(headerValue)
          ) {
            res.push(index);
          }

          return res;
        },
        []
      );

      if (
        this.isExcludedItem(headerValue, item.getByKey(headerValue), rowIndex)
      ) {
        foundIndexes.forEach((foundIndex) => {
          this.headers
            .filter(({ hasExcludeFeature }: ReportHeader) => hasExcludeFeature)
            .forEach((item: ReportHeader) => {
              this.excludedItems.remove({
                headerValue: item.value,
                value: this.items[foundIndex].data[item.value.split(".")[1]],
                rowIndex: foundIndex,
              });
            });
        });
      } else {
        foundIndexes.forEach((foundIndex) => {
          this.headers
            .filter(({ hasExcludeFeature }: ReportHeader) => hasExcludeFeature)
            .forEach((item: ReportHeader) => {
              if (
                this.items[foundIndex].data[item.value.split(".")[1]] !== "-" &&
                !this.isExcludedItem(
                  item.value,
                  this.items[foundIndex].data[item.value.split(".")[1]],
                  foundIndex
                )
              ) {
                this.excludedItems.add({
                  headerValue: item.value,
                  value: this.items[foundIndex].data[item.value.split(".")[1]],
                  parent:
                    item.value !== "data.source"
                      ? {
                          headerValue: "data.source",
                          value: this.items[foundIndex].data.source,
                        }
                      : undefined,
                  rowIndex: foundIndex,
                });
              }
            });
        });
      }
    } else if (
      this.isExcludedItem(headerValue, item.getByKey(headerValue), rowIndex)
    ) {
      this.excludedItems.remove({
        headerValue,
        value: item.getByKey(headerValue),
        rowIndex,
      });
    } else {
      this.excludedItems.add({
        headerValue,
        value: item.getByKey(headerValue),
        parent: {
          headerValue: "data.source",
          value: item.data.source,
        },
        rowIndex,
      });
    }

    this.setSelectedValuesForExcludedMenu();
  }

  handleAddingToExcludeFromMenu(item: ReportResultItem, headerValue: string) {
    const foundIndexes = this.items.reduce(
      (res: Array<number>, it: ReportResultItem, index) => {
        if (
          it.data[headerValue.replace(ReportResultItem.PREFIX, "")] ===
          item.getByKey(headerValue)
        ) {
          res.push(index);
        }

        return res;
      },
      []
    );

    foundIndexes.forEach((foundIndex) => {
      if (
        this.isExcludedItem(headerValue, item.getByKey(headerValue), foundIndex)
      ) {
        this.excludedItems.remove({
          headerValue,
          value: this.items[foundIndex].data[headerValue.split(".")[1]],
          rowIndex: foundIndex,
        });

        if (headerValue === "data.source") {
          this.excludedItems.remove({
            headerValue: "data.campaign",
            value: this.items[foundIndex].data.campaign,
            rowIndex: foundIndex,
          });
        }
      } else {
        this.excludedItems.add({
          headerValue,
          value: this.items[foundIndex].data[headerValue.split(".")[1]],
          parent:
            headerValue !== "data.source"
              ? {
                  headerValue: "data.source",
                  value: this.items[foundIndex].data.source,
                }
              : undefined,
          rowIndex: foundIndex,
        });

        if (headerValue === "data.source") {
          this.excludedItems.add({
            headerValue: "data.campaign",
            value: this.items[foundIndex].data.campaign,
            parent: {
              headerValue: "data.source",
              value: this.items[foundIndex].data.source,
            },
            rowIndex: foundIndex,
          });
        }
      }
    });
  }

  setSelectedValuesForExcludedMenu() {
    const foundSource = this.excludedHeaders.find(
      ({ value }) => value === "data.source"
    );

    const foundCampaign = this.excludedHeaders.find(
      ({ value }) => value === "data.campaign"
    );

    if (foundSource) {
      foundSource.selectedValues = this.currentSources.reduce(
        (res: Array<number>, item: string, index: number) => {
          if (this.excludedItems.values.some(({ value }) => item === value)) {
            res.push(index);
          }

          return res;
        },
        []
      );
    }

    if (foundCampaign) {
      foundCampaign.selectedValues = this.currentCampaigns.reduce(
        (res: Array<number>, item: string, index: number) => {
          if (this.excludedItems.values.some(({ value }) => item === value)) {
            res.push(index);
          }

          return res;
        },
        []
      );
    }
  }

  handleClickByExcludedMenuItem(itemValue: any, headerValue: string) {
    const foundItem = this.items.find(
      (item) =>
        item.data[headerValue.replace(ReportResultItem.PREFIX, "")] ===
        itemValue
    );

    if (foundItem) {
      this.handleAddingToExclude(foundItem, headerValue);
    }
  }

  get itemsForExcludedMenuByHeaderKey() {
    return (headerValue: string) => {
      switch (headerValue) {
        case "data.source":
          return this.currentSources.filter((item) =>
            item
              .toLowerCase()
              .includes(this.searchExcludedMenuItems.toLowerCase())
          );
        case "data.campaign":
          return this.currentCampaigns.filter(
            (item) =>
              item
                .toLowerCase()
                .includes(this.searchExcludedMenuItems.toLowerCase()) &&
              item !== "-"
          );
        default:
          return [];
      }
    };
  }

  async applyExcludedItems() {
    let foundTrackerFilterIndex = this.localReport.filter.findIndex(
      ({ id }: FilterModel) => id === FilterId.TRACKER
    );

    const excludedSources = uniq(
      this.excludedItems.values
        .filter((item) => item.headerValue === "data.source")
        .map(({ value }: ExcludeValueInterface) => value)
    );
    const includedSources = this.currentSources.filter(
      (value: string) => !excludedSources.includes(value)
    );
    const excludedCampaigns = uniq(
      this.excludedItems.values
        .filter(
          (item) =>
            !excludedSources.includes(item.parent?.value as string) &&
            item.headerValue === "data.campaign"
        )
        .map(({ value }: ExcludeValueInterface) => value)
    );

    if (foundTrackerFilterIndex >= 0) {
      this.localReport.filter[foundTrackerFilterIndex] = new TrackerFilterModel(
        FilterId.TRACKER,
        false,
        false,
        true,
        (this.localReport.filter[foundTrackerFilterIndex] as TrackerFilterModel)
          .source &&
        (this.localReport.filter[foundTrackerFilterIndex] as TrackerFilterModel)
          .source?.values.length > 0
          ? new TrackerFilterPartModel(
              true,
              (
                this.localReport.filter[
                  foundTrackerFilterIndex
                ] as TrackerFilterModel
              ).source?.values.filter(
                (item: string) => !excludedSources.includes(item)
              )
            )
          : undefined,
        undefined,
        excludedCampaigns.length > 0 ||
        (this.localReport.filter[foundTrackerFilterIndex] as TrackerFilterModel)
          .campaign
          ? new TrackerFilterPartModel(false, [
              ...((
                this.localReport.filter[
                  foundTrackerFilterIndex
                ] as TrackerFilterModel
              ).campaign?.values.length > 0
                ? ((
                    this.localReport.filter[
                      foundTrackerFilterIndex
                    ] as TrackerFilterModel
                  ).campaign?.values as Array<string>)
                : []),
              ...excludedCampaigns,
            ])
          : undefined,
        undefined,
        undefined,
        undefined
      );
    } else {
      this.localReport.filter = [
        ...this.localReport.filter,
        new TrackerFilterModel(
          FilterId.TRACKER,
          false,
          false,
          true,
          includedSources.length > 0
            ? new TrackerFilterPartModel(true, includedSources)
            : undefined,
          undefined,
          excludedCampaigns.length > 0
            ? new TrackerFilterPartModel(false, excludedCampaigns)
            : undefined,
          undefined,
          undefined,
          undefined
        ),
      ];
    }

    this.$store.commit("updateCurrentReport", this.localReport);

    await this.$emit("applyExcludedItems");

    this.excludedItems = new ExcludeValue();
    this.resetExcludedHeaders();
  }

  resetAllExcludedItems() {
    this.excludedItems.removeAll();
    this.excludedHeaders = this.excludedHeaders.map(
      (item: Record<string, any>) => ({
        ...item,
        selectedValues: [],
      })
    );
  }

  resetExcludedHeaders() {
    this.excludedHeaders = this.excludedHeaders.map(
      (item: Record<string, any>) => ({
        ...item,
        showMenu: false,
        selectedValues: [],
      })
    );
  }

  customSort(
    items: Array<ReportResultItem>,
    index: Array<keyof ReportResultItem>,
    [isDesc]: Array<boolean>
  ) {
    const key: keyof ReportResultItem = index[0] || "date";

    return items.sort((itemA: ReportResultItem, itemB: ReportResultItem) => {
      let valueA = key === "date" ? itemA[key] : itemA.getByKey(key);
      let valueB = key === "date" ? itemB[key] : itemB.getByKey(key);

      if (typeof valueA === "string") {
        valueA = valueA.toLowerCase();
      }

      if (typeof valueB === "string") {
        valueB = valueB.toLowerCase();
      }

      if (valueA === valueB) {
        return 0;
      }

      if (valueA === undefined || valueA === null) {
        return isDesc ? 1 : -1;
      }

      if (valueB === undefined || valueB === null) {
        return isDesc ? -1 : 1;
      }

      if (valueA > valueB) {
        return isDesc ? -1 : 1;
      }

      return isDesc ? 1 : -1;
    });
  }
}
