




















import { Component, Vue, Prop } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import { RoundSessionsReportFilterExtension } from "@/reports/models";
import { FilterPreviewId } from "@/shared/models";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component
export default class RoundSessionsFilterVisualization extends Vue {
  @Prop() filterPreviewId!: FilterPreviewId;

  readonly rules = [
    ValidUtil.required(this.$lang("validation.required")),
    ValidUtil.min(1, this.$lang("validation.min", 1)),
    ValidUtil.integer(this.$lang("validation.integer")),
  ];

  get localReport(): RoundSessionsReportFilterExtension {
    return this.$store.state.reportStore.localReport;
  }

  get localValue(): boolean {
    return this.localReport.roundSessions;
  }

  set localValue(value: boolean) {
    const clonedLocalReport = cloneDeep(this.localReport);

    clonedLocalReport.roundSessions = value;
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }

  get localSecondsValue(): number {
    return this.localReport.roundSessionsToSeconds;
  }

  set localSecondsValue(value: number) {
    const clonedLocalReport = cloneDeep(this.localReport);

    clonedLocalReport.roundSessionsToSeconds = value;
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }
}
