import { render, staticRenderFns } from "./ReportChart.vue?vue&type=template&id=5c56a4b4&"
import script from "./ReportChart.vue?vue&type=script&lang=ts&"
export * from "./ReportChart.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
installComponents(component, {VProgressCircular,VResponsive})
