
























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import SidebarPanel from "@/reports/components/metrics-filter/SidebarPanel.vue";
import BasicSettings from "./BasicSettings.vue";
import BasicMetrics from "./BasicMetrics.vue";
import CalculationSettings from "./CalculationSettings.vue";
import ResultSettings from "./ResultSettings.vue";
import {
  MetricStepId,
  UserMetricCalculationModel,
  UserMetricModel,
  UserMetricResultModel,
} from "@/reports/models";

@Component({
  components: {
    SidebarPanel,
    BasicSettings,
    BasicMetrics,
    CalculationSettings,
    ResultSettings,
  },
})
export default class UserMetricsForm extends Vue {
  @Prop() value!: boolean;
  @Prop({ default: false }) isCopy!: boolean;
  @Prop() userMetricBeingEdited!: UserMetricModel | null;
  @Prop() names!: Array<string>;
  @Prop() lastAddedCohortDays!: Array<number>;

  currentStep = 0;
  stepper: Array<{
    value: MetricStepId;
    isDone: boolean;
    isShow: boolean;
    isEdit: boolean;
  }> = [
    {
      value: MetricStepId.BASIC_SETTINGS,
      isDone: false,
      isShow: true,
      isEdit: false,
    },
    {
      value: MetricStepId.BASIC_METRICS,
      isDone: false,
      isShow: false,
      isEdit: false,
    },
    {
      value: MetricStepId.RESULT_SETTINGS,
      isDone: false,
      isShow: false,
      isEdit: false,
    },
  ];
  localValue = new UserMetricModel();

  get stepsShouldBeUpdated():
    | Map<MetricStepId, Array<MetricStepId>>
    | undefined {
    return new Map([
      [
        MetricStepId.BASIC_METRICS,
        [
          ...(this.localValue.definition.basicMetrics.some(
            ({ cohort }) => cohort
          )
            ? [MetricStepId.CALCULATION_SETTINGS]
            : []),
          MetricStepId.RESULT_SETTINGS,
        ],
      ],
    ]);
  }

  get visible(): boolean {
    return this.value;
  }

  set visible(value: boolean) {
    if (!value) {
      this.resetLocalData();
    }

    this.$emit("input", value);
  }

  get sidebarTitle(): string {
    if (!this.isCopy && this.userMetricBeingEdited) {
      return this.$lang("views.metrics.editCustomMetricTitle");
    }

    if (this.isCopy && this.userMetricBeingEdited) {
      return this.$lang("views.metrics.copyCustomMetricTitle");
    }

    return this.$lang("views.metrics.newCustomMetricTitle");
  }

  get disabledSave(): boolean {
    return this.stepper.some(({ isDone }) => !isDone);
  }

  get stepperFiltered() {
    return this.stepper.filter(({ isShow }) => isShow);
  }

  @Watch("userMetricBeingEdited")
  watchUserMetricBeingEdited(value: UserMetricModel | null) {
    if (value) {
      this.localValue = cloneDeep(value);
      const hasCohortBasicMetric = this.localValue.definition.basicMetrics.some(
        ({ cohort }) => cohort
      );
      const hasCalculationSettingsStep = this.stepper.some(
        ({ value }) => value === MetricStepId.CALCULATION_SETTINGS
      );

      if (hasCohortBasicMetric && !hasCalculationSettingsStep) {
        this.stepper.splice(2, 0, {
          value: MetricStepId.CALCULATION_SETTINGS,
          isDone: true,
          isShow: true,
          isEdit: false,
        });
      }

      this.stepper = this.stepper.map((item) => ({
        ...item,
        isDone: true,
        isShow: true,
        isEdit: false,
      }));

      if (this.isCopy) {
        this.changeIsDone({
          value: false,
          stepValue: MetricStepId.BASIC_SETTINGS,
        });
        this.changeIsEdit({
          value: true,
          stepValue: MetricStepId.BASIC_SETTINGS,
        });
        this.changeIsShow({
          value: true,
          stepValue: MetricStepId.BASIC_SETTINGS,
        });
      }
    }
  }

  handleCalculationSettingsStep() {
    const hasCohortBasicMetric = this.localValue.definition.basicMetrics.some(
      ({ cohort }) => cohort
    );
    const hasCalculationSettingsStep = this.stepper.some(
      ({ value }) => value === MetricStepId.CALCULATION_SETTINGS
    );

    if (hasCohortBasicMetric && !hasCalculationSettingsStep) {
      this.stepper.splice(2, 0, {
        value: MetricStepId.CALCULATION_SETTINGS,
        isDone: false,
        isShow: false,
        isEdit: false,
      });
    } else if (!hasCohortBasicMetric && hasCalculationSettingsStep) {
      this.stepper = this.stepper.filter(
        ({ value }) => value !== MetricStepId.CALCULATION_SETTINGS
      );
      this.localValue.definition.calculationSettings =
        new UserMetricCalculationModel();
    }
  }

  getComponentByStepId(stepId: MetricStepId): string {
    return {
      BASIC_SETTINGS: "BasicSettings",
      BASIC_METRICS: "BasicMetrics",
      CALCULATION_SETTINGS: "CalculationSettings",
      RESULT_SETTINGS: "ResultSettings",
    }[stepId];
  }

  changeIsDone({ value, stepValue }: { value: boolean; stepValue: string }) {
    const found = this.stepper.find(({ value }) => value === stepValue);

    if (found) {
      found.isDone = value;
    }
  }

  changeIsShow({ value, stepValue }: { value: boolean; stepValue: string }) {
    const found = this.stepper.find(({ value }) => value === stepValue);

    if (found) {
      found.isShow = value;
    }
  }

  changeIsEdit({ value, stepValue }: { value: boolean; stepValue: string }) {
    const found = this.stepper.find(({ value }) => value === stepValue);

    if (found) {
      found.isEdit = value;
    }
  }

  handleEditingStep(stepValue: MetricStepId) {
    this.changeIsDone({ value: false, stepValue });
    this.changeIsEdit({ value: true, stepValue });

    const foundIndex = this.stepper.findIndex(
      ({ value }) => value === stepValue
    );

    if (foundIndex >= 0) {
      this.currentStep = foundIndex;
    }
  }

  handleDeleteStep(stepValue: MetricStepId) {
    this.changeIsDone({ value: false, stepValue });

    switch (stepValue) {
      case MetricStepId.BASIC_SETTINGS:
        this.resetLocalData();
        break;
      case MetricStepId.BASIC_METRICS:
        this.currentStep = 1;
        this.resetBasicMetrics();
        this.resetResultSettings();
        this.resetStepperFromCurrentStep();
        break;
      case MetricStepId.CALCULATION_SETTINGS:
        this.currentStep = 2;
        this.resetCalculationSettings();
        this.resetStepperFromCurrentStep();
        break;
      case MetricStepId.RESULT_SETTINGS:
        this.currentStep = 3;
        this.resetResultSettings();
        this.resetStepperFromCurrentStep();
        break;
      default:
        return;
    }
  }

  handleConfirmStep({
    stepValue,
    value,
  }: {
    stepValue: MetricStepId;
    value: UserMetricModel;
  }) {
    this.changeIsDone({ value: true, stepValue });
    this.localValue = cloneDeep(value);
    this.handleCalculationSettingsStep();

    if (this.currentStep === this.stepper.length - 1) {
      return;
    }

    if (
      this.stepper[this.currentStep].isEdit &&
      this.stepsShouldBeUpdated?.get(stepValue)
    ) {
      this.changeIsEdit({ value: false, stepValue });
      this.stepsShouldBeUpdated
        .get(stepValue)
        ?.forEach((item: MetricStepId) => {
          this.changeIsDone({
            value: false,
            stepValue: item,
          });
        });
    }

    this.currentStep++;

    if (!this.stepper[this.currentStep].isShow) {
      this.stepper[this.currentStep].isShow = true;
    }
  }

  resetBasicMetrics() {
    this.localValue.definition.basicMetrics = [];
  }

  resetCalculationSettings() {
    this.localValue.definition.calculationSettings =
      new UserMetricCalculationModel();
  }

  resetResultSettings() {
    this.localValue.definition.resultSettings = new UserMetricResultModel();
    this.localValue.days = [];
  }

  resetLocalData() {
    this.localValue = new UserMetricModel();
    this.stepper = this.stepper.filter(
      (item) => item.value !== MetricStepId.CALCULATION_SETTINGS
    );
    this.stepper.forEach((item, index) => {
      item.isDone = false;
      item.isShow = !index;
      item.isEdit = false;
    });
    this.currentStep = 0;
  }

  resetStepperFromCurrentStep() {
    for (let i = this.currentStep + 1; i < this.stepper.length; i++) {
      this.stepper[i].isDone = false;
      this.stepper[i].isShow = false;
      this.stepper[i].isEdit = false;
    }
  }

  emitSave(isCreateOneMoreMetric: boolean) {
    this.$emit("save", { value: this.localValue, isCreateOneMoreMetric });

    this.resetLocalData();
  }
}
