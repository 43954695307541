




























































































































import { Component, Vue, Watch } from "vue-property-decorator";

import ExpansionPanelHeader from "@/reports/components/ExpansionPanelHeader.vue";
import ReportTable from "@/reports/components/ReportTable.vue";
import ReportChart from "@/chart/components/ReportChart.vue";
import ChartAlert from "@/alerts-system/components/ChartAlert.vue";
import ReportChartDialog from "@/reports/components/ReportChartDialog.vue";
import MonetizationMonitoringReportChart from "@/reports/components/MonetizationMonitoringReportChart.vue";
import ReportResultsTabs from "@/reports/components/ReportResultsTabs.vue";
import ReportResultsWithoutTabs from "@/reports/components/ReportResultsWithoutTabs.vue";
import {
  ReportDataType,
  ReportFilter,
  ReportResultItem,
  ReportType,
  ReportVisualizationModel,
  REPORT_VISUALIZATION_TYPE,
} from "@/reports/models";
import { getReportVisualization } from "@/reports/utils/ReportVisuzalizationUtil";
import ReportUtil from "../utils/ReportUtil";
import { MenuItemsTools } from "@/shared/models/Menu";

@Component({
  components: {
    ExpansionPanelHeader,
    ReportTable,
    ReportChart,
    ChartAlert,
    ReportChartDialog,
    MonetizationMonitoringReportChart,
    ReportResultsTabs,
    ReportResultsWithoutTabs,
  },
})
export default class ReportResults extends Vue {
  readonly REPORT_VISUALIZATION_TYPE = REPORT_VISUALIZATION_TYPE;
  readonly ReportType = ReportType;
  readonly MenuItemsTools = MenuItemsTools;
  panels: Array<number> = [];
  activeTab = 0;
  isChartDialogVisible = false;
  items: Array<ReportResultItem> = [];
  filter: Map<string, string> = new Map();

  get report(): ReportFilter {
    return this.$store.state.reportStore.currentReport;
  }

  get reportName(): string {
    return this.$route.name === MenuItemsTools.MONETIZATION_MONITORING
      ? ""
      : `${ReportUtil.getReportNameByReportType(this.report.reportId)} / `;
  }

  get visualizations(): Array<ReportVisualizationModel> {
    return this.report ? getReportVisualization(this.report) : [];
  }

  get isMoneyReport(): boolean {
    return ReportUtil.isMoneyReport(this.report.reportId);
  }

  getLoadingByReportDataType(reportDataType: ReportDataType): boolean {
    return this.$store.state.reportStore.report[reportDataType].loading;
  }

  getServerErrorMsg(reportDataType: ReportDataType): string {
    return this.$store.state.reportStore.report[reportDataType].serverErrorMsg;
  }

  @Watch("visualizations", { immediate: true })
  updatePanelsVisibility() {
    this.panels = this.visualizations.reduce(
      (result: Array<number>, { collapsed }, index) => [
        ...result,
        ...(collapsed ? [] : [index]),
      ],
      []
    );
  }

  @Watch("report.hasCharts")
  resetTabs(value: boolean) {
    if (value) {
      return;
    }

    this.activeTab = 0;
  }

  togglePanelContent(index: number) {
    if (this.panels.includes(index)) {
      this.panels = this.panels.filter((panelIndex) => panelIndex !== index);
    } else {
      this.panels = [...this.panels, index];
    }
  }

  showChartDialog({
    filter,
    reportDataType,
  }: {
    filter: Map<string, string>;
    reportDataType: ReportDataType;
  }) {
    this.items = this.$store.state.reportStore.report[reportDataType].items;
    this.filter = filter;
    this.isChartDialogVisible = true;
  }

  applyExcludedItems() {
    this.$emit("applyExcludedItems");
  }
}
