












































































import { Component, Prop, Vue } from "vue-property-decorator";

import {
  BasicMetricModel,
  BasicMetricType,
  MetricType,
  OPERATION_BY_OPERATION_TYPE,
  OperationType,
  PredefinedMetricModel,
  UserMetricModel,
} from "@/reports/models";

@Component
export default class ReportMetricCard extends Vue {
  @Prop() item!: UserMetricModel | PredefinedMetricModel;

  visible = false;

  get isPredefined(): boolean {
    return this.item.type === MetricType.PREDEFINED;
  }

  get title(): string {
    return this.isPredefined
      ? this.$lang(
          `views.metrics.tableHeader.${(
            this.item as PredefinedMetricModel
          )?.id?.toLowerCase()}`
        )
      : (this.item as UserMetricModel).definition.name;
  }

  get menuItems(): Array<Record<string, any>> {
    return [
      ...(!this.isPredefined
        ? [
            {
              id: 1,
              function: this.emitCopyItem,
              label: this.$lang("btn.copy"),
            },
          ]
        : []),
      {
        id: 2,
        function: this.emitDeleteItem,
        color: "red",
        label: this.$lang("btn.delete"),
      },
    ];
  }

  get haveBasicMetricsMoreThanOne(): boolean {
    return (this.item as UserMetricModel).definition.basicMetrics.length > 1;
  }

  get operands(): Array<BasicMetricType> {
    return (this.item as UserMetricModel).definition.resultSettings.operands;
  }

  get operation(): OperationType {
    return (this.item as UserMetricModel).definition.resultSettings.operation;
  }

  get basicMetrics(): Array<BasicMetricModel> {
    return (this.item as UserMetricModel).definition.basicMetrics;
  }

  get hasText(): boolean {
    return !!this.item.days.length || !this.isPredefined;
  }

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  getOperationSignByOperationType(): string {
    return OPERATION_BY_OPERATION_TYPE.get(this.operation) as string;
  }

  getBasicMetricTextByType(type: BasicMetricType | string): string {
    return this.$lang(`views.metrics.basicMetrics.${type.toLowerCase()}`);
  }

  emitCopyItem() {
    this.$emit("copy", this.item);
    this.visible = false;
  }

  emitDeleteItem() {
    this.$emit("delete");
    this.visible = false;
  }

  emitEditItem() {
    this.$emit("edit", this.item);
    this.visible = false;
  }
}
