
































































import AccountSettingsModel from "@/account-settings/models/AccountSettingsModel";
import { FilterLocationType } from "@/account-settings/models/AccountSettingType";
import { cloneDeep } from "lodash";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ReportFiltersControls extends Vue {
  @Prop({ default: true }) isTemplatesAvailable!: boolean;

  get isCommonReportFilterEnabled(): boolean {
    return this.$store.state.reportStore.isCommonReportFilterEnabled;
  }

  get accountSettings(): AccountSettingsModel {
    return this.$store.state.accountSettingsStore.accountSettings;
  }

  get isReportFilterHorizontal(): boolean {
    return this.$store.state.accountSettingsStore.accountSettings
      .isReportFilterHorizontal;
  }

  toggleFiltersPosition() {
    const accountSettingsCloned = cloneDeep(
      this.$store.state.accountSettingsStore.accountSettings
    );

    accountSettingsCloned.filterLocation = this.isReportFilterHorizontal
      ? FilterLocationType.VERTICAL
      : FilterLocationType.HORIZONTAL;
    this.$store.dispatch("updateAccountSettings", {
      payload: accountSettingsCloned,
    });
  }
}
