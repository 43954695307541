


























import { Component, Prop, Vue } from "vue-property-decorator";

import ValidUtil from "@/shared/utils/ValidUtil";
import { FormatterType } from "@/reports/models";

@Component
export default class Formatting extends Vue {
  @Prop() value!: FormatterType;

  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  readonly formattingItems = Object.entries(FormatterType).map(([, value]) => ({
    value,
    text: this.$lang(`views.metrics.${value.toLowerCase()}`),
    description: this.getDescription(value),
  }));

  get localValue(): FormatterType {
    return this.value;
  }

  set localValue(value: FormatterType) {
    this.$emit("input", value);
  }

  getDescription(value: FormatterType): string {
    return {
      CONDITIONAL_ROUND_MORE_THAN_100_NUMBER_FORMATTER: this.$lang(
        "views.metrics.conditionalRoundDesc"
      ),
      ZERO_DIGITS_NUMBER_FORMATTER: "10",
      TWO_DIGITS_NUMBER_FORMATTER: "10.12",
      FOUR_DIGITS_NUMBER_FORMATTER: "10.1234",
    }[value];
  }
}
