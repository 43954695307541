
























import { Component, Vue, Prop } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import DaysLimit from "@/reports/components/DaysLimit.vue";
import CustomSelect from "@/shared/components/pickers/CustomSelect.vue";
import { TargetDayReportFilterExtension } from "@/reports/models";
import { FilterPreviewId } from "@/shared/models";

@Component({
  components: {
    DaysLimit,
    CustomSelect,
  },
})
export default class TargetDayFilter extends Vue {
  @Prop() filterPreviewId!: FilterPreviewId;
  @Prop() isAttached!: boolean;

  readonly FilterPreviewId = FilterPreviewId;
  readonly items = [
    {
      text: this.$lang("shared.filters.maxAvailable"),
      value: true,
    },
    {
      text: this.$lang("shared.filters.concreteDay"),
      value: false,
    },
  ];

  get localReport(): TargetDayReportFilterExtension {
    return this.$store.state.reportStore.localReport;
  }

  get localMaxTargetDay(): boolean {
    return this.localReport.maxTargetDay;
  }

  set localMaxTargetDay(value: boolean) {
    const clonedLocalReport = cloneDeep(this.localReport);

    clonedLocalReport.maxTargetDay = value;
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }

  get localTargetDay(): number {
    return this.localReport.targetDay;
  }

  set localTargetDay(value: number) {
    const clonedLocalReport = cloneDeep(this.localReport);

    clonedLocalReport.targetDay = value;
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }
}
