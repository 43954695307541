











import { Component, Vue } from "vue-property-decorator";
import { ReportType } from "../models";

@Component
export default class ReportInfo extends Vue {
  readonly URL =
    "https://x-flow-ltd.atlassian.net/wiki/spaces/BIR/pages/122060833/Reports+FAQ#";

  get reportType(): ReportType {
    return this.$route.name as ReportType;
  }

  get url(): string {
    const anchor = {
      ARPDAU: "ARPDAU.1",
      ARPDAU_SIMPLE: "ARPDAU-Simple",
      ARPU: "ARPU",
      CALENDAR_PER_MIN: "Permin",
      COHORT_ANALYSIS: "Cohort-Analysis",
      COHORT_PER_MIN: "Permin",
      EVENTS_SUMMARY: "Events-Summary",
      MEASURES_LITE: "Measures-Lite",
      RETURN_RATE: "Return-Rate",
      REVENUE: "Revenue",
      SPEND: "Spend.1",
      SPEND_HYPER: "Spend-Hyper",
      SPEND_MINI: "Spend-Mini",
      TIME_SPENT: "Time-Spent",
      PROFIT: "Profit",
      CALENDAR_CPM: "CPM",
      METRICS: "Metrics",
      WATERFALL: "Waterfall",
      EVENTS_COST: "Events-Cost",
      CALENDAR_CTR: "CTR",
      CASH_COUNTRY: "Cash-Country",
      DIFF_INSTALLS_STORES: "Diff-Installs-Stores",
      COHORT_CTR: "CTR",
      AD_ROAS_COUNTRY: "Ad-ROAS-Country",
      AD_ROAS_NETWORK: "Ad-ROAS-Network",
      CASH_GAMING: "Cash-Gaming",
      COHORT_CPM: "CPM",
      SKAD: "SKAD",
      METRICS_SPEND: "Metrics-and-Spend",
      FILL_RATE: "Fill-Rate",
      PAYING_USERS_CONVERSION: "Paying-Users-Conversion",
      PRICED_REVENUE: "Priced-Revenue",
      MONETIZATION_MONITORING: "Monetization-monitoring",
      FIREBASE_SHOW_TO_IMPRESSION: "Show-to-Impression",
      FIREBASE_VS_NETWORKS: "Firebase-vs-Networks",
      FIREBASE_FILL_RATE: "Firebase-Fill-Rate",
      TRAFFIC_QUALITY: "Traffic-Quality",
      UA_MAIN_METRICS_OVERVIEW: "UA-Main",
      SUBSCRIPTIONS_OVERVIEW: "Subscriptions-Overview",
      METRICS_CONSTRUCTOR: "Metrics-Constructor",
      SUBSCRIPTIONS_OVERVIEW_IOS: "Subscriptions-Overview-Ios",
      EVENTS_SUMMARY_LITE: "Events-Summary-Lite",
    }[this.reportType];

    return `${this.URL}${anchor}`;
  }
}
