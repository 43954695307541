





































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ConfirmedItem extends Vue {
  @Prop() title!: string;
  @Prop() text?: string | Array<{ key: string; value: string }>;

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  emitEditItem() {
    this.$emit("edit");
  }

  emitDeleteItem() {
    this.$emit("delete");
  }
}
