









import { Component, Vue, Prop } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import CustomSwitch from "@/shared/components/filters/CustomSwitch.vue";
import { ShowUniqueDevicesReportFilterExtension } from "@/reports/models";
import { FilterPreviewId } from "@/shared/models";

@Component({
  components: {
    CustomSwitch,
  },
})
export default class ShowUniqueDevicesFilterVisualization extends Vue {
  @Prop() filterPreviewId!: FilterPreviewId;

  get localReport(): ShowUniqueDevicesReportFilterExtension {
    return this.$store.state.reportStore.localReport;
  }

  get localValue(): boolean {
    return this.localReport.showUniqueDevices;
  }

  set localValue(value: boolean) {
    const clonedLocalReport = cloneDeep(this.localReport);

    clonedLocalReport.showUniqueDevices = value;
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }

  get label(): string {
    return this.$lang(`components.${this.filterPreviewId.toLowerCase()}`);
  }
}
