















import { Component, Vue, Prop } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import CustomSelect from "@/shared/components/pickers/CustomSelect.vue";
import { AdType, Dictionary, FilterPreviewId } from "@/shared/models";
import {
  AdTypeReportFilterExtension,
  ReportFilter,
  ReportType,
} from "@/reports/models";

@Component({ components: { CustomSelect } })
export default class AdTypeFilterVisualization extends Vue {
  @Prop() filterPreviewId!: FilterPreviewId;
  @Prop() clearable!: boolean;
  @Prop() isAttached!: boolean;

  readonly items = [
    new Dictionary(
      AdType.BANNER,
      this.$lang(`views.perMin.${AdType.BANNER.toLowerCase()}`)
    ),
    new Dictionary(
      AdType.INTERSTITIAL,
      this.$lang(`views.perMin.${AdType.INTERSTITIAL.toLowerCase()}`)
    ),
    new Dictionary(
      AdType.REWARDED,
      this.$lang(`views.perMin.${AdType.REWARDED.toLowerCase()}`)
    ),
  ];

  get localReport(): ReportFilter & AdTypeReportFilterExtension {
    return this.$store.state.reportStore.localReport;
  }

  get multiple(): boolean {
    return this.localReport.reportId === ReportType.MONETIZATION_MONITORING;
  }

  get localValue(): AdType {
    return this.localReport.adType;
  }

  set localValue(value: AdType) {
    const clonedLocalReport = cloneDeep(this.localReport);

    clonedLocalReport.adType = value;
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }
}
