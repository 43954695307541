














































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import Teleport from "vue2-teleport";
import {
  CellMenuDataModel,
  ReportDataType,
  ReportFilter,
  ReportResultItem,
  ReportType,
} from "@/reports/models";

@Component({
  components: {
    Teleport,
  },
})
export default class CellTooltip extends Vue {
  @Prop() xPos!: number;
  @Prop() yPos!: number;
  @Prop() activator!: string;
  @Prop() visible!: boolean;
  @Prop() reportDataType!: ReportDataType;
  @Prop() currentSortByKey!: string;
  @Prop() groupedItems!: Map<any, Array<number>>;
  @Prop() items!: Array<ReportResultItem>;
  @Prop() hasGroupedHeaders!: boolean;

  cachedCellMenuData = new Map();
  cellMenuData = new CellMenuDataModel(this.$vuetify.lang.current);

  get rowIndex(): number {
    return Number(this.activator.split("__")[1]);
  }

  get headerKey(): string {
    return this.activator.split("__")[2].replace("-", ".");
  }

  get currentReport(): ReportFilter {
    return this.$store.state.reportStore.currentReport;
  }

  get isCellMenuPositionRight(): boolean {
    const rightAgainstCenter = document.body.clientWidth / 2;
    return this.xPos <= rightAgainstCenter;
  }

  @Watch("visible")
  @Watch("activator")
  watchVisibleAndActivator() {
    if (!this.visible) {
      return;
    }

    const cellKey = `${this.headerKey}/${this.getItemValueBySortKey(
      this.items[this.rowIndex]
    )}`;

    if (!this.cachedCellMenuData.has(cellKey)) {
      this.setCachedCellMenuData(this.items[this.rowIndex], this.headerKey);
    }

    const currentValue =
      this.items[this.rowIndex].data[
        this.headerKey.replace(ReportResultItem.PREFIX, "")
      ];
    const maxValue = this.cachedCellMenuData.get(cellKey).maxValue;
    const minValue = this.cachedCellMenuData.get(cellKey).minValue;
    const maxValueByMaxInstalls =
      this.cachedCellMenuData.get(cellKey).maxValueByMaxInstalls;

    this.cellMenuData.setData(
      currentValue,
      maxValue,
      minValue,
      maxValueByMaxInstalls
    );
  }

  getItemValueBySortKey(item: ReportResultItem): any {
    return this.currentSortByKey === "date"
      ? item?.formattedDate
      : item.data[this.currentSortByKey.replace(ReportResultItem.PREFIX, "")];
  }

  setCachedCellMenuData(rowItem: ReportResultItem, headerValue: string) {
    const values: Array<number> = [];
    const installs: Array<number> = [];
    let installsKey = "installs";

    if (
      [
        ReportType.METRICS_SPEND,
        ReportType.UA_MAIN_METRICS_OVERVIEW,
        ReportType.CASH_GAMING,
        ReportType.CASH_COUNTRY,
        ReportType.EVENTS_COST,
      ].includes((this.currentReport as ReportFilter)?.reportId)
    ) {
      installsKey = "adjustInstalls";
    }

    if (this.currentReport?.reportId === ReportType.METRICS_CONSTRUCTOR) {
      installsKey = "installsCountAdjust";
    }

    if (this.hasGroupedHeaders && this.reportDataType === ReportDataType.DATA) {
      (
        this.groupedItems.get(this.getItemValueBySortKey(rowItem)) as Array<
          number & undefined
        >
      ).forEach((rowIndex: number) => {
        const value =
          this.items[rowIndex].data[
            headerValue.replace(ReportResultItem.PREFIX, "")
          ];

        if (value !== undefined && value !== null) {
          values.push(value);
          installs.push(this.items[rowIndex].data[installsKey]);
        }
      });
    } else {
      this.items.forEach((item) => {
        const value =
          item.data[headerValue.replace(ReportResultItem.PREFIX, "")];

        if (value !== undefined && value !== null) {
          values.push(value);
          installs.push(item.data[installsKey]);
        }
      });
    }

    const maxInstall = Math.max(...installs);
    const maxValuesByMaxInstall = installs.reduce(
      (result: number, item: number, index: number) => {
        if (item === maxInstall) {
          return values[index];
        }

        return result;
      },
      0
    );

    this.cachedCellMenuData.set(
      `${headerValue}/${this.getItemValueBySortKey(rowItem)}`,
      {
        maxValue: Math.max(...values),
        minValue: Math.min(...values),
        maxValueByMaxInstalls: maxValuesByMaxInstall,
      }
    );
  }
}
