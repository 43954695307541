





















import { Component, Prop, Vue } from "vue-property-decorator";

import ReportFilterItem from "@/reports/components/ReportFilterItem.vue";
import { ReportFilter } from "@/reports/models";
import ReportUtil from "@/reports/utils/ReportUtil";
import { FilterPreviewModel } from "@/shared/models/FilterPreviewModel";
import { FilterPreviewId } from "@/shared/models";

@Component({
  components: {
    ReportFilterItem,
  },
})
export default class ReportFilters extends Vue {
  @Prop({ default: false }) horizontal!: boolean;
  @Prop() value!: boolean;

  readonly eventFilterPreviewIds = [
    FilterPreviewId.GENERATED_EVENT,
    FilterPreviewId.EVENT,
  ];

  get localValid(): boolean {
    return this.value;
  }

  set localValid(value: boolean) {
    this.$emit("input", value);
  }

  get localReport(): ReportFilter {
    return this.$store.state.reportStore.localReport;
  }

  get filterPreviews(): Array<FilterPreviewModel> {
    return ReportUtil.getAvailableReportFilters(this.localReport.reportId);
  }

  get notEventFilterPreviews(): Array<FilterPreviewModel> {
    return this.horizontal
      ? this.filterPreviews.filter(
          ({ filterPreviewId }) =>
            !this.eventFilterPreviewIds.includes(filterPreviewId)
        )
      : this.filterPreviews;
  }

  get eventFilterPreviews(): Array<FilterPreviewModel> {
    return this.horizontal
      ? this.filterPreviews.filter(({ filterPreviewId }) =>
          this.eventFilterPreviewIds.includes(filterPreviewId)
        )
      : [];
  }
}
