













import { Component, Vue, Prop } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import CustomSelect from "@/shared/components/pickers/CustomSelect.vue";
import { Dictionary, FilterPreviewId } from "@/shared/models";
import {
  MeasuresLiteReportType,
  MeasuresLiteReportTypeReportFilterExtension,
} from "@/reports/models";

@Component({ components: { CustomSelect } })
export default class MeasuresLiteReportTypeFilterVisualization extends Vue {
  @Prop() filterPreviewId!: FilterPreviewId;
  @Prop() isAttached!: boolean;

  readonly items = [
    new Dictionary(
      MeasuresLiteReportType.EVENTS_DELTA_PER_USER_CUMULATIVE,
      this.$lang("views.measures.events_delta_per_user_cumulative")
    ),
    new Dictionary(
      MeasuresLiteReportType.EVENTS_DELTA_PER_USER,
      this.$lang("views.measures.events_delta_per_user")
    ),
    new Dictionary(
      MeasuresLiteReportType.EVENTS_DELTA_PER_ACTIVE_USER,
      this.$lang("views.measures.events_delta_per_active_user")
    ),
    new Dictionary(
      MeasuresLiteReportType.EVENTS_COUNT,
      this.$lang("views.measures.events_count")
    ),
    new Dictionary(
      MeasuresLiteReportType.UNIQUE_USERS_COUNT,
      this.$lang("views.measures.unique_users_count")
    ),
    new Dictionary(
      MeasuresLiteReportType.UNIQUE_USERS_PERCENT,
      this.$lang("views.measures.unique_users_percent"),
      ""
    ),
  ];

  get localReport(): MeasuresLiteReportTypeReportFilterExtension {
    return this.$store.state.reportStore.localReport;
  }

  get localValue(): MeasuresLiteReportType {
    return this.localReport.reportType;
  }

  set localValue(value: MeasuresLiteReportType) {
    const clonedLocalReport = cloneDeep(this.localReport);

    clonedLocalReport.reportType = value;
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }
}
