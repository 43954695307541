













import { Component, Vue, Prop } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import CustomSelect from "@/shared/components/pickers/CustomSelect.vue";
import { AdRevenueMethod } from "@/shared/models";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component({ components: { CustomSelect } })
export default class AdRevenueMethodFilter extends Vue {
  @Prop() value!: any;
  @Prop({ default: false }) isAttached!: boolean;

  readonly items = [
    {
      value: AdRevenueMethod.PRICED,
      text: this.$lang("components.adRevenueMethodPicker.priced"),
    },
    {
      value: AdRevenueMethod.AVERAGE,
      text: this.$lang("components.adRevenueMethodPicker.average"),
    },
  ];
  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];

  get localValue(): AdRevenueMethod {
    return this.value.revenueMethod;
  }

  set localValue(value: AdRevenueMethod) {
    const clonedLocalValue = cloneDeep(this.value);

    clonedLocalValue.revenueMethod = value;
    this.$emit("input", clonedLocalValue);
  }
}
