






































































import { Component, Prop, Vue } from "vue-property-decorator";

import Teleport from "vue2-teleport";

@Component({
  components: {
    Teleport,
  },
})
export default class SidebarPanel extends Vue {
  @Prop() value!: boolean;
  @Prop({ default: "" }) title!: string;
  @Prop({ default: false }) disabledSave!: boolean;

  isCreateOneMoreMetric = false;

  get visible(): boolean {
    return this.value;
  }

  set visible(value: boolean) {
    if (!value) {
      this.isCreateOneMoreMetric = false;
    }

    this.$emit("input", value);
  }

  emitSave() {
    this.$emit("save", this.isCreateOneMoreMetric);
  }
}
