














import { cloneDeep } from "lodash";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { ProvidedBannerReportFilterExtension } from "@/reports/models";
import CustomSelect from "@/shared/components/pickers/CustomSelect.vue";
import {
  AdRevenueMethod,
  AdType,
  Dictionary,
  FilterPreviewId,
  ProvidedBannerType,
} from "@/shared/models";

@Component({ components: { CustomSelect } })
export default class ProvidedBannerFilterVisualization extends Vue {
  @Prop() filterPreviewId!: FilterPreviewId;
  @Prop() isAttached!: boolean;

  readonly items = [
    new Dictionary(
      ProvidedBannerType.ADJUST,
      this.$lang("components.providedBannerPicker.adjust")
    ),
    new Dictionary(
      ProvidedBannerType.FIREBASE,
      this.$lang("components.providedBannerPicker.firebase")
    ),
  ];

  get localReport(): ProvidedBannerReportFilterExtension {
    return this.$store.state.reportStore.localReport;
  }

  get localValue(): string {
    return this.localReport.providedBanner;
  }

  set localValue(value: string) {
    const clonedLocalReport = cloneDeep(this.localReport);

    clonedLocalReport.providedBanner = value;
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }

  @Watch("localReport.adRevenueMethod")
  watchAdRevenueMethod(value: AdRevenueMethod) {
    if (
      value !== AdRevenueMethod.AVERAGE ||
      this.localValue === ProvidedBannerType.ADJUST
    ) {
      return;
    }

    this.localValue = ProvidedBannerType.ADJUST;
  }

  @Watch("localReport.adType")
  watchAdType(value: AdType) {
    if (
      value === AdType.BANNER ||
      this.localValue === ProvidedBannerType.ADJUST
    ) {
      return;
    }

    this.localValue = ProvidedBannerType.ADJUST;
  }
}
