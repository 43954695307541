















import { Component, Vue, Prop } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import CustomSelect from "@/shared/components/pickers/CustomSelect.vue";
import { Dictionary, DictionaryType, FilterPreviewId } from "@/shared/models";
import { RevenueReportFilterExtension } from "@/reports/models";

@Component({ components: { CustomSelect } })
export default class RevenueFilterVisualization extends Vue {
  @Prop() filterPreviewId!: FilterPreviewId;
  @Prop() isAttached!: boolean;

  get localReport(): RevenueReportFilterExtension {
    return this.$store.state.reportStore.localReport;
  }

  get localValue(): Array<string> {
    return this.localReport.revenue;
  }

  set localValue(value: Array<string>) {
    const clonedLocalReport = cloneDeep(this.localReport);

    clonedLocalReport.revenue = value;
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }

  get items(): Array<Dictionary> {
    return this.$store.state.dictionaryStore[
      DictionaryType.REVENUES
    ].values.map(({ name, value }: Dictionary) => ({
      text: name,
      value: value.toUpperCase(),
    }));
  }
}
