














import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import CustomSelect from "@/shared/components/pickers/CustomSelect.vue";
import { DefaultValues, Dictionary, DictionaryType } from "@/shared/models";
import { FilterPreviewId } from "@/shared/models/FilterModel";
import { EventReportFilterExtension, ReportFilter } from "@/reports/models";

@Component({ components: { CustomSelect } })
export default class EventFilterVisualization extends Vue {
  @Prop() filterPreviewId!: FilterPreviewId;
  @Prop() isAttached!: boolean;

  get items(): Array<Dictionary> {
    return this.$store.state.dictionaryStore[DictionaryType.EVENTS].values;
  }

  get localReport(): ReportFilter & EventReportFilterExtension {
    return this.$store.state.reportStore.localReport;
  }

  get localValue(): string {
    return this.localReport.event;
  }

  set localValue(value: string) {
    const clonedLocalReport = cloneDeep(this.localReport);

    clonedLocalReport.event = value;
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }

  @Watch("localReport.adRevenueMethod")
  watchAdRevenueMethod() {
    if (this.localReport.isPricedMethod) {
      this.localValue = DefaultValues.EVENT;
    }
  }
}
