









import { Component, Vue, Prop } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import { BasicMetricModel } from "@/reports/models";

@Component
export default class CohortFilter extends Vue {
  @Prop() value!: BasicMetricModel;

  get localValue(): boolean {
    return this.value.cohort;
  }

  set localValue(value: boolean) {
    const clonedLocalValue = cloneDeep(this.value);

    clonedLocalValue.cohort = value;

    if (!value) {
      clonedLocalValue.cumulative = false;
    }

    this.$emit("input", clonedLocalValue);
  }
}
