









































import { Component, Prop, Vue } from "vue-property-decorator";
import { uniq } from "lodash";

import ValidUtil from "@/shared/utils/ValidUtil";

@Component
export default class CohortDays extends Vue {
  @Prop() value!: Array<number>;
  @Prop() hasPredictedArpu!: boolean;

  readonly requiredRule = [
    ValidUtil.requiredArray(this.$lang("validation.required")),
  ];
  readonly DAYS = [1, 3, 7, 14, 30, 60, 120, 150, 180, 365];
  search = "";

  get localValue(): Array<number> {
    return this.value;
  }

  set localValue(value: Array<number>) {
    this.$emit("input", value);
  }

  get noDataText(): string {
    return isNaN(Number(this.search)) || Number(this.search) < 0
      ? this.$lang("comboboxNoDataText")
      : this.$lang("comboboxNoDataTextOption", this.search || "");
  }

  handleInputDays(values: Array<string | number>) {
    this.localValue = uniq(
      values.flatMap((value) => {
        const numValue = Number(value);

        return isNaN(numValue) || numValue < 0 || numValue % 1 !== 0
          ? []
          : [numValue];
      })
    );
  }
}
