






























import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import { ReportTemplateModel } from "@/templates/models/TemplateModel";
import { CurrentUserModel } from "@/shared/models";

@Component
export default class ReportTemplates extends Vue {
  @Prop() value!: boolean;

  readonly headers = [
    {
      text: this.$lang("template.name"),
      value: "name",
      width: "30%",
    },
    {
      text: this.$lang("template.description"),
      value: "description",
    },
    {
      text: this.$lang("template.reportMode"),
      value: "reportMode",
      width: "20%",
    },
    {
      text: this.$lang("template.owner"),
      value: "ownerName",
      width: "20%",
    },
  ];
  showOnlyMy = false;

  get templates(): Array<ReportTemplateModel> {
    return this.$store.state.templateStore.reportTemplates;
  }

  get applicationId(): string {
    return this.$route.params.id;
  }

  get reportName(): string {
    return this.$route.name || "";
  }

  get currentUser(): CurrentUserModel {
    return this.$store.state.userStore.currentUser;
  }

  get filteredTemplates(): Array<ReportTemplateModel> {
    return this.showOnlyMy
      ? this.templates.filter((it) => it.ownerId === this.currentUser.username)
      : this.templates;
  }

  get isTemplatesLoading(): boolean {
    return this.$store.state.templateStore.isLoadingTemplates;
  }

  @Watch("applicationId", { immediate: true })
  watchApplicationId() {
    this.$store.dispatch("fetchCurrentReportTemplates");
  }

  loadReport(template: ReportTemplateModel) {
    this.$emit("loadReport", template.getQuery());
    this.$emit("input", false);
  }

  getReportMode(item: ReportTemplateModel): string {
    return item.applicationIds.length > 1
      ? this.$lang("template.multiapp")
      : this.$lang("template.singleapp");
  }

  customSort(
    items: Array<ReportTemplateModel>,
    [index]: Array<string>,
    [isDesc]: Array<boolean>
  ): Array<ReportTemplateModel> {
    items.sort((a: any, b: any) => {
      if (index === "reportMode") {
        if (!isDesc) {
          return b.applicationIds.length < a.applicationIds.length ? -1 : 1;
        } else {
          return a.applicationIds.length < b.applicationIds.length ? -1 : 1;
        }
      } else {
        if (!isDesc) {
          return a[index] < b[index] ? -1 : 1;
        } else {
          return b[index] < a[index] ? -1 : 1;
        }
      }
    });

    return items;
  }
}
