














































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import ConfirmedItem from "./ConfirmedItem.vue";
import ValidUtil from "@/shared/utils/ValidUtil";
import { MetricStepId, UserMetricModel } from "@/reports/models";
import { VueForm } from "@/shared/types/ExtendedVueType";

@Component({
  components: {
    ConfirmedItem,
  },
})
export default class BasicSettings extends Vue {
  @Prop() value!: UserMetricModel;
  @Prop({ default: false }) isDone!: boolean;
  @Prop({ default: false }) isCopy!: boolean;
  @Prop() names!: Array<string>;

  @Ref("form") form!: VueForm;

  readonly rules = [
    ValidUtil.required(this.$lang("validation.required")),
    ValidUtil.maxLength(20, this.$lang("validation.max", 20)),
  ];
  valid = false;
  localMetricNameValue = "";

  get confirmedTitle(): string {
    return this.value.definition.name;
  }

  get isLocalDone(): boolean {
    return this.isDone;
  }

  set isLocalDone(value: boolean) {
    this.$emit("changeIsDone", {
      value,
      stepValue: MetricStepId.BASIC_SETTINGS,
    });
  }

  get errorMessages(): Array<string> {
    if (this.isCopy && this.names.includes(this.localMetricNameValue)) {
      return [this.$lang("validation.unique")];
    }

    if (
      !this.isCopy &&
      this.names.includes(this.localMetricNameValue) &&
      this.value.definition.name !== this.localMetricNameValue
    ) {
      return [this.$lang("validation.unique")];
    }

    return [];
  }

  @Watch("value.definition.name", { deep: true })
  private watchDefinitionName(value: string) {
    this.localMetricNameValue = value;
  }

  handleEditingStep() {
    this.$emit("editStep", MetricStepId.BASIC_SETTINGS);
  }

  handleDeleteStep() {
    this.$emit("deleteStep", MetricStepId.BASIC_SETTINGS);
  }

  handleConfirmStep() {
    if (!this.form.validate()) {
      return;
    }

    const clonedValue = cloneDeep(this.value);
    clonedValue.definition.name = this.localMetricNameValue;

    this.$emit("confirmStep", {
      stepValue: MetricStepId.BASIC_SETTINGS,
      value: clonedValue,
    });
  }
}
