var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-expansion-panels',{attrs:{"multiple":""},model:{value:(_vm.panels),callback:function ($$v) {_vm.panels=$$v},expression:"panels"}},[_vm._l((_vm.visualizations),function(visualization,index){return _c('v-expansion-panel',{key:index},[_c('expansion-panel-header',{attrs:{"value":_vm.panels,"index":index},on:{"toggle":_vm.togglePanelContent}},[_vm._v(" "+_vm._s(_vm.reportName)+" "+_vm._s(_vm.$lang( ("reportVisualizationName." + (visualization.name || visualization.reportDataType.toLowerCase())) ))+" "),_c('v-spacer'),(_vm.isMoneyReport)?_c('v-chip',{staticClass:"mt-1",attrs:{"color":"teal","outlined":"","label":"","small":""}},[_vm._v(" "+_vm._s(_vm.$lang("currency"))+" ")]):_vm._e(),(
              _vm.getLoadingByReportDataType(visualization.reportDataType) &&
              _vm.report.reportId === _vm.ReportType.MONETIZATION_MONITORING
            )?_c('v-progress-circular',{staticClass:"align-self-center ml-3",attrs:{"color":"primary","indeterminate":"","size":"20","width":2}}):_vm._e()],1),_c('v-expansion-panel-content',[(_vm.getServerErrorMsg(visualization.reportDataType))?_c('v-alert',{attrs:{"border":"left","type":"error","text":""}},[_vm._v(" "+_vm._s(_vm.getServerErrorMsg(visualization.reportDataType))+" ")]):[(visualization.hasTabs)?_c('report-results-tabs',{attrs:{"visualization":visualization},on:{"showChartDialog":_vm.showChartDialog}}):_c('v-row',_vm._l((visualization.vizualizations),function(dataVisualization,dataVisualizationIndex){return _c('v-col',{key:dataVisualizationIndex,attrs:{"cols":"12"}},[(
                    dataVisualization.type === _vm.REPORT_VISUALIZATION_TYPE.TABLE
                  )?_c('report-results-without-tabs',{attrs:{"visualization":visualization},on:{"showChartDialog":_vm.showChartDialog,"applyExcludedItems":_vm.applyExcludedItems}}):(
                    dataVisualization.type === _vm.REPORT_VISUALIZATION_TYPE.CHART
                  )?[_c('chart-alert',{attrs:{"report-date":_vm.report.date}}),(
                      _vm.report.reportId === _vm.ReportType.MONETIZATION_MONITORING
                    )?_c('monetization-monitoring-report-chart',{attrs:{"chart-name":dataVisualization.chartName,"has-title":dataVisualization.hasTitle,"report-data-type":visualization.reportDataType,"group-by":_vm.report.groupByFilter,"report-date":_vm.report.date}}):_c('report-chart',{attrs:{"chart-name":dataVisualization.chartName,"has-title":dataVisualization.hasTitle,"report-data-type":visualization.reportDataType,"group-by":_vm.report.groupByFilter,"report-date":_vm.report.date}})]:_vm._e()],2)}),1)]],2)],1)}),_c('report-chart-dialog',{attrs:{"report":_vm.report,"items":_vm.items,"filter":_vm.filter},model:{value:(_vm.isChartDialogVisible),callback:function ($$v) {_vm.isChartDialogVisible=$$v},expression:"isChartDialogVisible"}})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }