


























































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import ConfirmedItem from "./ConfirmedItem.vue";
import ValidUtil from "@/shared/utils/ValidUtil";
import {
  CalculationModeType,
  MetricStepId,
  UserMetricModel,
} from "@/reports/models";
import { VueForm } from "@/shared/types/ExtendedVueType";

@Component({
  components: {
    ConfirmedItem,
  },
})
export default class CalculationSettings extends Vue {
  @Prop() value!: UserMetricModel;
  @Prop({ default: false }) isDone!: boolean;
  @Prop() names!: Array<string>;

  @Ref("form") form!: VueForm;

  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  readonly calculationModeItems = Object.values(CalculationModeType).map(
    (value: CalculationModeType) => ({
      value,
      text: this.$lang(`views.metrics.${value.toLowerCase()}`),
    })
  );
  valid = false;
  localModeValue: CalculationModeType = CalculationModeType.FULL;

  get isLocalDone(): boolean {
    return this.isDone;
  }

  set isLocalDone(value: boolean) {
    this.$emit("changeIsDone", {
      value,
      stepValue: MetricStepId.CALCULATION_SETTINGS,
    });
  }

  get confirmedItemText(): Array<{
    key: string;
    value: string;
  }> {
    const {
      calculationSettings: { mode },
    } = this.value.definition;

    return [
      {
        key: this.$lang("views.metrics.calculationMode"),
        value: this.$lang(`views.metrics.${mode.toLowerCase()}`),
      },
    ];
  }

  @Watch("value.definition.calculationSettings.mode", {
    immediate: true,
    deep: true,
  })
  private watchMode(value: CalculationModeType) {
    this.localModeValue = value;
  }

  handleEditingStep() {
    this.$emit("editStep", MetricStepId.CALCULATION_SETTINGS);
  }

  handleDeleteStep() {
    this.$emit("deleteStep", MetricStepId.CALCULATION_SETTINGS);
  }

  handleConfirmStep() {
    if (!this.form.validate()) {
      return;
    }

    const clonedValue = cloneDeep(this.value);
    clonedValue.definition.calculationSettings.mode = this.localModeValue;

    this.$emit("confirmStep", {
      stepValue: MetricStepId.CALCULATION_SETTINGS,
      value: clonedValue,
    });
  }
}
