













import { Component, Prop, Vue } from "vue-property-decorator";
import { ReportDataSource, ReportFilter } from "../models";
import { getReportDataSources } from "../utils/ReportDataSourceUtil";
import {
  DataProviderAttributionType,
  DataProviderUserEventsType,
} from "@/shared/models";

@Component
export default class ReportSources extends Vue {
  @Prop() report!: ReportFilter;

  get appDataProviderUserEvents(): DataProviderUserEventsType {
    return this.$store.state.application.appDataProviderUserEvents;
  }

  get appDataProviderAttribution(): DataProviderAttributionType {
    return this.$store.state.application.appDataProviderAttribution;
  }

  get sources(): Array<ReportDataSource> {
    return getReportDataSources(
      this.report,
      this.appDataProviderUserEvents,
      this.appDataProviderAttribution
    );
  }
}
